import React from 'react';
import PropTypes from 'prop-types';
import { activeIcon, notActiveIcon } from '../../assets';

const IsEnabledIcon = (props) => {
  if (props.value === true) {
    return <img src={activeIcon} />
  }
  return <img src={notActiveIcon} />
};

IsEnabledIcon.propTypes = {
    value: PropTypes.bool.isRequired
};

export default IsEnabledIcon;