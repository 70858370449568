import React, { Component } from 'react';
import './reservations.style.scss';
import { Field, Form } from 'react-final-form';
import BootstrapInput from '../../components/BootstrapInput/BootstrapInput';
import Error from '../../components/forms/Error';
import stores from '../../stores';
import moment from 'moment';
import EmployeesGuestSelector from '../../components/EmployeesGuestSelector/EmployeesGuestSelector';
import { toast } from 'react-toastify';
export default class EditReservationModal extends Component {
  onSubmitForm = (valuesObj) => {
    let event = this.getFormattedEventObject(valuesObj);

    if (!this.props.editMode) {
      // New Reservation
      stores.reservationsStore
        .create(event)
        .then(() => {
          this.props.closeModal();
          this.props.getCalendarEventsOfThisWeek(this.props.firstDayOfWeek, this.props.lastDayOfWeek);
        })
        .catch(err => {
          toast(err.data.message, { type: toast.TYPE.ERROR });
        });
    } else {
      event._id = this.props.selectedEvent._id;
      // Edit Exisiting Reservation
      stores.reservationsStore
        .update(event)
        .then((response) => {
          this.props.closeModal();
          this.props.getCalendarEventsOfThisWeek(this.props.firstDayOfWeek, this.props.lastDayOfWeek);
        })
        .catch((err) => {
          toast(err.data.message, { type: toast.TYPE.ERROR });
        });
    }
  };

  validateForm = (values) => {
    const errors = {};
    if (!values.employee) {
      errors.employee = 'Username is required';
    }
    if (!values.date) {
      errors.date = 'Date is required';
    }
    if (!values.facility) {
      errors.facility = 'Facility is required';
    }
    if (!values.startTime) {
      errors.startTime = 'Start time is required';
    }
    if (!values.finishTime) {
      errors.finishTime = 'Finish time is required';
    }
    return errors;
  };

  todayString = () => {
    const getDate = new Date();
    // Check if day and month length is 1, then add 0 to the string: "5" => "05"
    const month =
      (getDate.getMonth() + 1 + '').length == 1 ? '0' + (getDate.getMonth() + 1) + '' : getDate.getMonth() + 1 + '';
    const day = (getDate.getDate() + '').length == 1 ? '0' + getDate.getDate() + '' : getDate.getDate() + '';
    const today = `${getDate.getFullYear()}-${month}-${day}`;

    return today;
  };

  // Change Event to Db object
  getFormattedEventObject = (valuesObj) => {
    let selectedDate = valuesObj.date.split('-');
    let year = parseInt(selectedDate[0]);
    let day = parseInt(selectedDate[2]);
    let month = parseInt(selectedDate[1]) - 1;

    let newDay = day.toString().length == 1 ? '0' + day.toString() : day.toString();
    let newMonth = month.toString().length == 1 ? '0' + month.toString() : month.toString();

    let selectedStartTime = valuesObj.startTime.split(':');
    let selectedFinishTime = valuesObj.finishTime.split(':');
    let event = {
      employee: valuesObj.employee._id, // Change this to Object id username
      facilityId: parseInt(this.props.facilityId),
      comments: valuesObj.comments,
      date: new Date(year, newMonth, newDay, parseInt(selectedStartTime[0]), parseInt(selectedStartTime[1])),
      startTime: new Date(year, newMonth, newDay, parseInt(selectedStartTime[0]), parseInt(selectedStartTime[1])), // 10.00 AM
      finishTime: new Date(year, newMonth, newDay, parseInt(selectedFinishTime[0]), parseInt(selectedFinishTime[1])) // 2.00 PM
    };
    return event;
  };

  render() {
    let initialValues = {};
    const selectedEvent = this.props.selectedEvent;
    if (this.props.editMode && this.props.selectedEvent) {
      initialValues = {
        employee: selectedEvent.employee,
        facility: this.props.facilityName,
        date: moment(this.props.selectedEvent.date).format('YYYY-MM-DD'), // need date
        startTime: moment(selectedEvent.start).format('HH:mm'),
        finishTime: moment(selectedEvent.end).format('HH:mm'),
        comments: selectedEvent.comments
      };
    } else {
      // Add New Event - Use Default
      initialValues = {
        employee: null,
        facility: this.props.facilityName,
        date: this.todayString(),
        startTime: null,
        finishTime: null,
        comments: null
      };
    }
    return (
      <Form
        onSubmit={this.onSubmitForm}
        validate={this.validateForm}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} id='form1'>
              <div className='row'>
                <div className='col'>User:</div>
                <div className='col'>
                  <Field name='employee' component={EmployeesGuestSelector} />
                  <Error name='employee' />
                </div>
              </div>
              <div className='row'>
                <div className='col'>Facility:</div>
                <div className='col disabled-input'>
                  <Field name='facility' component={'input'} type='text' disabled />
                  <Error name='facility' />
                </div>
              </div>
              <div className='row'>
                <div className='col'>Date:</div>
                <div className='col'>
                  <Field name='date' component={BootstrapInput} type='date' />
                  <Error name='date' />
                </div>
              </div>
              <div className='row'>
                <div className='col'>Start Time:</div>
                <div className='col'>
                  <Field name='startTime' component={BootstrapInput} type='time' />
                  <Error name='startTime' />
                </div>
              </div>
              <div className='row'>
                <div className='col'>Finish Time:</div>
                <div className='col'>
                  <Field name='finishTime' component={BootstrapInput} type='time' />
                  <Error name='finishTime' />
                </div>
              </div>
              <div className='row'>
                <div className='col'>Comments:</div>
                <div className='col'>
                  <Field name='comments' component={BootstrapInput} type='text' />
                </div>
              </div>
              <button type='submit' disabled={submitting}>
                Save
              </button>
            </form>
          );
        }}
      />
    );
  }
}
