import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class ExternalParkingSelector extends Component {
  constructor(props) {
    super(props);
  }

  filterParkings({label, value, data}, inputValue) {
    if (inputValue.length === 0) return true;
    if (!data.name || !data.parkName) return false;
    return data.name.indexOf(inputValue) > -1 || data.parkName.indexOf(inputValue) > -1;
  }

  render() {
    const { selected, externalParkings } = this.props;
    const value = externalParkings.find((parking) => parking && parking._id === selected) || '';
    return (
      <Select
        className="basic-single"
        classNamePrefix="react-select"
        value={value}
        onChange={this.props.onSelect}
        options={externalParkings}
        isClearable={true}
        getOptionValue={option => option._id}
        filterOption={this.filterParkings}
        formatOptionLabel={parking => parking.name + ' - ' + parking.parkName}
      />
    );
  }
}

ExternalParkingSelector.propTypes = {
  selected: PropTypes.string,
  externalParkings: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  defaultOption: PropTypes.string
};

ExternalParkingSelector.defaultProps = {
  selected: '',
  defaultOption: 'Select External Parking',
  externalParkings: []
};

export default ExternalParkingSelector;