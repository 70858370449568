import React, { Component } from 'react';
import stores from '../../../../stores/index';
import { observer } from 'mobx-react';
import DataTable from '../../../../components/DataTable/DataTable';
import ConstantEmployeeFilters from './ConstantEmployeeFilters';

const { constantEmployeeReportStore } = stores;

@observer
class ConstantEmployeeReport extends Component {
  componentDidMount() {
    constantEmployeeReportStore.getReport();
  }

  onExportClick = (submittedValues) => {
    constantEmployeeReportStore.exportReport(submittedValues);
  };

  onFiltersSearch = (submittedValues) => {
    constantEmployeeReportStore.getReport(submittedValues);
  };

  render() {
    return (
      <div>
        <h1 className="page-title">Constant Employee Report</h1>
        <div className="actions">
          <ConstantEmployeeFilters onSearch={this.onFiltersSearch} onExportClick={this.onExportClick} />
        </div>
        <DataTable columns={constantEmployeeReportStore.tableColumns}
                   data={constantEmployeeReportStore.currentReport.slice()} />
      </div>
    );
  }
}

export default ConstantEmployeeReport;