import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from '../stores/index';

@observer
class PrivateRoute extends React.Component {
  render() {
    const { ...restProps } = this.props;
    if (stores.userStore.currentUser) return <Route {...restProps} />;
    return <Redirect to="/" />;
  }
}

export default PrivateRoute;
