import { action, observable } from 'mobx';
import API from '../../utils/API/api';
import CrudStore from './crudStore';
import { ROLES, ROUTES } from '../../utils/common';

const defaultPermissions = [ROLES.COMPANY, ROLES.SENIOR_SECRETARY,ROLES.ADMIN];
const extendedPermissions = [...defaultPermissions, ROLES.SECRETARY ,ROLES.ADMIN];

const permissions = {
  getById: extendedPermissions,
  getAll: extendedPermissions,
  create: defaultPermissions,
  update: defaultPermissions,
  delete: defaultPermissions
};

class VacationsStore extends CrudStore {
  @observable selectedVacation;
  @observable isEditModalShown;

  constructor(stores) {
    super(stores, API.company.vacations, permissions);
    stores.commonStore.onLocationChanged(location => {
      if (location.path === ROUTES.VACATIONS) {
        this.getAll();
      }
			if (location.path === ROUTES.ADMIN_VACATIONS) {
				this.getAll();
			}
    })
  }

  @action
  editVacation = (vacation) => {
    this.selectedVacation = vacation;
    this.showEditModal();
  };

  @action
  newVacation = () => {
    this.selectedVacation = undefined;
    this.showEditModal();
  };

  @action
  showEditModal = () => {
    this.isEditModalShown = true;
  };

  @action
  hideEditModal = () => {
    this.isEditModalShown = false
  };
}

export default VacationsStore;
