import React, { useState } from 'react';
import './InputWithCheckbox.css';
import { Field } from 'react-final-form';

const InputWithCheckbox = () => {
	return (
		<div className="input-with-checkbox">
			<Field
				className="checkbox-container"
				name="apiUrl"
				render={({ input }) => (
					<div className="input-with-checkbox">
						<input {...input} type={'text'} className="form-control" placeholder="Enter API URL" />
					</div>
				)}
			/>
			<Field
				className="input-with-checkbox"
				name="isSecure"
				type="checkbox"
				render={({ input }) => {
					return (
						<label className="checkbox-container">
							<input
								{...input}
								type="checkbox"
								checked={input.checked}
								onChange={input.onChange}
								title={'Secure mode enabled'}
                                alt={'Secure mode enabled'}
							/>
							<span className="checkmark"></span>
						</label>
					);
				}}
			/>
		</div>
	);
};

export default InputWithCheckbox;
