import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { deleteIcon, editIcon } from '../../assets';
import stores from '../../stores';
import Modal from '../../components/modal/Modal';
import EditCameraModal from './CamerasComponents/EditCameraModal/EditCameraModal';
import { toJS } from 'mobx';
import DataTable from '../../components/DataTable/DataTable';

const { camerasStore, camerasViewStore } = stores;

@withRouter
@observer
class Cameras extends Component {
  componentDidMount() {
    camerasStore.getCamerasByCompany(this.props.match.params.companyId);
  }

  onEditRow = (row) => {
    camerasViewStore.editCamera(row._original);
  };

  onDeleteRow = (row) => {
    const answer = window.confirm("Are you sure you want to delete this forever?");
    if (answer) {
      camerasStore.deleteCamera(row._original._id);
    }
  };

  onNewCameraClick = () => {
    camerasViewStore.newCamera(this.props.match.params.companyId);
  };

  render() {
    const columns = [
      {
        Header: 'Camera Name',
        accessor: 'name'
      }, {
        Header: 'Guid',
        accessor: 'guid'
      }, {
        Header: 'Type',
        accessor: 'type'
      }, {
        Header: 'Branch Name',
        accessor: 'branch.name'
      }, {
        Header: 'Edit',
        Cell: ({ row }) => <img src={editIcon} onClick={this.onEditRow.bind(this, row)} />,
        sortable: false
      }, {
        Header: 'Delete',
        Cell: ({ row }) => <img src={deleteIcon} onClick={this.onDeleteRow.bind(this, row)} />,
        sortable: false
      }
    ];

    const { isEditCameraModalShown, hideEditCameraModal, companiesBranches, companiesParks, selectedCamera } = camerasViewStore;
    const { camerasList } = camerasStore;
    const branchesArray = toJS(companiesBranches);
    const parksArray = toJS(companiesParks);

    return (
      <div className="full-height">
        <h1 className="page-title">Cameras</h1>
        <div className="actions">
          <button type="button" onClick={this.onNewCameraClick}>New Camera</button>
        </div>
        <DataTable
          data={camerasList.slice()}
          columns={columns}
        />
        <Modal title="New Camera" show={isEditCameraModalShown} handleClose={hideEditCameraModal}>
          <EditCameraModal companyId={this.props.match.params.companyId} camera={selectedCamera}
													 parkingLots={parksArray}
                           branches={branchesArray} closeModal={hideEditCameraModal} />
        </Modal>
      </div>
    );
  }
}

export default Cameras;
