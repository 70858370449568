import React from 'react';

export default function ParkingNameCell(props) {
  const { value } = props;
  const parking = props.original;

  if (!parking) return null;
  const isVirtualParking = parking.virtualParking.length > 0;
  const isInnerParking = !!parking.externalParking;
  const isOuterParking = !!parking.internalParking;
  let parkingName = value;
  if (isInnerParking) {
    parkingName += ' (Inner)';
  }
  else if (isVirtualParking) {
    parkingName += ' (Virtual)';
  }
  else if (isOuterParking) {
    parkingName += ' (Outer)';
  }
  return (
    <span className="font-weight-bold">{parkingName}</span>
  );

};