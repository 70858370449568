import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from '../../stores';
import DataTable from '../../components/DataTable/DataTable';
import moment from 'moment';

const { logsViewStore } = stores;

@withRouter
@observer
class AutoReleaseLogs extends Component {
  componentDidMount() {
    logsViewStore.loadArrivalLogs();
  }

  render() {
    const columns = [
      {
        Header: 'Time',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          return `${moment(value).fromNow()} (${moment(value).format("YYYY-MM-DD HH:mm")})`;
        }
      }, {
        Header: 'Company',
        accessor: 'company.name'
      }
    ];

    const { autoReleaseLogsList } = logsViewStore;
    return (
      <div className="full-height">
        <h1 className="page-title">Automatic release</h1>
        <div className="actions">
          {/*<button>From</button>*/}
        </div>
        <DataTable
          data={autoReleaseLogsList.slice()}
          columns={columns}
          defaultSorted={[
            {
              id: "time",
              desc: true
            }
          ]}
        />
      </div>
    );
  }
}

export default AutoReleaseLogs;