import { ROLES } from '../utils/common';

export const defaultUserObject = {
  _id: undefined,
  username: '',
  name: '',
  password: '',
  email: '',
  phone: '',
  company: undefined,
  type: ROLES.COMPANY
};

class User {
  constructor(rawUser = defaultUserObject) {
    if (rawUser === null) {
      rawUser = defaultUserObject;
    }
    this._id = rawUser._id;
    this.username = rawUser.username;
    this.name = rawUser.name;
    this.email = rawUser.email;
    this.type = rawUser.type;
    this.company = (rawUser.company && rawUser.company !== '' && rawUser.company._id) ? rawUser.company._id : rawUser.company;
    this.phone = rawUser.phone;
    if (rawUser.password) {
      this.password = rawUser.password;
    }
  }
}

export default User;