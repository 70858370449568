import React, { useState, useEffect } from 'react';
import DataTable from '../../components/DataTable/DataTable';
import stores from '../../stores/index';
import Filters from './LprLogsFilters';

const { LprLogsReportPerParkStore } = stores;

const LprLogsPerPark = (props) => {
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState([]);
	const [originalData, setOriginalTempData] = useState([]);

	useEffect(() => {
		const parkId =  props.match.params.parkId;
		LprLogsReportPerParkStore.getLprLogsPerPark(parkId)
			.then(response =>{
					setData(response.results);
					setOriginalTempData(response.results);
				setIsLoading(true)
			})
	}, []);


	const onExportClick = submittedValues => {
		const params = this.parseParams(submittedValues);
		LprLogsReportPerParkStore.exportReport(params);
	};


	const onSearch = values => {
		const dataToFilter = originalData.length > data.length ? originalData : data;
		const filterObj = {};
		values.carNumber ? filterObj.carNumber = values.carNumber : filterObj.carNumber = "";
		values.employee ? filterObj.driverName = values.employee.name || values.employee : filterObj.driverName = "";

		const filterData = dataToFilter.filter(d => d.driverName.includes(filterObj.driverName) && d.carNumber.includes(filterObj.carNumber));
		setData(filterData);
	};

	return (
		isLoading &&
		<div className="content">
			<div className="actions">
				<Filters onSubmit={onSearch} initialValues={{}} />
			</div>
			<div>
				<h1 className="page-title">Counter Per Parking Lot</h1>
				<DataTable
					columns={LprLogsReportPerParkStore.tableColumns}
					data={data.slice()}
				/>
			</div>
		</div>
	)
}

export default LprLogsPerPark

