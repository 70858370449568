import { ROLES } from '../../../utils/common';
import React, { Component } from 'react';
import NavbarLink from '../NavbarLink';
import './responsiveNavBar.css'
class AdminView extends Component {
  render() {
    const { currentUser } = this.props;
    if (!currentUser) return null;
    if (currentUser.type && currentUser.type === ROLES.ADMIN) {
      return (
        <ul className="nav navbar-nav pull-xs-right">
          <li className="nav-item">
            <NavbarLink to="/users">
              Users
            </NavbarLink>
          </li>

          <li className="nav-item">
            <NavbarLink to="/companies">
              Companies
            </NavbarLink>
          </li>

          <li className="nav-item">
            <NavbarLink to="/autoRelease">
              Automatic Release
            </NavbarLink>
          </li>

          <li className="nav-item">
            <NavbarLink to="/smsLogs">
              SMS Logs
            </NavbarLink>
          </li>

          <li className="nav-item">
            <NavbarLink to="/lprLogs">
              LPR Logs
            </NavbarLink>
          </li>
					<li className='nav-item'>
						<NavbarLink to='adminVacation'>
							adminVacation
						</NavbarLink>
					</li>
          <li className="nav-item">
            <NavbarLink to="/logout">
              Logout
            </NavbarLink>
          </li>
        </ul>
      );
    }
    return null;
  };
}

export default AdminView;
