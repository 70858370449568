import { Field, Form } from 'react-final-form';
import React from 'react';
import BootstrapInput from '../../components/BootstrapInput/BootstrapInput';
import EmployeesGuestSelector from '../../components/EmployeesGuestSelector/EmployeesGuestSelector';

const Filters = ({ onSubmit, initialValues }) => {
	return (
		<Form
			onSubmit={onSubmit}
			initialValues={initialValues}
			render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
				return (
					<form onSubmit={handleSubmit}>
						<div className="row col-sm-12">
							{/* <div className="col-sm-4">
								<div className="row">
									<div className="col-sm-4">
										<label>From Date</label>
									</div>
									<div className="col-sm-8">
										<Field name="startDate">
											{({ input, meta }) => {
												return (
													<DatePicker
														selected={input.value}
														onChange={input.onChange}
														onBlur={input.onBlur}
														onFocus={input.onFocus}
														dateFormat="dd/MM/yyyy"
														className="form-control"
														maxDate={new Date()}
													/>
												);
											}}
										</Field>
									</div>
								</div>
							</div> */}
							{/* <div className="col-sm-3">
								<div className="row">
									<div className="col-sm-4">
										<label>To Date</label>
									</div>
									<div className="col-sm-8">
										<Field name="endDate">
											{({ input, meta }) => {
												return (
													<DatePicker
														selected={input.value}
														onChange={input.onChange}
														onBlur={input.onBlur}
														onFocus={input.onFocus}
														dateFormat="dd/MM/yyyy"
														className="form-control"
														maxDate={new Date()}
													/>
												);
											}}
										</Field>
									</div>
								</div>
							</div> */}
							<div className="col-sm-10">
								<div className="row align-items-baseline">
									<div className="col">
										<label htmlFor="carNumber">Plate #</label>
									</div>
									<div className="col-sm-3">
										<Field name="carNumber" id="carNumber" component={BootstrapInput} />
									</div>
									<div className="col">
										<label>Employee name</label>
									</div>
									<div className="col-sm-4">
										<Field name="employee" component={EmployeesGuestSelector} />
									</div>
									<div className="form-check mb-2 mr-sm-2">
										<Field name="allTransactions" className="form-check-input" component={BootstrapInput} type="checkbox" id="allTransactions" />
										<label className="form-check-label" htmlFor="allTransactions">
											Display All Traffic
										</label>
									</div>
									{/*<div className="col">
										<label htmlFor="allTransactions">All</label>
										<Field name="allTransactions" component={BootstrapInput} type="checkbox" id="allTransactions" />
									</div>*/}
								</div>
							</div>
							<div className="col">
								<button type="submit" disabled={submitting} className="btn btn-primary btn-block">
									Search
								</button>
							</div>
						</div>
					</form>
				);
			}}
		/>
	);
};

export default Filters;
