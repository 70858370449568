import React, { Component } from 'react';
import UserDetails from './MyDetailsComponents/UserDetails';
import CompanyDetails from './MyDetailsComponents/CompanyDetails';
import ChangePassword from './MyDetailsComponents/ChangePassword';

class MyDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="companies-page">
        <UserDetails />
        <br />
        <ChangePassword />
        <br />
        <CompanyDetails />
      </div>
    );
  }
}

MyDetails.propTypes = {
  //myProp: PropTypes.object.isRequired
};

MyDetails.defaultProps = {
  //myProp: <defaultValue>
};

export default MyDetails;
