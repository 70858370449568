	import React from 'react';
import { NavLink } from 'react-router-dom';

const CountersView = ({ counters,  parksPerCompany }) => {

	 function sortValList(list) {
		return list.sort(function(a, b){
			if (a.parks && a.parks[0] && a.parks[0].name &&
				b.parks && b.parks[0] && b.parks[0].name) {
				if (a.parks[0].name < b.parks[0].name) { return -1; }
				if (a.parks[0].name > b.parks[0].name) { return 1; }
			}
			return 0;
		})
	}

	const isManagementCompany = parksPerCompany.length > 1;
	return (
		<div className="counter-container">
			<h4>Parking Status</h4>
			{
				isManagementCompany ?
				<div className="mul-counter-container">
					{
						(sortValList(parksPerCompany).map((park, index) => {
							const currentCountersCompany = counters.filter(c => c.company && park.company && c.company.label === park.company.label)
							return (
							<div key={index} className='comapny-counter'>
							{
								currentCountersCompany.length>0 && (currentCountersCompany[0].counters.map((counter, index) => {
								const currentPark = park.parks.filter(pa => pa.name === counter.name)

								return (
									<div key={index} className="current-counter">
									{
										currentPark[0] ?
										<NavLink
										to={`/logs-per-park/${currentPark[0] ? currentPark[0]._id :""}`}
										style={{color: '#0099ff'}} >
											{counter.name}: {counter.current}/{counter.max}
										</NavLink>
										:
										<span style={{color: '#0099ff'}}>{counter.name}: {counter.current}/{counter.max}</span>
									}
									</div>
								);
								}))
							}
							</div>
							)
						}))
					}
				</div>
				 : (
						counters.map(counter => {
						const currentPark = parksPerCompany.length > 0 && parksPerCompany[0].parks.filter(pa => pa.name === counter.name)
						return (
							<div key={counter.id}>
							{
								currentPark[0] ?
								<NavLink
								to={`/logs-per-park/${currentPark[0] ? currentPark[0]._id :""}`}
								style={{color: '#0099ff'}} >
									{counter.name}: {counter.current}/{counter.max}
								</NavLink>
								:
								<span style={{color: '#0099ff'}}>{counter.name}: {counter.current}/{counter.max}</span>
							}
							</div>
						);
					}))
			}
		</div>
	);
};

export default CountersView;
