import dayjs from 'dayjs';
import PerformedBy from '../../pages/Reports/ReportsComponents/PerformedBy';
import React from 'react';

const ReportStartDateCell = ({ row, value }) => {
  if (!value) return null;
  return (
    <p className="text-left">
      <span>{dayjs(value).format('YYYY-MM-DD HH:mm:ss')} [<PerformedBy by={row._original.holdBy} />]</span>
    </p>
  );
};

export default ReportStartDateCell;