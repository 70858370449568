import { generateOption } from '../utils/common';

export const defaultCameraObject = {
  _id: undefined,
  name: '',
  guid: '',
  externalCameraId: '',
  type: 'enter',
  branch: '',
  park: '',
  company: '',
  apiUrl: '' ,
  isSecure:false,
	autoExit : false,
};

class Camera {
  constructor(rawCamera = defaultCameraObject) {
    this._id = rawCamera._id;
    this.name = rawCamera.name;
    this.guid = rawCamera.guid;
    this.externalCameraId = rawCamera.externalCameraId;
    this.type = rawCamera.type;
    this.branch = rawCamera.branch !== '' && rawCamera.branch._id ? rawCamera.branch._id : rawCamera.branch;
    if (rawCamera.park && rawCamera.park !== '') {
			this.park = generateOption(rawCamera.park.name, rawCamera.park._id);
		} else {
    	this.park = null;
		}
    this.company = rawCamera.company;
    this.apiUrl = rawCamera.apiUrl;
    this.isSecure = rawCamera.isSecure
		this.autoExit = rawCamera.autoExit || false;

  }
}

export function cameraSerializer(camera) {
	return {
		_id: camera._id,
		name: camera.name,
		guid: camera.guid,
		externalCameraId: camera.externalCameraId,
		type: camera.type,
		branch: camera.branch,
		park: camera.park ? camera.park.value : null,
		company: camera.company ? camera.company._id : null,
		apiUrl: camera.apiUrl,
    isSecure:camera.isSecure || false,
		autoExit : camera.autoExit,
	}
}

export default Camera;
