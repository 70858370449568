import { action } from 'mobx';
import API from '../../utils/API/api';
import CrudStore from './crudStore';
import { ROLES } from '../../utils/common';

const defaultPermissions = [ROLES.COMPANY];

const permissions = {
  getAll: defaultPermissions,
  create: defaultPermissions,
  update: defaultPermissions,
  delete: defaultPermissions
};

export default class ReservationsStore extends CrudStore {
  constructor(stores) {
    super(stores, API.company.reservations, permissions);
  }

  @action
  getCalendarEventsOfThisWeek(firstDay, lastDay, companyId) {
    this.commonStore.setIsLoading(true);
    return this.api.getCalendarEventsOfThisWeek(firstDay, lastDay, companyId);
  }

  @action
  stopLoading() {
    this.commonStore.setIsLoading(false);
  }
}
