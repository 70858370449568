import { action, observable } from 'mobx';

class UsersViewStore {
  @observable selectedUser;
  @observable isEditModalShown;

  @action
  editUser = (user) => {
    this.selectedUser = user;
    this.showEditModal();
  };

  @action
  newUser = () => {
    this.selectedUser = null;
    this.showEditModal();
  };

  @action
  showEditModal = () => {
    this.isEditModalShown = true;
  };

  @action
  hideEditModal = () => {
    this.isEditModalShown = false
  };
}

export default UsersViewStore;