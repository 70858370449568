import React, { Component } from 'react';
import classnames from 'classnames';
import Loader from 'react-loaders';
import './loader.styles.scss';

import stores from '../../stores';
import { observer } from 'mobx-react';

const { commonStore } = stores;

@observer
class DPMLoader extends Component {
  render() {

    const className = classnames({
      'loader-container': true,
      shown: commonStore.isLoading
    });
    return (
      <div className={className}>
        <Loader type="ball-scale-ripple" active />
      </div>
    );
  }
}

export default DPMLoader;
