import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import stores from '../../stores';
import Error from '../../components/forms/Error';
import Guest from '../../models/Guest';
import BootstrapInput from '../../components/BootstrapInput/BootstrapInput';
import DatePicker from 'react-datepicker';
import randomPassword from '../../utils/randomPassword';
import dayjs from 'dayjs';
import Select from 'react-select';
import Api from '../../utils/API/api';

class EditLprGuest extends Component {
	constructor(props) {
		super(props);
	}

	onCompanySelected = (option) => {
		stores.userStore.currentUser.company.managedCompanies = option;
	}
	 onSubmitForm = async submittedValues => {
		 const carNumber = submittedValues.carNumbers[0];
		 const matchIds = stores.guestsStore.list.map((guest) => {
			 if (guest.carNumbers[0] === carNumber && guest._id) {
				 return guest._id;
			 }
		 }).filter((value) => value !== undefined && value !== null);
		 if(matchIds[0] && matchIds[0]!=''){
			 submittedValues._id = matchIds[0];
		 }
		 const guest = new Guest(submittedValues);
		 const isEditMode = guest && guest._id ;
		 const { camerasStore } = stores
		 const { camerasList } = camerasStore
		 const parkinglotsCameras = camerasList.map((camera) => {
			 if (camera && camera.guid && camera.park && camera.park._id) {
				 return {
					 guid: camera.guid,
					 parkId: camera.park._id
				 };
			 }
		 }).filter(camera => camera !== undefined); // Filter out the undefined values
		 const firstMatchingCamera = parkinglotsCameras.find(camera => submittedValues.allowedParks.includes(camera.parkId));
		 const guid = firstMatchingCamera ? firstMatchingCamera.guid : '';
		 //eslint-disable-next-line no-unused-expressions
		 stores.userStore.currentUser.company.isManagementCompany ? Object.assign(guest,{...{managedCompanies:stores.userStore.currentUser.company.managedCompanies}}): null;
		 if (!isEditMode) {
			 stores.guestsStore
				 .create(guest)
				 .then(async response => {
					 console.log('created');
					 if (guid) {
						 const result = await this.entryGuid(carNumber, guid);
						 console.log('status of try to enter the park ' , result);
						 if(result){
								this.props.closeModal();
								window.location.reload();
							}
						 if(!result){
							 alert('the parking lots is full, please choose another paring lots');
						 }
					 }
				 })
				 .catch(err => {
					 console.error(err);
				 });
		 } else {
			 stores.guestsStore
				 .update(guest)
				 .then(async response => {
					 console.log('updated!');
					 console.log(`guid is ${guid}`);
					 if (guid) {
						 const result = await this.entryGuid(carNumber, guid);
						 console.log('status of try to enter the park ' , result);
						 if (result) {
							 this.props.closeModal();
							 window.location.reload();
						 }
						 if(!result){
							 alert('the parking lots is full, please choose another paring lots');
						 }
					 }

				 })
				 .catch(err => {
					 console.error(err);
				 });
		 }
	 };

	 entryGuid = async (carNumber, guid) => {
		 const statusEntry =  await Api.company.lprDashboard.entryGuid(carNumber , guid);
		 if(statusEntry.results == true){
			 return true
		 }
		 return false;
	 };


	validateForm = values => {
		const errors = {};
		if (!values.name) {
			errors.name = 'Guest name is required';
		}
		if (!values.phone) {
			errors.phone = 'Username is required';
		}
		if (!values.password && values._id === undefined) {
			errors.password = 'Password is required';
		}
		if (!values.startDate) {
			errors.startDate = 'Start date is required';
		}
		if (!values.endDate) {
			errors.endDate = 'End date is required';
		}
		if(0 === values.allowedParks.length){
			errors.allowedParks = 'ParkingLots in required'
		}
		return errors;
	};


	render() {
		const { camerasStore } = stores
		camerasStore.getCamerasByCompany(stores.userStore.currentUser.company._id);
		const defaultValues = new Guest(this.props.guest || undefined);
		const isEditMode = defaultValues._id !== undefined;
		const currentUser = stores.userStore.currentUser;
		const { parkingLots } = this.props;
		if (!isEditMode) {
			defaultValues.password = randomPassword();
		}
		return (
			<Form
				onSubmit={this.onSubmitForm}
				mutators={{ ...arrayMutators }}
				initialValues={defaultValues}
				validate={this.validateForm}
				render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
					return (
						<form onSubmit={handleSubmit}>
							<div className="row">
								<div className="col">Full Name*:</div>
								<div className="col">
									<Field name="name" component={BootstrapInput} type="text" />
									<Error name="name" />
								</div>
							</div>
							<div className="row">
								<div className="col">Start Date*:</div>
								<div className="col">
									<Field name="startDate">
										{({ input, meta }) => {
											const value =
												typeof input.value === 'string' && input.value === ''
													? undefined
													: dayjs(input.value).toDate();
											return (
												<DatePicker
													minDate={new Date()}
													selected={value}
													onChange={input.onChange}
													onBlur={input.onBlur}
													onFocus={input.onFocus}
													dateFormat={'dd/MM/yyyy'}
													className="form-control"
												/>
											);
										}}
									</Field>
									<Error name="startDate" />
								</div>
							</div>
							<div className="row">
								<div className="col">End Date*:</div>
								<div className="col">
									<Field name="endDate">
										{({ input, meta }) => {
											const value =
												typeof input.value === 'string' && input.value === ''
													? undefined
													: dayjs(input.value).toDate();
											const minDate = values.startDate === null ? new Date() : values.startDate;
											return (
												<DatePicker
													minDate={minDate}
													selected={value}
													onChange={input.onChange}
													onBlur={input.onBlur}
													onFocus={input.onFocus}
													dateFormat={'dd/MM/yyyy'}
													className="form-control"
												/>
											);
										}}
									</Field>
									<Error name="endDate" />
								</div>
							</div>
							<div className="row">
								<div className="col">Username (phone number)*:</div>
								<div className="col">
									<Field name="phone" component={BootstrapInput} type="text" />
									<Error name="phone" />
								</div>
							</div>
							{!isEditMode && (
								<div className="row">
									<div className="col">Password*:</div>
									<div className="col">
										<Field name="password" component={BootstrapInput} type="text" />
										<Error name="password" />
									</div>
								</div>
							)}
							<div className="row">
								<div className="col">Email:</div>
								<div className="col">
									<Field name="email" component={BootstrapInput} type="text" />
									<Error name="email" />
								</div>
							</div>
							<div className="row">
								<div className="col">ID:</div>
								{currentUser.company.name == 'colmobil' ? <div className="col">
									<Field name="customId" component="select" className="custom-select">
										<option value="">Select...</option> {/* Placeholder option */}
										<option value="employee">עובד (Employee)</option>
										<option value="partner">שותף לדרך (Partner)</option>
										<option value="supplierA">ספק A (Supplier A)</option>
										<option value="supplierE">ספק E (Supplier E)</option>
										<option value="guestA">אורח A (Guest A)</option>
										<option value="guestB">אורח B (Guest B)</option>
										<option value="guestD">אורח D (Guest D)</option>
										<option value="demoCar">רכב הדגמה (Demo Car)</option>
									</Field>
									<Error name="customId" />
								</div> : <div className="col">
									<Field name="customId" component={BootstrapInput} type="text">
									</Field>
									<Error name="customId" />
								</div>}
							</div>

							<div className='row'>
								{currentUser.company.isManagementCompany ?
									<>
										<div className='col'>
											<label htmlFor='managedCompanies'>Managed Companies: </label>
										</div>
										<div className='col'>
											<Field name="managedCompanies" id="managedCompanies" >
												{({ input, meta }) => {
													return (
														<Select
															className="basic-single"
															classNamePrefix="select"
															id="managedCompanies"
															isMulti
															defaultValue={stores.userStore.currentUser.company.managedCompanies}
															onChange={this.onCompanySelected}
															options={stores.userStore.currentUser.company.managedCompanies}
														/>
													);
												}}
											</Field>
										</div>
									</>
									: null}
							</div>
							<div className="row">
								<div className="col">Car numbers:</div>
								<div className="col">
									<button type="button" onClick={() => form.mutators.push('carNumbers', undefined)}>
										Add Car number
									</button>
									<FieldArray name="carNumbers">
										{({ fields }) => {
											return fields.map((name, index) => (
												<div key={name} className="row">
													<div className="col-sm-3">
														<label>Car #{index + 1}</label>
													</div>
													<div className="col-sm-8">
														<Field
															name={`${name}`}
															component={BootstrapInput}
															placeholder="12345678"
														/>
													</div>
													<div className="col-sm-1">
														<span
															onClick={() => fields.remove(index)}
															style={{ cursor: 'pointer' }}
														>
															&times;
														</span>
													</div>
													<Error name={`carNumbers[${index}]`} />
												</div>
											));
										}}
									</FieldArray>
								</div>
							</div>
							<div className="row">
								<div className="col">
									{currentUser.company.isWithWorkstation ? 'Locations' : 'Parking Lots'}:
								</div>
								<div className="col">
									<Field name="allowedParks">
										{({ input, meta }) => {
											// Change the onChange function to handle single selection
											const onChange = parkId => {
												// If the selected parkId is already selected, clear the selection, otherwise set it as the value
												if (input.value.includes(parkId)) {
													input.onChange([]);
												} else {
													input.onChange([parkId]); // Set the new value as an array with the selected parkId
												}
											};

											return (
												<>
													{parkingLots.map(park => (
														<div className="form-group row" key={park.value}>
															<div className="col-sm-12">
																<div className="form-check">
																	<input
																		className="form-check-input"
																		type="checkbox"
																		onChange={() => onChange(park.value)}
																		id={park.value}
																		value={park.value}
																		// Ensure the checkbox is checked only if it matches the selected value
																		checked={input.value.includes(park.value)}
																	/>
																	<label className="form-check-label" htmlFor={park.value}>
																		{park.label}
																	</label>
																</div>
															</div>
														</div>
													))}
												</>
											);
										}}
									</Field>
									<Error name="allowedParks" />
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label htmlFor="isActive">Active</label>
								</div>
								<div className="col">
									<Field name="isActive" component={BootstrapInput} type="checkbox" id="isActive" />
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label htmlFor="shouldSendSms">Send SMS to constant guest?:</label>
								</div>
								<div className="col">
									<Field
										name="shouldSentSMS"
										component={BootstrapInput}
										type="checkbox"
										id="shouldSendSms"
									/>
									<Error name="shouldSentSMS" />
								</div>
							</div>
							<button type="submit" disabled={submitting} className="btn btn-primary">
								Save
							</button>
						</form>
					);
				}}
			/>
		);
	}
}

EditLprGuest.propTypes = {
	closeModal: PropTypes.func.isRequired,
	guest: PropTypes.object,
	parkingLots: PropTypes.array.isRequired,
};

EditLprGuest.defaultProps = {
	guest: undefined,
};

export default EditLprGuest;
