import React, { Component } from 'react';
import stores from '../../../../stores/index';
import { observer } from 'mobx-react';
import DataTable from '../../../../components/DataTable/DataTable';
import GuestsFilters from './GuestsFilters';

const { guestsReportStore } = stores;

@observer
class GuestsReport extends Component {
  componentDidMount() {
    guestsReportStore.getReport();
  }

  onExportClick = (submittedValues) => {
    const params = this.parseParams(submittedValues);
    guestsReportStore.exportReport(params);
  };

  parseParams = (submittedValues) => {
    let params = {};
    if (submittedValues.guest) {
      params.guestId = submittedValues.guest._id;
    }
    return params;
  };

  onFiltersSearch = (submittedValues) => {
    const params = this.parseParams(submittedValues);
    guestsReportStore.getReport(params);
  };

  render() {
    return (
      <div>
        <h1 className="page-title">Guests Report</h1>
        <div className="actions">
          <GuestsFilters onSearch={this.onFiltersSearch} onExportClick={this.onExportClick} />
        </div>
        <DataTable columns={guestsReportStore.tableColumns} data={guestsReportStore.currentReport.slice()} />
      </div>
    );
  }
}

export default GuestsReport;