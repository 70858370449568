import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import Error from '../../components/forms/Error';
import Company from '../../models/Company';
import DatePicker from 'react-datepicker';
import { toJS } from 'mobx';
import stores from '../../stores';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

const { userStore } = stores;

class Settings extends Component {
  state = {
    newReportType: '',
    facilites: []
  };

  validateForm = (values) => {};

  onSubmitForm = (submittedValues) => {
    const company = new Company(submittedValues);

    userStore
      .updateCompanyDetails(company)
      .then(() => {
        toast('Settings saved!', { type: toast.TYPE.SUCCESS });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /*componentDidMount() {
    const company = new Company(toJS(userStore.currentCompany) || undefined);
    for (let i = 0; i < company.numOfFacilities; i++) {
      window.setFormValue('facility[facility-' + i + ']', company.facilities[i] && company.facilities[i].facilityName);
    }
  }*/

  getFacilitesObject = (numOfFacilities, facilities) => {
    let facilitiesArray = [];
    for (let i = 0; i < numOfFacilities; i++) {
      facilitiesArray.push({ id: i, facilityName: '' });
    }

    facilities &&
      Object.keys(facilities).forEach((key) => {
        let index = key.split('-')[1];
        if (facilities[key] && facilities[key].length > 0) {
          facilitiesArray[index] = { id: parseInt(index), facilityName: facilities[key] };
        } else {
          facilitiesArray[index] = { id: parseInt(index), facilityName: '' };
        }
      });

    return facilitiesArray;
  };

  render() {
    const company = new Company(toJS(userStore.currentCompany) || undefined);
    // console.log(company.facilities);
    const companyFacilities = toJS(company.facilities);
    const maxTime = dayjs()
      .endOf('day')
      .toDate();

    return (
      <div>
        <h1 className='page-title'>Settings</h1>
        <Form
          onSubmit={this.onSubmitForm}
          initialValues={company}
          mutators={{
            ...arrayMutators,
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            }
          }}
          validate={this.validateForm}
          render={({
            handleSubmit,
            form: {
              mutators: { push, pop, setValue }
            },
            submitFailed,
            submitting,
            pristine,
            errors,
            values
          }) => {
            if (!window.setFormValue) window.setFormValue = setValue;
            return (
              <form onSubmit={handleSubmit}>
                <div className='col-xl-4 col-xl-offset-4 col-sm-6 col-sm-offset-3'>
                  <div className='form-container'>
                    <div className='row'>
                      <label className='col-form-label font-weight-bold' htmlFor='automaticReleaseDatePicker'>
                        Automatic Release Time:
                      </label>
                      <Field name='autoReleaseTime'>
                        {({ input, meta }) => {
                          let value = input.value === '' ? null : input.value;
                          return (
                            <div>
                              <DatePicker
                                selected={value}
                                id={`automaticReleaseDatePicker`}
                                showTimeSelect
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                onFocus={input.onFocus}
                                showTimeSelectOnly
                                timeIntervals={5}
                                dateFormat='HH:mm'
                                timeFormat='HH:mm'
                                timeCaption='Time'
                                autoComplete='off'
                                className='form-control'
                              />
                              <div>
                                <input
                                  type='checkbox'
                                  id='disableAutomaticRelease'
                                  onClick={() => input.onChange('')}
                                />
                                <label htmlFor='disableAutomaticRelease'> Disable automatic release</label>
                              </div>
                            </div>
                          );
                        }}
                      </Field>
                      <Error name='autoReleaseTime' />
                    </div>
                    <div className='mt-2'>
                      <h1 className='page-title'>Sending Arrival SMS</h1>
                      {Object.keys(values.smsSendingHours).map((dayName) => {
                        return (
                          <div className='form-group text-left' key={`${dayName}`}>
                            <label className='text-capitalize font-weight-bold'>{dayName}</label>
                            <div className="timeRow">
                              <label className='f-1 col-form-label' htmlFor={`${dayName}.sendAt`}>
                                Send At
                              </label>
															<div className="f-2">
																<Field name={`smsSendingHours.${dayName}.sendAt`}>
																	{({ input, meta }) => {
																		const value = input.value === '' ? null : input.value;
																		return (
																			<DatePicker
																				selected={value}
																				id={`${dayName}.sendAt`}
																				showTimeSelect
																				onChange={input.onChange}
																				onBlur={input.onBlur}
																				onFocus={input.onFocus}
																				placeholderText='Send arrival SMS time, i.e 09:00'
																				showTimeSelectOnly
																				timeIntervals={5}
																				dateFormat='HH:mm'
																				timeFormat='HH:mm'
																				timeCaption='Time'
																				autoComplete='off'
																				className='form-control'
																			/>
																		);
																	}}
																</Field>
																<Error name={`smsSendingHours.${dayName}.sendAt`} />
															</div>
                              <label className='f-1 col-form-label' htmlFor={`${dayName}.waitUntil`}>
                                Wait until
                              </label>
															<div className="f-2">
																<Field name={`smsSendingHours.${dayName}.waitUntil`}>
																	{({ input, meta }) => {
																		let sendAt = values.smsSendingHours[dayName]['sendAt'];
																		if (sendAt === '' || !sendAt) {
																			sendAt = new dayjs().startOf('day').toDate();
																		}

																		const value = input.value === '' ? null : input.value;
																		return (
																			<DatePicker
																				selected={value}
																				id={`${dayName}.waitUntil`}
																				dateFormatCalendar='HH:mm'
																				showTimeSelect
																				onChange={input.onChange}
																				onBlur={input.onBlur}
																				onFocus={input.onFocus}
																				showTimeSelectOnly
																				timeIntervals={5}
																				minTime={sendAt}
																				maxTime={maxTime}
																				placeholderText='Waiting until which time?, i.e 10:30'
																				dateFormat='HH:mm'
																				timeFormat='HH:mm'
																				timeCaption='Time'
																				autoComplete='off'
																				className='form-control'
																			/>
																		);
																	}}
																</Field>
															</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {company.isWithReporting && (
                      <div className='mt-2'>
                        <h1 className='page-title'>Application Report Types</h1>
                        <FieldArray name='reportTypes'>
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div key={name}>
                                <label>{index + 1}.</label>
                                <Field name={name} component='input' placeholder='First Name' />
                                <span onClick={() => fields.remove(index)} style={{ cursor: 'pointer' }}>
                                  ❌
                                </span>
                              </div>
                            ))
                          }
                        </FieldArray>
                        <button type='button' onClick={() => push('reportTypes', '')}>
                          Add Type
                        </button>
                      </div>
                    )}
                    {company.isWithReservations && (
                      <div className='mt-2'>
                        <h1 className='page-title'>Reservations Names</h1>
                        <FieldArray name='facilities'>
                          {({ fields }) =>
                            fields.map((name, index) => (
                                <div key={name}>
                                  <label>{index + 1}.</label>
                                  <Field
                                    name={`${name}.facilityName`}
                                    type='text'
                                    component='input'
                                  />
                                </div>
                              )
                            )
                          }
                        </FieldArray>
                      </div>
                    )}
                    <button type='submit' className='mt-2' disabled={submitting}>
                      Save
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

export default Settings;
