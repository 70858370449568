import { action, observable } from 'mobx';
import dayjs from 'dayjs';
import React from 'react';
import API from '../../../utils/API/api';
import ReportsStore from '../reportsStore';

const tableColumns = [
  {
    Header: 'Report Type',
    accessor: 'subject'
  },
  {
    Header: 'Content',
    accessor: 'content'
  },
  {
    Header: 'Guest',
    accessor: 'guest',
  }, {
    Header: 'Employee',
    accessor: 'employee'
  }, {
    Header: 'Reported At',
    accessor: 'createdAt',
    Cell: ({value}) => (
      <span>{dayjs(value).format('YYYY-MM-DD HH:mm:ss')}</span>
    )
  }
];

class ApplicationReportStore extends ReportsStore {
  @observable selectedReportType;

  constructor(stores) {
    super(stores, tableColumns, API.company.reports.getApplicationReport, API.company.reports.exportApplicationReport)
  }

  @action
  setSelectedReportType = (reportType) => {
    this.selectedReportType = reportType;
  };
}

export default ApplicationReportStore;
