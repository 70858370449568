import axios from 'axios';
import AdminApi from './admin';
import CompanyApi from './company';
import stores from '../../stores';
import { getApiUrlEnvironment } from '../getEnvironment';

// const API_ROOT = `http://localhost:3000/`;
const API_ROOT = getApiUrlEnvironment();
// const API_ROOT = `http://18.222.159.216/dpm-management/`;
// const API_ROOT = `http://login.dpm-parking.com/`;

const apiInstance = axios.create({
  baseURL: API_ROOT,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  transformResponse: [
    function(data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return [];
      }
    },
  ],
});

apiInstance.interceptors.response.use(
  function(response) {
    return response.data;
  },
  function(error) {
    const { authStore } = stores;
    if (
      error.response &&
      error.response.status === 401 &&
      window.location.href.indexOf('login') === -1 &&
      window.location.href.indexOf('logout')
    ) {
      authStore.logout();
    }
    return Promise.reject(error.response);
  },
);

class Util {
  static makeCancelableRequest() {
    let call;
    return url => {
      if (call) {
        call.cancel();
      }
      call = axios.CancelToken.source();
      return apiInstance.get(url, { cancelToken: call.token }).catch(err => {
        if (axios.isCancel(err)) {
          return 'canceled';
        }
      });
    };
  }
}

class Auth {
  login(username, password) {
    const url = `admin/login`;
    return apiInstance
      .post(url, {
        username,
        password,
      })
      .then(response => {
        apiInstance.defaults.headers['x-csrf-token'] = response.csrfToken;
        return response;
      });
  }

  logout() {
    const url = `/logout`;
    return apiInstance.get(url);
  }

  isLoggedIn() {
    const url = `admin/isLoggedIn`;
    return apiInstance.get(url);
  }

  forgotPassword(email, phone) {
    const url = `/admin/forgotPassword`;
    return apiInstance.post(url, { email, phone });
  }

  isResetTokenValid(resetToken) {
    const url = `admin/isResetTokenValid?token=${resetToken}`;
    return apiInstance.get(url);
  }

  resetPasswordViaEmail(resetToken, newPassword) {
    const url = `admin/resetPasswordViaEmail?token=${resetToken}`;
    return apiInstance.post(url, { newPassword });
  }
}

class UserApi {
  static currentUser() {
    const url = `users/me`;
    return apiInstance.get(url).then(response => {
      apiInstance.defaults.headers['x-csrf-token'] = response.csrfToken;
      return response;
    });
  }

  static changePassword(currentPassword, newPassword) {
    const url = `/users/changePassword`;
    return apiInstance.post(url, { currentPassword, newPassword });
  }
}

export default {
  util: Util,
  auth: new Auth(),
  admin: new AdminApi(apiInstance),
  company: new CompanyApi(apiInstance),
  user: UserApi,
};
