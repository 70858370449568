import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import stores from '../../stores';

const { parkingsStore } = stores;

class CarNumberSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      selectedEmployee: null
    }
  }

  onSuggestionsFetchRequested = (value) => {
    const { type } = this.props;
    parkingsStore.getAutoCompleteEmployeesByCarNumber(value.value, type).then(results => {
      if (results && Array.isArray(results)) {
        this.setState({ employees: results })
      }
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
			employees: []
    });
  };

  getSuggestionValue = (suggestion) => {
    return suggestion._id;
  };

  renderSuggestion = (suggestion, { query }) => {
    const suggestionText = suggestion.carNumber;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);
    return (
      <span className="autocomplete-list-item">
        <span className="name">
        {
          parts.map((part, index) => {
            const className = part.highlight ? 'highlight' : null;
            return (
              <span className={className} key={index}>{part.text}</span>
            );
          })
        }
        </span> - {suggestion.type.toUpperCase()}</span>
    );
  };

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    if (children === null) return null;
    return (
      <div {...containerProps} className="autocomplete-list-container">
        {children}
      </div>
    );
  };

  onSuggestionSelected = (suggestion) => {
    this.setState({ selectedEmployee: suggestion });
  };

  render() {
    const { employees, selectedEmployee } = this.state;
    const { input, meta } = this.props;
    const inputProps = {
      placeholder: 'Enter car number...',
      ...input,
      value: input.value.carNumber ? input.value.carNumber : input.value,
      style: { outline: 'none' },
      className: 'form-control'
    };
    return (
      <Autosuggest
        suggestions={employees}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        onSuggestionSelected={(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
          input.onChange(suggestion);
          this.onSuggestionSelected(suggestion);
        }}
        inputProps={inputProps}
      />
    );
  }
}

CarNumberSelector.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.bool,
};

export default CarNumberSelector;
