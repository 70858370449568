import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from '../../components/modal/Modal';
import stores from '../../stores';
import EditParkModal from './SpecialParkingsComponent/SpecialParkingModal';
import DataTable from '../../components/DataTable/DataTable';
import moment from 'moment';

const { specialParksStore } = stores;
@withRouter
@observer
class SpecialParkings extends Component {
	constructor(props) {
		super(props);
	}

	onEditRow = row => {
		specialParksStore.editBranch(row._original);
	};

	render() {
		const { list, isEditModalShown, hideEditModal, selectedPark } = specialParksStore;

		const columns = [
			{
				Header: 'Company Name',
				accessor: 'companyName',
			},
			{
				Header: 'Company Number',
				accessor: 'company',
			},
			{
				Header: 'Parkinglot Name',
				accessor: 'companyParkinglot',
				filterMethod: (filter, row) => {
					if (!row['companyParkinglot']) return false;
					return row['companyParkinglot'].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
				},
			},
			{
				Header: 'Max Number Of Parkings',
				accessor: 'maxNumberOfParkings',
			},
			{
				Header: 'Available Parkings',
				accessor: 'availableParkings',
			},
			{
				Header: 'Parkinglot Number',
				accessor: 'parkinglotNumber',
			},
			{
				Header: 'Last Update',
				accessor: 'updatedAt',
				Cell: ({value}) => {
					if (value) return moment(value).format('HH:mm:ss DD/MM/YYYY');
					return '';
				},
				sortMethod: (a, b) => {
					if (a === b) {
						return 0;
					}
					const aMoment = moment(a);
					const bMoment = moment(b);
					return aMoment.isAfter(bMoment) ? 1 : -1;
				}
			},
		];

		return (
			<div className="full-height">
				<h1 className="page-title">Special Parkings</h1>
				<DataTable
					filterable
					data={list.slice()}
					columns={columns}
					defaultSort={[
						{
							id: 'preference',
							desc: false,
						},
					]}
				/>
				<Modal title="New Parking Lot" show={isEditModalShown} handleClose={hideEditModal}>
					<EditParkModal park={selectedPark} closeModal={hideEditModal} />
				</Modal>
			</div>
		);
	}
}

export default SpecialParkings;
