import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import stores from '../../stores';
import '../../styles/react-table.styles.scss';
import Modal from '../../components/modal/Modal';
import { deleteIcon, editIcon } from '../../assets';
import EditVacationModal from './VacationsComponents/EditVacationModal';
import DataTable from '../../components/DataTable/DataTable';

const { vacationsStore } = stores;
const  {userStore} = stores;

@withRouter
@observer
class Vacations extends Component {
  onEditRow = (row) => {
    vacationsStore.editVacation(row._original);
  };

  onDeleteRow = (row) => {
    const answer = window.confirm("Are you sure you want to delete this forever?");
    if (answer) {
      vacationsStore.delete(row._original._id);
    }
  };

  render() {
    const columns = [
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        Cell: ({ row, value }) => <span>{dayjs(value).format('YYYY-MM-DD HH:mm')}</span>,
      }, {
        Header: 'Start Date',
        accessor: 'startTime',
        Cell: ({ row, value }) => <span>{dayjs(value).format('YYYY-MM-DD HH:mm')}</span>,
      }, {
        Header: 'End Date',
        accessor: 'endTime',
        Cell: ({ row, value }) => <span>{dayjs(value).format('YYYY-MM-DD HH:mm')}</span>,
      }, {
        Header: 'Employee',
        accessor: 'employee.name'
      }, {
        Header: 'Vacation Type',
        accessor: 'type'
      }, {
        Header: 'Notes',
        accessor: 'comments'
      }, {
        Header: 'Edit',
        Cell: ({ row }) => <img src={editIcon} onClick={this.onEditRow.bind(this, row)} />,
        sortable: false
      }, {
        Header: 'Delete',
        Cell: ({ row }) => <img src={deleteIcon} onClick={this.onDeleteRow.bind(this, row)} />,
        sortable: false
      }
    ];
		// eslint-disable-next-line no-unused-expressions
		userStore.isAdmin  ? columns.push({
			Header: 'company name',
			accessor: 'companyName'}) : {};
    const { list, isEditModalShown, newVacation, hideEditModal, selectedVacation } = vacationsStore;
    return (
      <div className="companies-page full-height">
        <h1 className="page-title">Companies Vacations</h1>
        <div className="actions">
          <button type="button" onClick={newVacation}>New Company Vacation</button>
        </div>
        <DataTable
          data={list.slice()}
          columns={columns}
          defaultSorted={[
            {
              id: "startTime",
              desc: true
            },{
              id: "endTime",
              desc: true
            }
          ]}
        />
        <Modal title="New Vacation" show={isEditModalShown} handleClose={hideEditModal}>
          <EditVacationModal vacation={selectedVacation} closeModal={hideEditModal} />
        </Modal>
      </div>
    );
  }
}

export default Vacations;
