import { action, observable } from 'mobx';
import API from '../../utils/API/api';
import CrudStore from './crudStore';
import { ROLES } from '../../utils/common';
import { authorize } from '../../components/AuthrorizeHOC/AuthorizeHOC';

const defaultPermissions = [ROLES.COMPANY, ROLES.SENIOR_SECRETARY];
const extendedPermissions = [...defaultPermissions, ROLES.SECRETARY];

const permissions = {
	getById: extendedPermissions,
	getAll: extendedPermissions,
	create: defaultPermissions,
	update: defaultPermissions,
	delete: defaultPermissions,
};

class SpecialParksStore extends CrudStore {
	@observable selectedPark;
	@observable isEditModalShown;

	constructor(stores) {
		super(stores, API.company.specialParks, permissions);
		stores.userStore.onCompanyUserLoaded(user => {
			API.company.parkings.getSpecialParkings().then(res => {
				let data = res.data;
				if (res.status && data) {
					API.company.parkings.getParkinglotJsonNames().then(parkinglotNames => {
						if(user.company.name === "Matam") {
							let matamParkinglotJsonNames = parkinglotNames.data;
							for (let i = 0; i < data.length; i++) {
								data[i].companyParkinglot = matamParkinglotJsonNames[data[i].parkinglotNumber.toString()];
							}
							this.list = data;
						}
						else if (user.company.name === 'Nof_Galil'){
							let nof_galilParkinglotJsonNames = parkinglotNames.data;
							for (let i = 0; i < data.length; i++) {
								data[i].companyParkinglot = nof_galilParkinglotJsonNames[data[i].parkinglotNumber.toString()];
							}
							this.list = data;
						}
						else if(user.company.name === "Afcon") {
							let afconParkinglotNames = parkinglotNames.data;
							for (let i = 0; i < data.length; i++) {
								try {data[i].companyParkinglot = afconParkinglotNames.find(item => {return item.id === data[i].parkinglotNumber}).name}
								catch (e){
									// This here can be empty
								}

							}
							this.list = data;
						}
						else if(user.company.name === 'NationalLibrary'){
							let NationalLibraryParkinglotNames = parkinglotNames.data;
							for (let i = 0; i < data.length; i++) {
								try {data[i].companyParkinglot = NationalLibraryParkinglotNames.find(item => {return item.id === data[i].parkinglotNumber}).name}
								catch (e){
									// This here can be empty
								}

							}
							this.list = data;
						}
						else if(user.company.name === "Ipi") {
							let ipiParkinglotNames = parkinglotNames.data;
							for (let i = 0; i < data.length; i++) {
								try {data[i].companyParkinglot = ipiParkinglotNames.find(item => {return item.id === data[i].parkinglotNumber}).name}
								catch (e){
									// This here can be empty
								}

							}
							this.list = data;
						}
						else if(user.company.name === "Vitania") {
							let vitaniaParkinglotNames = parkinglotNames.data;
							for (let i = 0; i < data.length; i++) {
								try {data[i].companyParkinglot = vitaniaParkinglotNames.find(item => {return item.id === data[i].parkinglotNumber}).name}
								catch (e){
									// This here can be empty
								}

							}
							this.list = data;
						}
						else if(user.company.name === "Nesharim") {
							let nesharimParkinglotNames = parkinglotNames.data;
							for (let i = 0; i < data.length; i++) {
								try {data[i].companyParkinglot = nesharimParkinglotNames.find(item => {return item.id === data[i].parkinglotNumber}).name}
								catch (e){
									// This here can be empty
								}

							}
							this.list = data;
						}
						else if(user.company.name === "Mivne") {
							let MivneParkinglotJsonNames = parkinglotNames.data;
							for (let i = 0; i < data.length; i++) {
								data[i].companyParkinglot = MivneParkinglotJsonNames[data[i].parkinglotNumber.toString()];
							}
							this.list = data;
						}
						else if(user.company.name === "GavYam") {
							let GavYamParkinglotJsonNames = parkinglotNames.data;
							for (let i = 0; i < data.length; i++) {
								data[i].companyParkinglot = GavYamParkinglotJsonNames[data[i].parkinglotNumber.toString()];
							}
							this.list = data;
						}
					});
				}
			});
		});
	}

	@action
	editBranch = branch => {
		if (authorize(defaultPermissions)) {
			this.selectedPark = branch;
			this.showEditModal();
		}
	};

	@action
	showEditModal = () => {
		this.isEditModalShown = true;
	};

	@action
	hideEditModal = () => {
		this.isEditModalShown = false;
	};
}

export default SpecialParksStore;
