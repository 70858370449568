import { Field, Form } from 'react-final-form';
import React from 'react';
import BootstrapInput from '../../components/BootstrapInput/BootstrapInput';
import EmployeesGuestSelector from '../../components/EmployeesGuestSelector/EmployeesGuestSelector';

const Filters = ({ onSubmit, initialValues }) => {
	return (
		<Form
			onSubmit={onSubmit}
			initialValues={initialValues}
			render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
				return (
					<form onSubmit={handleSubmit}>
						<div className="container my-4 mx-auto">
						<div className="row col-sm-12">

							<div className="col-sm-10">
								<div className="row align-items-baseline">
									<div className="col">
										<label htmlFor="carNumber">Plate #</label>
									</div>
									<div className="col-sm-3">
										<Field name="carNumber" id="carNumber" component={BootstrapInput} />
									</div>
									<div className="col">
										<label>Employee name</label>
									</div>
									<div className="col-sm-4">
										<Field name="employee" component={EmployeesGuestSelector} />
									</div>

								</div>
							</div>
							<div className="col">
								<button type="submit" disabled={submitting} className="btn btn-primary btn-block">
									Search
								</button>
							</div>
						</div>

						</div>

					</form>
				);
			}}
		/>
	);
};

export default Filters;
