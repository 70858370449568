import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Camera, { cameraSerializer } from '../../../../models/Camera';
import { Field, Form } from 'react-final-form';
import stores from '../../../../stores';
import { generateOption } from '../../../../utils/common';
import Error from '../../../../components/forms/Error';
import BootstrapInput from '../../../../components/BootstrapInput/BootstrapInput';
import Select from 'react-select';
import InputWithCheckbox from '../../../../components/InputWithCheckbox/InputWithCheckbox';

const cameraTypeOptions = [generateOption('Enter', 'enter'), generateOption('Exit', 'exit')];
const autoExitOptions = [  generateOption('NO' , false), generateOption('YES' , true )];

class EditCameraModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecure : false
    }
  }

  onSubmitForm = (submittedValues) => {
    const camera = cameraSerializer(submittedValues);
    const { companyId } = this.props;
    camera.company = companyId;
    const isEditMode = this.props.camera && this.props.camera._id;

    if (!isEditMode) {
      stores.camerasStore.createCamera(camera, companyId).then((response) => {
        this.props.closeModal();
      }).catch(err => {
        console.error(err);
      })
    }
    else {
      stores.camerasStore.updateCamera(camera, companyId).then((response) => {
        this.props.closeModal();
      }).catch(err => {
        console.error(err);
      })
    }
  };

  validateForm = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Camera name is required';
    }
    if (!values.guid) {
      errors.guid = 'Guid is Required'
    }
    if (!values.type) {
      errors.type = 'Camera Type is Required';
    }
    if (!values.branch) {
      errors.branch = 'Branch is Required'
    }
    return errors
  };


  render() {
    const defaultValues = new Camera(this.props.camera || undefined);
    return (
      <Form onSubmit={this.onSubmitForm} initialValues={defaultValues} validate={this.validateForm}
            render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">Camera Name*:</div>
                    <div className="col">
                      <Field name="name" component={BootstrapInput} type="text" />
                      <Error name="name" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label htmlFor="guid">Guid*:</label>
                    </div>
                    <div className="col">
                      <Field
                        name="guid"
                        component={BootstrapInput}
                        type="text"
                        id="guid"
                      />
                      <Error name="guid" />
                    </div>
                  </div>
									<div className="row">
                    <div className="col">
                      <label htmlFor="externalCameraId">External Camera ID:</label>
                    </div>
                    <div className="col">
                      <Field
                        name="externalCameraId"
                        component={BootstrapInput}
                        type="text"
                        id="externalCameraId"
                      />
                      <Error name="externalCameraId" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">Type*:</div>
                    <div className="col">
                      <Field name="type" component="select" value={cameraTypeOptions[0].value}>
                        {cameraTypeOptions.map((option, idx) => {
                          return <option value={option.value} key={idx}>{option.label}</option>
                        })}
                      </Field>
                      <Error name="type" />
                    </div>
                  </div>
									<div className="row">
										<div className="col">Auto Exit*:</div>
										<div className="col">
											<Field name="autoExit" component="select" value={autoExitOptions[0].value}>
												{autoExitOptions.map((option, idx) => {
													return <option value={option.value} key={idx}>{option.label}</option>
												})}
											</Field>
											<Error name="autoExit" />
										</div>
									</div>
                  <div className="row">
                    <div className="col">Branch:</div>
                    <div className="col">
                      <Field name="branch" component="select" value={this.props.branches[0]}>
                        <option value="">Select Branch</option>
                        {this.props.branches.map((branch) => {
                          return <option value={branch._id} key={branch._id}>{branch.name}</option>
                        })}
                      </Field>
                      <Error name="branch" />
                    </div>
                  </div>
									<div className="row">
                    <div className="col">Parking Lot:</div>
                    <div className="col">
											<Field name="park">
												{({ input, meta }) => {
													const selected = this.props.parkingLots.find(({ value }) => value === input.value.value );
													return (
														<Select
															className="basic-single"
															classNamePrefix="select"
															{...input}
															isClearable
															styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
															menuPortalTarget={document.body}
															value={selected}
															onChange={input.onChange}
															options={this.props.parkingLots}
														/>
													);
												}}
											</Field>
                      <Error name="park" />
                    </div>
                  </div>
									<div className="row">
                    <div className="col">
                      <label htmlFor="apiUrl">Api Url:</label>
                    </div>
                    <div className="col">
                      <InputWithCheckbox name="apiUrl" />
                      <Error name="apiUrl" />
                    </div>
                  </div>
                  <button type="submit" disabled={submitting}>
                    Save
                  </button>
                </form>
              )
            }} />
    );
  }
}

EditCameraModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  camera: PropTypes.object,
  branches: PropTypes.array,
  parkingLots: PropTypes.array,
  companyId: PropTypes.string.isRequired
};

EditCameraModal.defaultProps = {
  camera: undefined,
  branches: [],
};

export default EditCameraModal;
