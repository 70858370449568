import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import stores from '../../stores';
import classnames from 'classnames';

const { commonStore } = stores;

@observer
class NavbarLink extends Component {
  render() {
    const isActive = commonStore.locationPathname === this.props.to;
    const navLinkClassname = classnames({
      'nav-link': true,
      active: isActive
    });
    return (
      <NavLink to={this.props.to} className={navLinkClassname}>
        {this.props.children}
      </NavLink>
    );
  }
}

NavbarLink.propTypes = {
  to: PropTypes.string.isRequired
};

export default NavbarLink;