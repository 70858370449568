import { action, observable } from 'mobx';
import API from '../../utils/API/api';
import CrudStore from './crudStore';
import Guest from '../../models/Guest';
import { CAR_NUMBER_EXISTS_ERROR, ROLES, ROUTES, SAME_USER_AND_PASSWORD_EXISTS } from '../../utils/common';
import { authorize } from '../../components/AuthrorizeHOC/AuthorizeHOC';

const defaultPermissions = [ROLES.COMPANY, ROLES.SECRETARY, ROLES.SENIOR_SECRETARY];

const permissions = {
  getById: defaultPermissions,
  getAll: defaultPermissions,
  create: defaultPermissions,
  update: defaultPermissions,
  delete: defaultPermissions
};

class GuestsStore extends CrudStore {
  @observable selectedGuest;
  @observable isEditModalShown;
  @observable isUploadFileModalShown;

  constructor(stores) {
    super(stores, API.company.guests, permissions);
    stores.commonStore.onLocationChanged(location => {
      if (location.path === ROUTES.GUESTS || location.path ===ROUTES.LPR_DASHBOARD) {
        this.getAll();
        stores.parksStore.getAll();
      }
    })
  }

  @action
  create = (object) => {
    if (authorize(defaultPermissions)) {
      this.commonStore.setIsLoading(true);
      return this.api.create(object).then(action(response => {
        if (response.results === -1) {
          alert("Sorry ... You have reach the maximum users number you have purchase, Please contact the DPM support team support@dpm-parking.com");
          throw new Error('max users limit reached');
        }
        else if (response.results === -2) {
          alert(CAR_NUMBER_EXISTS_ERROR);
          throw new Error(CAR_NUMBER_EXISTS_ERROR);
        }
        else if (response.results === -3) {
          alert(SAME_USER_AND_PASSWORD_EXISTS);
          throw new Error(SAME_USER_AND_PASSWORD_EXISTS);
        }
        else if (response.results && response.results._id) {
          this.refreshGuest(response.results._id);
          return response;
        }
        else {
          alert("Error ...Please try again.");
          throw new Error('Error ...Please try again.');
        }
      })).finally(() => {
        this.commonStore.setIsLoading(false);
      });
    }
    return Promise.reject('Not authorized to create new parking');
  };

  @action
  update(object) {
    return super.update(object).then(response => {
      if (response.results === -2) {
        alert(CAR_NUMBER_EXISTS_ERROR);
        throw new Error(CAR_NUMBER_EXISTS_ERROR);
      }
    })
  }

  @action
  refreshGuest(guestId) {
    this.commonStore.setIsLoading(true);
    this.api.getById(guestId).then(action(response => {
      const guestIdx = this.list.findIndex(p => p._id === guestId);
      if (guestIdx > -1) {
        this.list[guestIdx] = response.results;
      }
      else {
        this.list.push(response.results);
      }
      this.commonStore.setIsLoading(false);
    })).catch(e => {
      this.commonStore.setIsLoading(false);
    });
  }

  @action
  editGuest = (guest) => {
    if (authorize(defaultPermissions)) {
      this.selectedGuest = guest;
      this.showEditModal();
    }
  };

  @action
  newGuest = () => {
    if (authorize(defaultPermissions)) {
      this.selectedGuest = new Guest();
      this.showEditModal();
    }
  };

  @action
  showEditModal = () => {
    this.isEditModalShown = true;
  };

  @action
  hideEditModal = () => {
    this.isEditModalShown = false
  };

  @action
  showUploadModal = () => {
    this.isUploadFileModalShown = true;
  };

  @action
  hideUploadModal = () => {
    this.isUploadFileModalShown = false
  };

  @action
  onUploadExcelButtonClick = () => {
    if (authorize(defaultPermissions)) {
      this.showUploadModal();
    }
  };

  @action
  changePassword(userId, newPassword) {
    if (authorize(defaultPermissions)) {
      this.commonStore.setIsLoading(true);
      return this.api.changePassword(userId, newPassword).then(response => {
        if (response.results && response.results === -3) {
          throw new Error('Please choose another password');
        }
      }).finally(() => this.commonStore.setIsLoading(false));
    }
    return Promise.reject('Unauthorized');
  }

  @action
  uploadEmployeesFile(file) {
    if (authorize(defaultPermissions)) {
      this.commonStore.setIsLoading(true);
      return this.api.importExcelFile(file).then((response) => {
        this.getAll();
        return response;
      }).finally(() => this.commonStore.setIsLoading(false));
    }
    return Promise.reject('Unauthorized');
  }
}

export default GuestsStore;
