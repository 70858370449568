import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import stores from '../../../../stores';
import { generateOption, ROLES } from '../../../../utils/common';
import Error from '../../../../components/forms/Error';
import Select from 'react-select';
import User from '../../../../models/User';
import BootstrapInput from '../../../../components/BootstrapInput/BootstrapInput';
import { toJS } from 'mobx';
import sortBy from 'lodash/sortBy';
import { toast } from 'react-toastify';

const typeOptions = [generateOption('Company Admin', ROLES.COMPANY), generateOption('Senior Secretary', ROLES.SENIOR_SECRETARY), generateOption('Secretary', ROLES.SECRETARY)];

class EditUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: props.user && props.user._id
    }
  }

  onSubmitForm = (submittedValues) => {
    const user = new User(submittedValues);
    const isEditMode = this.props.user && this.props.user._id;
    if (!isEditMode) {
      stores.usersStore.create(user).then((response) => {
        this.props.closeModal();
      }).catch(err => {
        if (err && err.data && err.data.message) {
          toast(err.data.message, { type: toast.TYPE.ERROR });
        }
        console.error(err);
      })
    }
    else {
      stores.usersStore.update(user).then((response) => {
        this.props.closeModal();
      }).catch(err => {
        console.error(err);
      })
    }
  };

  validateForm = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = 'Username is required';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    }
    if (values._id === undefined && !values.password) {
      errors.password = 'Password is required';
    }
    if (!values.type) {
      errors.type = 'Type is Required'
    }
    else if (values.type === 'company' && !values.company) {
      errors.company = 'Company is required';
    }
    return errors
  };

  render() {
    const companiesList = sortBy(toJS(stores.companiesStore.companies.map(c => ({label: c.name, value: c._id }))), 'label');
    const initialValues = new User(this.props.user);
    const isEditMode = initialValues._id !== undefined;
    return (
      <Form onSubmit={this.onSubmitForm} validate={this.validateForm} initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <form onSubmit={handleSubmit} id="form1">
                  <div className="row">
                    <div className="col">Username (login)*</div>
                    <div className="col">
                      <Field
                        name="username"
                        component={BootstrapInput}
                        type="text"
                      />
                      <Error name="username" />
                    </div>
                  </div>
                  {!isEditMode && <div className="row">
                    <div className="col">Password*</div>
                    <div className="col">
                      <Field
                        name="password"
                        component={BootstrapInput}
                        type="text"
                      />
                      <Error name="password" />
                    </div>
                  </div>}
                  <div className="row">
                    <div className="col">Email*:</div>
                    <div className="col">
                      <Field
                        name="email"
                        component={BootstrapInput}
                        type="text"
                      />
                      <Error name="email" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label htmlFor="company">Company*</label>
                    </div>
                    <div className="col">
                      <Field
                        name="company"
                        component={BootstrapInput}
                        type="text"
                        id="company"
                      >
                        {({ input, meta }) => {
                          return (
                            <>
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                {...input}
                                value={companiesList.find(({ value }) => value === input.value)}
                                onChange={({ value }) => input.onChange(value)}
                                options={companiesList}
                              />
                            </>
                          )
                        }
                        }
                      </Field>
                      <Error name="company" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label>Type*</label>
                    </div>
                    <div className="col">
                      <Field
                        name="type"
                      >
                        {({ input, meta }) => {
                          return (
                            <>
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="type"
                                {...input}
                                value={typeOptions.filter(({ value }) => value === input.value)}
                                onChange={({ value }) => input.onChange(value)}
                                options={typeOptions}
                              />
                            </>
                          )
                        }
                        }
                      </Field>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label htmlFor="fullname">Full name:</label>
                    </div>
                    <div className="col">
                      <Field
                        name="name"
                        component={BootstrapInput}
                        type="text"
                        id="fullname"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">Phone</div>
                    <div className="col">
                      <Field
                        name="phone"
                        component={BootstrapInput}
                        type="text"
                      />
                    </div>
                  </div>
                  <button type="submit" disabled={submitting}>
                    Save
                  </button>
                </form>
              )
            }} />
    );
  }
}

EditUserModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  user: PropTypes.object
};

export default EditUserModal;
