import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from '../../stores';
import Modal from '../../components/modal/Modal';
import EditCompanyModal from './CompaniesComponents/EditComapnyModal/EditCompanyModal';
import { camerasIcon, deleteIcon, editIcon } from '../../assets';
import IsEnabledIcon from '../../components/CrudTable/IsEnabledIcon';
import DataTable from '../../components/DataTable/DataTable';
import dayjs from 'dayjs';

const { companiesStore } = stores;

const booleanOptions = [
  { label: 'All', value: '' },
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

const BooleanFilterComponent = ({ filter, onChange }) => {
  return (
    <select
      onChange={event => onChange(event.target.value)}
      style={{ width: "100%" }}
      value={filter ? filter.value : ""}
    >
      {booleanOptions.map(option => {
        return <option value={option.value} key={option.value}>{option.label}</option>
      })}
    </select>
  )
};

@withRouter
@observer
class Companies extends Component {
  onEditRow = (row) => {
    companiesStore.editCompany(row._original._id);
  };

  onDeleteRow = (row) => {
    const answer = window.confirm("Are you sure you want to delete this forever?");
    if (answer) {
      stores.companiesStore.deleteCompany(row._original._id);
    }
  };

  render() {
    const { companiesList, isEditCompanyModalShown, newCompany, hideEditCompanyModal, selectedCompany } = companiesStore;
    const columns = [
      {
        Header: 'Company ID',
        accessor: 'idNumber',
        Footer: (columnProps) => {
          return (
            <span>
            <strong>Total: </strong>{columnProps.data.length}
          </span>
          )
        },
      }, {
        Header: 'Company Name',
        accessor: 'name',
        filterMethod: (filter, row) => {
          if (!row['name']) return false;
          return row['name'].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
        },
      }, {
        Header: 'Start Date',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          if (value) {
            return dayjs(value).format('YYYY-MM-DD HH:mm');
          }
          return null;
        }
      }, {
        Header: 'Active?',
        accessor: 'isActive',
        Cell: IsEnabledIcon,
        Filter: BooleanFilterComponent
      }, {
        Header: 'Max Users',
        accessor: 'maxUsers',
        Cell: ({ original, value }) => <span>({original.employeesCount}/<strong>{value || 0}</strong>)</span>,
        filterable: false
      }, {
        Header: 'Max Parkings',
        accessor: 'maxParkings',
        Cell: ({ original, value }) => <span>({original.parkingsCount}/<strong>{value || 0}</strong>)</span>,
        filterable: false
      }, {
        Header: 'No. parkingLots',
        accessor: 'parkingLotsCount'
      }, {
        Header: 'GPS?',
        accessor: 'isWithGPS',
        Cell: IsEnabledIcon,
        Filter: BooleanFilterComponent
      }, {
        Header: 'With virtual?',
        accessor: 'isWithVirtualParking',
        Cell: IsEnabledIcon,
        Filter: BooleanFilterComponent
      }, {
        Header: 'Edit',
        Cell: ({ row }) => <img src={editIcon}  alt="editIcon" onClick={this.onEditRow.bind(this, row)} />,
        sortable: false,
        filterable: false
      }, {
        Header: 'Delete',
        Cell: ({ row }) => <img src={deleteIcon} alt="deleteIcon" onClick={this.onDeleteRow.bind(this, row)} />,
        sortable: false,
        filterable: false
      }, {
        Header: 'Cameras',
        Cell: (props) => <Link to={`/cameras/${props.original._id}`}><img src={camerasIcon} alt="camerasIcon"
                                                                          style={{ width: 32, height: 32 }} /></Link>,
        sortable: false,
        filterable: false
      }
    ];
    return (
      <div className="companies-page full-height">
        <h1 className="page-title">Companies</h1>
        <div className="actions">
          <strong className="mr-2">Remaining SMS Quantity: {companiesStore.smsCredit}</strong>
          <button type="button" onClick={newCompany}>New Company</button>
        </div>
        <DataTable
          filterable
          data={companiesList.slice()}
          columns={columns}
        />
        <Modal title="New Company" show={isEditCompanyModalShown} handleClose={hideEditCompanyModal}>
          <EditCompanyModal company={selectedCompany} closeModal={hideEditCompanyModal} />
        </Modal>
      </div>
    );
  }
}

export default Companies;
