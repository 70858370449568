import React, { Component } from 'react';
import stores from '../../../../stores/index';
import { observer } from 'mobx-react';
import DataTable from '../../../../components/DataTable/DataTable';
import ParkingsFilters from './ParkingsFilters';

const { parkingsReportStore, userStore } = stores;

@observer
class ParkingsReport extends Component {
  componentDidMount() {
    parkingsReportStore.getReport();
		if (userStore.currentUser.company.isWithWorkstation) {
			parkingsReportStore.tableColumns[1].Header = "Workstation Name";
		}
  }

  onExportClick = (submittedValues) => {
    const params = this.parseParams(submittedValues);
    parkingsReportStore.exportReport(params);
  };

  parseParams = (submittedValues) => {
    let params = {};
    if (submittedValues.startDate) {
      params.startDate = submittedValues.startDate.toISOString();
    }
    if (submittedValues.endDate) {
      params.endDate = submittedValues.endDate.toISOString();
    }
    if (submittedValues.parking) {
      params.parking = submittedValues.parking;
    }
    if (submittedValues.parkingLotId) {
      params.parkingLotId = submittedValues.parkingLotId;
    }
    if (submittedValues.employee) {
      params.employeeId = submittedValues.employee._id;
    }
    if (submittedValues.guest) {
      params.guestId = submittedValues.guest._id;
    }
    return params;
  };

  onFiltersSearch = (submittedValues) => {
    const params = this.parseParams(submittedValues);
    parkingsReportStore.getReport(params);
  };

  render() {
    const currentUser = userStore.currentUser;
    return (
      <div>
        <h1 className="page-title">{currentUser.company.isWithWorkstation ? 'Workstations' : 'Parkings'} Report</h1>
        <div className="actions">
          <ParkingsFilters onSearch={this.onFiltersSearch} onExportClick={this.onExportClick} />
        </div>
        <DataTable columns={parkingsReportStore.tableColumns} data={parkingsReportStore.currentReport.slice()} />
      </div>
    );
  }
}

export default ParkingsReport;
