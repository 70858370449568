import React from 'react';
import stores from '../../stores/index';
import isAuthorized from '../../utils/isAuthorized';
import { NO_PERMISSION_ERROR } from '../../utils/common';
import { toast } from 'react-toastify';

const AuthorizeHOC = (allowedRoles) => {
  return (WrappedComponent) => {
    return class WithAuthorization extends React.Component {
      constructor(props) {
        super(props);
      }

      render() {
        const { currentUser } = stores.userStore;

        if (currentUser && allowedRoles.includes(currentUser.type)) {
          return <WrappedComponent {...this.props} />
        } else {
          return null;
        }
      }
    }
  }
};

export default AuthorizeHOC;

/*
export function authorizeDecorator(allowedRoles) {
  const newFn = (oldFn, args) => {
    if (stores && stores.userStore) {
      if (isAuthorized(allowedRoles, stores.userStore.currentUser)) {
        return oldFn.apply(this, args);
      }
      else {
        toast(NO_PERMISSION_ERROR, { type: toast.TYPE.ERROR });
        console.error('No permissions')
      }
    }
  };

  return function (target, property, descriptor) {
    if (descriptor) {
      // @action method() { }
      if (descriptor.value) {
        return {
          value: () => {
            return newFn(descriptor.value)
          },
          enumerable: false,
          configurable: true,
          writable: true
        }
      }
      // babel only: @action method = () => {}
      const { initializer } = descriptor;
      return {
        enumerable: false,
        configurable: true,
        writable: true,
        initializer: () => {
          // N.B: we can't immediately invoke initializer; this would be wrong
          // return newFn(initializer.call(this))
          return newFn(initializer)
        }
      }
    }
  };
}*/

export function authorize(allowedRoles) {
  if (stores && stores.userStore) {
    if (isAuthorized(allowedRoles, stores.userStore.currentUser)) {
      return true;
    }
    else {
      toast(NO_PERMISSION_ERROR, { type: toast.TYPE.ERROR });
      console.error('No permissions')
    }
  }
}
