import React from 'react';
import { observer } from 'mobx-react';
import stores from '../../stores';
import './header.style.scss';
import LoggedInView from './HeaderComponents/LoggedInView';

@observer
class Header extends React.Component {
  render() {
    if (!stores.userStore.currentUser) return null;
    return (
      <nav className="navbar navbar-light">
        <div className="container-fluid">
          <LoggedInView currentUser={stores.userStore.currentUser} />
        </div>
      </nav>
    );
  }
}

export default Header;
