import { action, computed, observable } from 'mobx';
import API from '../../utils/API/api';
import CrudStore from './crudStore';
import { ROLES } from '../../utils/common';
import { authorize } from '../../components/AuthrorizeHOC/AuthorizeHOC';

const defaultPermissions = [ROLES.ADMIN, ROLES.COMPANY, ROLES.SENIOR_SECRETARY];
const extendedPermissions = [...defaultPermissions, ROLES.SECRETARY];

const permissions = {
  getById: extendedPermissions,
  getAll: extendedPermissions,
  create: defaultPermissions,
  update: defaultPermissions,
  delete: defaultPermissions
};

class BranchesStore extends CrudStore {
  @observable selectedBranch;
  @observable isEditModalShown;

  constructor(stores) {
    super(stores, API.company.branches, permissions);
  }

  @action
  getBranchesByCompany(companyId) {
    return API.admin.branches.getByCompanyId(companyId).then(response => response.results);
  }

  @action
  editBranch = (branch) => {
    if (!authorize(defaultPermissions)) {
      return;
    }
    this.selectedBranch = branch;
    this.showEditModal();
  };

  @action
  newBranch = () => {
    if (!authorize(defaultPermissions)) {
      return;
    }
    this.selectedBranch = null;
    this.showEditModal();
  };

  @action
  showEditModal = () => {
    this.isEditModalShown = true;
  };

  @action
  hideEditModal = () => {
    this.isEditModalShown = false
  };

  @computed
  get branchesDropdownOptions() {
    return this.list.map(item => ({ label: item.name, value: item._id }));
  }

}

export default BranchesStore;