import { action, observable } from 'mobx';
import API from '../../utils/API/api';
import { ROUTES } from '../../utils/common';

class BookingsStore {
  @observable bookings = [];

  constructor(stores) {
    this.api = API.company.bookings;
    this.commonStore = stores.commonStore;
    stores.commonStore.onLocationChanged(location => {
      if (location.path === ROUTES.BOOKINGS) {
        this.loadAllBookings();
      }
    });
  }

  @action
  async loadAllBookings() {
    try {
      this.commonStore.setIsLoading(true);
      const results = await this.api.getFutureBookings();
      if (results.results) {
        this.bookings = results.results;
      }
      this.commonStore.setIsLoading(false);
    }
    catch (e) {
      console.error(e);
      this.commonStore.setIsLoading(false);
    }
  }

  @action
  async deleteBooking(bookingId) {
    if (bookingId) {
      const isConfirm = window.confirm('Are you sure?');
      if (isConfirm) {
        try {
          this.commonStore.setIsLoading(true);
          await this.api.deleteBooking(bookingId);
          this.loadAllBookings();
        } catch (e) {
          console.error(e);
          this.commonStore.setIsLoading(false);
        }
      }
    }
  }
}

export default BookingsStore;
