import React, { Component } from 'react';
import stores from '../../../../stores/index';
import { observer } from 'mobx-react';
import DataTable from '../../../../components/DataTable/DataTable';
import ApplicationReportFilters from './ApplicationReportFilters';

const { applicationReportStore } = stores;

@observer
class ApplicationReport extends Component {
  componentDidMount() {
    applicationReportStore.getReport();
  }

  onExportClick = (submittedValues) => {
    const params = this.parseParams(submittedValues);
    applicationReportStore.exportReport(params);
  };

  parseParams = (submittedValues) => {
    let params = {};
    if (submittedValues.reportType) {
      params.reportType = submittedValues.reportType;
    }
    return params;
  };

  onFiltersSearch = (submittedValues) => {
    const params = this.parseParams(submittedValues);
    applicationReportStore.getReport(params);
  };

  render() {
    return (
      <div>
        <h1 className="page-title">Application Report</h1>
        <div className="actions">
          <ApplicationReportFilters onSearch={this.onFiltersSearch} onExportClick={this.onExportClick} />
        </div>
        <DataTable columns={applicationReportStore.tableColumns} data={applicationReportStore.currentReport.slice()} />
      </div>
    );
  }
}

export default ApplicationReport;