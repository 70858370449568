const defaultEmployeeObject = {
	_id: undefined,
	name: '',
	company: null,
	phone: '',
	email: '',
	parking: null,
	isActive: true,
	customId: '',
	carNumbers: [],
	deviceToken: undefined,
	deviceType: undefined,
	shouldSentSMS: true,
	allowedParks: [],
	shouldChangePasswordAfterLogin: true,
	holdRange : '',
	Sunday:true,
	Monday:true,
	Tuesday:true,
	Wednesday:true,
	Thursday:true,
	Friday:true,
	Saturday:true,
	smsByDays: {
		Sunday: { isChecked: false, parking: null },
		Monday: { isChecked: false, parking: null },
		Tuesday: { isChecked: false, parking: null },
		Wednesday: { isChecked: false, parking: null },
		Thursday: { isChecked: false, parking: null },
		Friday: { isChecked: false, parking: null },
		Saturday: { isChecked: false, parking: null },
	}
};

export const ResponseType = {
	NOT_RESPONDED: 'not_responded',
	ARRIVE: 'arrive',
	DOES_NOT_ARRIVE: 'does_not_arrive',
	HOLD_PARKING_WITHOUT_ARRIVING: 'hold_parking_without_arriving',
};

export default class Employee {
	constructor(rawEmployee = defaultEmployeeObject) {
		this._id = rawEmployee._id;
		this.name = rawEmployee.name;
		this.company = rawEmployee.company;
		this.password = rawEmployee.password;
		this.phone = rawEmployee.phone;
		this.email = rawEmployee.email;
		this.isActive = rawEmployee.isActive;
		this.customId = rawEmployee.customId || '';
		this.parking = rawEmployee.parking;
		this.carNumbers = rawEmployee.carNumbers;
		this.maxConcurrentCars = rawEmployee.maxConcurrentCars;
		this.deviceToken = rawEmployee.device_token;
		this.deviceType = rawEmployee.device_type;
		this.shouldSentSMS = rawEmployee.shouldSentSMS;
		this.shouldChangePasswordAfterLogin = rawEmployee.shouldChangePasswordAfterLogin;
		this.allowedParks = rawEmployee.allowedParks && Array.isArray(rawEmployee.allowedParks) ? rawEmployee.allowedParks.map(c => c && c.hasOwnProperty('_id') ? c._id : c) : [];
		this.holdRange = rawEmployee.holdRange;
		this.Sunday = rawEmployee.Sunday || undefined ;
		this.Monday = rawEmployee.Monday || undefined ;
		this.Tuesday = rawEmployee.Tuesday || undefined ;
		this.Wednesday = rawEmployee.Wednesday || undefined ;
		this.Thursday = rawEmployee.Thursday || undefined ;
		this.Friday = rawEmployee.Friday || undefined ;
		this.Saturday = rawEmployee.Saturday || undefined ;
		this.smsByDays = { ...rawEmployee.smsByDays } || undefined
	}
}

export function employeeSerializer(employee) {
	return {
		_id: employee._id,
		name: employee.name,
		company: (employee.company && employee.company._id) ? employee.company._id : employee.company,
		password: employee.password,
		phone: employee.phone,
		email: employee.email,
		isActive: employee.isActive,
		customId: employee.customId,
		parking: employee.parking,
		carNumbers: employee.carNumbers.map(carnumber =>
			(carnumber.match(/\d+/g) || [carnumber]).join('').trim()
		),
		maxConcurrentCars: employee.maxConcurrentCars,
		shouldSentSMS: employee.shouldSentSMS,
		shouldChangePasswordAfterLogin: employee.shouldChangePasswordAfterLogin,
		allowedParks: employee.allowedParks && Array.isArray(employee.allowedParks) ? employee.allowedParks.map(c => c && c.hasOwnProperty('_id') ? c._id : c) : [],
		holdRange: employee.holdRange,
		Sunday: employee.Sunday,
		Monday: employee.Monday,
		Tuesday: employee.Tuesday,
		Wednesday: employee.Wednesday,
		Thursday: employee.Thursday,
		Friday: employee.Friday,
		Saturday: employee.Saturday,
	};
}
