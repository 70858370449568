import React, { Component } from 'react';
import stores from '../../stores';
import { withRouter } from 'react-router-dom';

@withRouter
class Logout extends Component {
  componentDidMount() {
    const isSure = window.confirm('Are you sure you want to logout?');
    if (isSure) {
      stores.authStore.logout().then(() => {
        this.props.history.replace('/login');
      })
    }
    else {
      this.props.history.goBack();
    }
  }

  render() {
    return null;
  }
}

export default Logout;
