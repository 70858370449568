export const defaultParkObject = {
  _id: '',
  name: '',
  preference: '',
	parentParking: null,
  location: {
    coordinates: [0, 0]
  },
  geoFenceRange: 0,
  distanceThreshold: 0,
  branch: null,
	map_url:''
};

class Park {
  constructor(rawPark = defaultParkObject) {
    this._id = rawPark._id;
    this.name = rawPark.name;
    this.preference = rawPark.preference;
		this.parentParking = rawPark.parentParking;
    this.location = rawPark.location;
    this.geoFenceRange = rawPark.geoFenceRange;
    this.distanceThreshold = rawPark.distanceThreshold;
    this.maxLprParking = rawPark.maxLprParking;
    this.branch = rawPark.branch && rawPark.branch._id ? rawPark.branch._id : rawPark.branch;
		this.map_url = rawPark.map_url;
  }

  asJson() {
    return {
      _id: this._id,
      name: this.name,
      preference: this.preference,
			parentParking: this.parentParking,
      location: this.location,
      geoFenceRange: this.geoFenceRange,
      distanceThreshold: this.distanceThreshold,
      branch: this.branch ? this.branch.id : null,
			maxLprParking: this.maxLprParking,
			map_url:this.map_url
    }
  }
}

export default Park;
