import AuthStore from './view/authStore';
import UserStore from './logic/userStore';
import CommonStore from './logic/commonStore';
import CompaniesStore from './logic/companiesStore';
import CompaniesViewStore from './view/companiesViewStore';
import CamerasStore from './logic/camerasStore';
import CamerasViewStore from './view/camerasViewStore';
import BranchesStore from './logic/branchesStore';
import LogsViewStore from './view/logsViewStore';
import SmsLogsViewStore from './view/smsLogsViewStore';
import UsersViewStore from './view/usersViewStore';
import UsersStore from './logic/usersStore';
import ReservationsStore from './logic/reservationsStore';
import ParksStore from './logic/parksStore';
import ParkingsStore from './logic/parkingsStore';
import EmployeesStore from './logic/employeesStore';
import VacationsStore from './logic/vacationsStore';
import GuestsStore from './logic/guestsStore';
import ConstantEmployeeReportStore from './logic/reports/ConstantEmployeeReportStore';
import EfficiencyReportStore from './logic/reports/EfficiencyReportStore';
import EmployeesReportStore from './logic/reports/EmployeesReportStore';
import ParkingsReportStore from './logic/reports/ParkingsReportStore';
import GuestsReportStore from './logic/reports/GuestsReportStore';
import LprLogsReportStore from './logic/reports/LprLogsReportStore';
import LprLogsNotUsersReportStore from './logic/reports/LprLogsNotUsersReportStore';
import LprLogsReportPerParkStore from './logic/reports/LprLogsReportPerParkStore';
import LprEfficiencyReportStore from './logic/reports/LprEfficiencyReportStore';
import LprLogsViewStore from './view/lprLogsViewStore';
import ArrivalLogsReportStore from './logic/reports/ArrivalLogsReportStore';
import PushNotificationsStore from './logic/pushNotificationsStore';
import ApplicationReportStore from './logic/reports/ApplicationReportStore';
import BookingsStore from './logic/bookingsStore';
import SpecialParksStore from './logic/specialParksStore';
import LprDashboardStore from './logic/lprDashboardStore';

class Stores {
  constructor() {
    this.authStore = new AuthStore();
    this.userStore = new UserStore(this);
    this.commonStore = new CommonStore();
    this.companiesStore = new CompaniesStore(this);
    this.companiesViewStore = new CompaniesViewStore();
    this.camerasStore = new CamerasStore(this);
    this.camerasViewStore = new CamerasViewStore();
    this.branchesStore = new BranchesStore(this);
    this.logsViewStore = new LogsViewStore(this);
    this.smsLogsViewStore = new SmsLogsViewStore(this);
    this.lprLogsViewStore = new LprLogsViewStore(this);
    this.usersStore = new UsersStore(this);
    this.bookingsStore = new BookingsStore(this);
    this.usersViewStore = new UsersViewStore();
    this.reservationsStore = new ReservationsStore(this);
    this.parksStore = new ParksStore(this);
    this.specialParksStore = new SpecialParksStore(this);
    this.parkingsStore = new ParkingsStore(this);
    this.employeesStore = new EmployeesStore(this);
    this.vacationsStore = new VacationsStore(this);
    this.guestsStore = new GuestsStore(this);
    this.constantEmployeeReportStore = new ConstantEmployeeReportStore(this);
    this.efficiencyReportStore = new EfficiencyReportStore(this);
    this.employeesReportStore = new EmployeesReportStore(this);
    this.guestsReportStore = new GuestsReportStore(this);
    this.parkingsReportStore = new ParkingsReportStore(this);
    this.arrivalLogsReportStore = new ArrivalLogsReportStore(this);
    this.applicationReportStore = new ApplicationReportStore(this);
    this.pushNotificationsStore = new PushNotificationsStore(this);
    this.lprLogsReportStore = new LprLogsReportStore(this);
    this.LprLogsNotUsersReportStore = new LprLogsNotUsersReportStore(this);
		this.LprLogsReportPerParkStore = new LprLogsReportPerParkStore(this)
    this.lprEfficiencyReportStore = new LprEfficiencyReportStore(this);
    this.lprDashboardStore = new LprDashboardStore(this);
  }

  getViewStores() {
    return {
      companiesViewStore: this.companiesViewStore,
      logsViewStore: this.logsViewStore,
      smsLogsViewStore: this.smsLogsViewStore,
      lprLogsViewStore: this.lprLogsViewStore,
      usersViewStore: this.usersViewStore,
      camerasViewStore: this.camerasViewStore,
    };
  }

  getStores() {
    return {
      ...this.getViewStores(),
      authStore: this.authStore,
      userStore: this.userStore,
      commonStore: this.commonStore,
      companiesStore: this.companiesStore,
      camerasStore: this.camerasStore,
      branchesStore: this.branchesStore,
      usersStore: this.usersStore,
      reservationsStore: this.reservationsStore,
      parksStore: this.parksStore,
      parkingsStore: this.parkingsStore,
      employeesStore: this.employeesStore,
      vacationsStore: this.vacationsStore,
      guestsStore: this.guestsStore,
      constantEmployeeReportStore: this.constantEmployeeReportStore,
      efficiencyReportStore: this.efficiencyReportStore,
      employeesReportStore: this.employeesReportStore,
      guestsReportStore: this.guestsReportStore,
      parkingsReportStore: this.parkingsReportStore,
      arrivalLogsReportStore: this.arrivalLogsReportStore,
      applicationReportStore: this.applicationReportStore,
      lprLogsReportStore: this.lprLogsReportStore,
      lprLogsNotUsersReportStore: this.LprLogsNotUsersReportStore,
      LprLogsReportPerParkStore: this.LprLogsReportPerParkStore,
      lprEfficiencyReportStore: this.lprEfficiencyReportStore,
      pushNotificationsStore: this.pushNotificationsStore,
      bookingsStore: this.bookingsStore,
      specialParksStore: this.specialParksStore,
      lprDashboardStore: this.lprDashboardStore,
    };
  }
}

const rootStore = new Stores();

export default rootStore.getStores();
