import dayjs from 'dayjs';

const defaultGuestObject = {
  _id: undefined,
  name: '',
  company: null,
  phone: '',
  email: '',
  parking: null,
  customId: '',
  carNumbers: [],
  deviceToken: undefined,
  deviceType: undefined,
  isActive: true,
  startDate: null,
  shouldSentSMS: true,
  endDate: null,
  comments: '',
  allowedParks: []
};

export default class Guest {
  constructor(rawGuest = defaultGuestObject) {
    this._id = rawGuest._id;
    this.name = rawGuest.name;
    this.company = rawGuest.company;
    this.password = rawGuest.password;
    this.phone = rawGuest.phone;
    this.email = rawGuest.email;
    this.parking = rawGuest.parking;
    this.isActive = rawGuest.isActive;
    this.carNumbers = rawGuest.carNumbers;
    this.deviceToken = rawGuest.device_token;
    this.deviceType = rawGuest.device_type;
    this.startDate = rawGuest.startDate ? dayjs(rawGuest.startDate).startOf('day').toDate() : rawGuest.startDate;
    this.customId = rawGuest.customId;
    this.endDate = rawGuest.endDate ? dayjs(rawGuest.endDate).endOf('day').toDate() : rawGuest.endDate;
    this.comments = rawGuest.comments;
    this.shouldSentSMS = rawGuest.shouldSentSMS;
    this.allowedParks = rawGuest.allowedParks && Array.isArray(rawGuest.allowedParks) ? rawGuest.allowedParks.map(c => c && c.hasOwnProperty('_id') ? c._id : c) : [];
  }
}