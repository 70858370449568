import { observer } from 'mobx-react';
import React, { Component } from 'react';
import DataTable from '../../../../components/DataTable/DataTable';
import stores from '../../../../stores/index';
import LprLogsFilters from './LprLogsFilters';

const { lprLogsReportStore } = stores;

@observer
class LprLogsReport extends Component {
	componentDidMount() {
		lprLogsReportStore.getReport();
	}

	onExportClick = submittedValues => {
		const params = this.parseParams(submittedValues);
		lprLogsReportStore.exportReport(params);
	};

	parseParams = submittedValues => {
		let params = {};
		if (submittedValues.carNumber) {
			params.carNumber = submittedValues.carNumber;
		}
		return params;
	};

	onFiltersSearch = submittedValues => {
		const params = this.parseParams(submittedValues);
		lprLogsReportStore.getReport(params);
	};

	render() {
		return (
			<div>
				<h1 className="page-title">Lpr Logs Report</h1>
				<div className="actions">
					<LprLogsFilters onSearch={this.onFiltersSearch} onExportClick={this.onExportClick} />
				</div>
				<DataTable
					columns={lprLogsReportStore.tableColumns}
					data={lprLogsReportStore.currentReport.slice()}
				/>
			</div>
		);
	}
}

export default LprLogsReport;
