import { Link } from 'react-router-dom';
import React from 'react';

export default function ParkingEmployeeName({ value }) {
  if (!value) return null;
  if (value._id) {
    return <Link to={{
      pathname: `employees/${value._id}`,
      state: { employeeId: value._id }
    }}>{value.name}</Link>;
  }
  else {
    return <span>{value.name}</span>;
  }
}