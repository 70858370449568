import dayjs from 'dayjs';
import { action, computed, observable } from 'mobx';
import API from '../../utils/API/api';
import { ROUTES } from '../../utils/common';

const minuteInterval = 1000 * 2;

class LprDashboardStore {
	@observable lprLogsList = observable.array();

	@observable monthsList = observable.array();

	@observable startDate = dayjs()
		.startOf('day')
		.toISOString();

	@observable endDate = dayjs().endOf('day').toISOString();

	@observable carNumber;

	@observable allTransactions = false;

	@observable selectedEmployee = {};

	@observable cameras = observable.array();

	@observable selectedLog;

	@observable todayCounters;

	constructor(stores) {
		this.commonStore = stores.commonStore;
		this.api = API.company.lprDashboard;
		this.parksStore = stores.parksStore;

		this.commonStore.onLocationChanged(location => {
			if (location.path === ROUTES.LPR_DASHBOARD) {
				this.getLprLogs();
				this.getCamerasList();
			}
		});
	}

	@action
	search(values) {
		// this.startDate = values.startDate;
		// this.endDate = values.endDate;
		this.carNumber = values.carNumber;
		this.selectedEmployee = values.employee || {};
		this.allTransactions = values.allTransactions || false;
		this.getLprLogs(this.startDate, this.endDate, this.carNumber, this.selectedEmployee._id, this.allTransactions);
	}

	@action
	getCamerasList() {
		return this.api.getCameras().then(
			action(response => {
				this.cameras.replace(response.results);
			}),
		);
	}

	@action
	getLprLogs(startDate, endDate, carNumber, employeeId, showAllTransactions = false) {
		this.commonStore.setIsLoading(true);
		const from = startDate
			? dayjs(startDate)
					.startOf('day')
					.toISOString()
			: undefined;
		const to = endDate
			? dayjs(endDate)
					.endOf('day')
					.toISOString()
			: undefined;
		return this.api
			.getLprDashboardReport(from, to, carNumber, employeeId, showAllTransactions)
			.then(
				action(response => {
					this.lprLogsList.replace(response.results);
					this.selectedLog = this.lprLogsList.length > 0 ? this.lprLogsList[0] : undefined;
				}),
			)
			.finally(() => {
				this.commonStore.setIsLoading(false);
			});
	}

	@action
	getLastLprLogs() {
		const from = this.startDate
			? dayjs(this.startDate)
					.startOf('day')
					.toISOString()
			: undefined;
		const to = this.endDate
			? dayjs(this.endDate)
					.endOf('day')
					.toISOString()
			: undefined;
		const employeeId = this.selectedEmployee._id;
		return this.api.getLprDashboardReport(from, to, this.carNumber, employeeId, this.allTransactions).then(
			action(response => {
				if (response.results && response.results.length > 0) {
					this.getCountersPerPark();
					let flagOnFirstRow = false;
					if (
						this.selectedLog &&
						this.lprLogsList &&
						this.lprLogsList.length > 0 &&
						this.selectedLog._id === this.lprLogsList[0]._id
					) {
						flagOnFirstRow = true;
					}

					this.lprLogsList.replace(response.results);

					if (flagOnFirstRow) {
						this.selectedLog = this.lprLogsList[0];
					}
				}
			}),
		);
	}

	@action
	onLogSelected(lprLog) {
		this.selectedLog = lprLog;
	}

	@action
	openGate(camera) {
		const cameraId = camera._id;
		if (cameraId) {
			return this.api.openGate(cameraId);
		}
		return Promise.reject('error open gate');
	}

	@action
	async getCounters() {
		try {
			const today = new Date().toISOString();
			this.todayCounters = await this.api.getCounters(today);
		} catch (err) {
			console.error('error in getting counters', err);
		}
	}

	@action
	async getCountersPerPark() {
		try {
			const today = new Date().toISOString();
			this.todayCounters = await this.api.getCountersPerPark(today);
			// console.log("this.todayCounters",this.todayCounters);
		} catch (err) {
			console.error('error in getting counters', err);
		}
	}

	@computed
	get counters() {
		if (this.todayCounters && this.todayCounters.length > 1)
		{
			return this.todayCounters.map((countersPerCompany)=>{
				return {
					company:countersPerCompany.company,
					counters:countersPerCompany.counters.map((counter, index) => {
						return {
							id: index,
							name: counter.name,
							current: counter.count,
							max: counter.maxLprParking
						}
					})
				}
			})
		}
		else
		{
			const counters = (this.todayCounters || {}).counters || []
			if (counters) {
				return counters.map((counter, index) => {
					return {
						id: index,
						name: counter.name,
						current: counter.count,
						max: counter.maxLprParking
					}
				});
			}
			return {
				id: 0,
				name: "",
				current: 0,
				max: 0
			}
		}
	}
}

export default LprDashboardStore;
