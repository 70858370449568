import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Company, {
	LPR_AVAILABLE_LANGUAGES,
	LPR_AVAILABLE_LANGUAGES_LABELS,
} from '../../../../models/Company';
import { Field, Form } from 'react-final-form';
import stores from '../../../../stores';
import BootstrapInput from '../../../../components/BootstrapInput/BootstrapInput';
import Error from '../../../../components/forms/Error';
import Select from 'react-select';
import { generateOption } from '../../../../utils/common';

const { companiesStore } = stores;

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);
class EditCompanyModal extends Component {
  constructor(props) {
    super(props);
    // this.state = {selectedCompanies: []}
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     selectedCompanies: nextProps.company && nextProps.company.managedCompanies || [],
  //   };
  // }

  onCompanySelected = (option) => {
    //this.setState({selectedCompanies: option});

    this.props.company.managedCompanies = option;
  }

  onSubmitForm = (submittedValues) => {
    const company = new Company(submittedValues);
    const isEditMode = this.props.company && this.props.company._id;

    if(!submittedValues.isWithWorkstation){
      submittedValues.isWithWorkstationMultiple = submittedValues.isWithWorkstation
    }

    if ( company.isManagementCompany)
      company.managedCompanies = this.props.company.managedCompanies;
    else company.managedCompanies = [];
    if (!isEditMode) {
      companiesStore
      .createCompany(company)
      .then((response) => {
        this.props.closeModal();
      })
      .catch((err) => {
          console.error(err);
        });
      } else {
      // Handle a check if the user changed the number of the elements, only add
      // the user cant lower the number only add to it.
      // initialize array with empty strings
      let beforeUpdateNumOfFacilities = this.props.company.numOfFacilities;
      let inputNewNumOfFacilities = company.numOfFacilities;

      if (company.isWithReservations) {
        if (!this.props.company.numOfFacilities) {
          for (let i = 0; i < inputNewNumOfFacilities; i++) {
            company.facilities.push({ id: i, facilityName: '' });
          }
        } else {
          if (inputNewNumOfFacilities > beforeUpdateNumOfFacilities) {
            let numOfInputToAdd = inputNewNumOfFacilities - beforeUpdateNumOfFacilities;
            for (let i = 0; i < numOfInputToAdd; i++) {
              company.facilities.push({ id: beforeUpdateNumOfFacilities + i, facilityName: '' });
            }
          }
        }
      }
      companiesStore
      .updateCompany(company)
      .then((response) => {
        this.props.closeModal();
      })
        .catch((err) => {
          console.error(err);
        });
      }
    };

  validateForm = (values) => {
    let errors = {};
    if (!values.name) {
      errors.name = 'Company name is required';
    }
    if (values.isWithReservations && !values.numOfFacilities) {
      errors.numOfFacilities = 'Number of facilities is required';
    }

    if (values.isWithReservations && values.numOfFacilities) {
      if (this.props.company.numOfFacilities && values.numOfFacilities < this.props.company.numOfFacilities) {
        errors.numOfFacilities = 'You can only add to the number of facilities';
      }
    }
    return errors;
  };

  renderEditForm = (companiesList) => {
  	const availableLanguages = [
  		generateOption(LPR_AVAILABLE_LANGUAGES_LABELS.en, LPR_AVAILABLE_LANGUAGES.ENGLISH),
  		generateOption(LPR_AVAILABLE_LANGUAGES_LABELS.he, LPR_AVAILABLE_LANGUAGES.HEBREW),
		]
    return (
      <>
        <div className='row'>
          <div className='col'>
            <label htmlFor='geofenceNotification'>With Release GPS Notification?</label>
          </div>
          <div className='col'>
            <Field
              name='isWithGeofenceNotification'
              component={BootstrapInput}
              type='checkbox'
              id='geofenceNotification'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='holdGeoNotification'>With Hold GPS Notification?</label>
          </div>
          <div className='col'>
            <Field
              name='isWithHoldGeofenceNotification'
              component={BootstrapInput}
              type='checkbox'
              id='holdGeoNotification'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='autoLpr'>With LPR Camerars?</label>
          </div>
          <div className='col'>
            <Field name='isWithAutomaticLPR' component={BootstrapInput} type='checkbox' id='autoLpr' />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='smsLpr'>With SMS LPR?</label>
          </div>
          <div className='col'>
            <Field name='isWithSMSLPR' component={BootstrapInput} type='checkbox' id='smsLpr' />
          </div>
        </div>
				<div className='row'>
          <div className='col'>
            <label htmlFor='lprMessageLanguage'>LPR SMS Language</label>
          </div>
          <div className='col'>
						<Field name="lprMessageLanguage">
							{({ input, meta }) => {
								return (
									<Select
										className="basic-single"
										classNamePrefix="select"
										id="lprMessageLanguage"
										{...input}
										value={availableLanguages.find(
											({ value }) => value === input.value,
										)}
										onChange={({ value }) => input.onChange(value)}
										options={availableLanguages.slice()}
									/>
								);
							}}
						</Field>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='pushLpr'>With Push Notifications LPR?</label>
          </div>
          <div className='col'>
            <Field name='isWithNotificationLPR' component={BootstrapInput} type='checkbox' id='pushLpr' />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='arrivalSms'>With Sending SMS For Arrival to work?</label>
          </div>
          <div className='col'>
            <Field name='isWithSendingSMSForArrival' component={BootstrapInput} type='checkbox' id='arrivalSms' />
          </div>
        </div>
				<div className='row'>
					<div className='col'>
						<label htmlFor='isWithPushNotificationsArrival'>With Sending Push For Arrival to work?</label>
					</div>
					<div className='col'>
						<Field name='isWithPushNotificationsArrival' component={BootstrapInput} type='checkbox' id='isWithPushNotificationsArrival' />
					</div>
				</div>
				<div className='row'>
					<div className='col'>
						<label htmlFor='arrivalSms'>With Sending SMS For Arrival to work by Days?</label>
					</div>
					<div className='col'>
						<Field name='isWithSendingSMSForArrivalByDays' component={BootstrapInput} type='checkbox' id='isWithSendingSMSForArrivalByDays' />
					</div>
				</div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='checkSmsResponse'>With Check Response Of Arrival SMS to work?</label>
          </div>
          <div className='col'>
            <Field
              name='isWithCheckResponseOfSMSForArrival'
              component={BootstrapInput}
              type='checkbox'
              id='checkSmsResponse'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='withVacation'>With Application absence update</label>
          </div>
          <div className='col'>
            <Field name='isWithVacationInApp' component={BootstrapInput} type='checkbox' id='withVacation' />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='withPartnerParking'>With Parking Partner</label>
          </div>
          <div className='col'>
            <Field name='isWithPartnerParking' component={BootstrapInput} type='checkbox' id='withPartnerParking' />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='withReporting'>With Reporting</label>
          </div>
          <div className='col'>
            <Field name='isWithReporting' component={BootstrapInput} type='checkbox' id='withReporting' />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='withReservations'>With Reservations</label>
          </div>
          <div className='col'>
            <Field name='isWithReservations' component={BootstrapInput} type='checkbox' id='withReservations' />
          </div>
        </div>
        <Condition when='isWithReservations' is={true}>
          <div className='row'>
            <div className='col'>
              <label>Number Of Facilities: </label>
            </div>
            <div className='col'>
              <Field name='numOfFacilities' component={BootstrapInput} type='number' />
              <Error name='numOfFacilities' />
            </div>
          </div>
        </Condition>
        <div className='row'>
          <div className='col'>
            <label htmlFor='withManualPush'>With Admin Push Notifications</label>
          </div>
          <div className='col'>
            <Field name='isWithManualPush' component={BootstrapInput} type='checkbox' id='withManualPush' />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='withOpenGate'>With Open Gate</label>
          </div>
          <div className='col'>
            <Field name='isWithOpenGate' component={BootstrapInput} type='checkbox' id='withOpenGate' />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='withAutoResetPassword'>With Change password</label>
          </div>
          <div className='col'>
            <Field
              name='isWithAutoResetPassword'
              component={BootstrapInput}
              type='checkbox'
              id='withAutoResetPassword'
            />
          </div>
        </div>
				<div className='row'>
          <div className='col'>
            <label htmlFor='withHoldingOccupiedParking'>With hold occupied parking</label>
          </div>
          <div className='col'>
            <Field
              name='isWithHoldingOccupiedParking'
              component={BootstrapInput}
              type='checkbox'
              id='withHoldingOccupiedParking'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='isSpecial'>Is Special Company</label>
          </div>
          <div className='col'>
            <Field name='isSpecial' component={BootstrapInput} type='checkbox' id='isSpecial' />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <label htmlFor='withTripleBlocking'>With Triple Blocking</label>
          </div>
          <div className='col'>
            <Field name='isWithTripleBlocking' component={BootstrapInput} type='checkbox' id='withTripleBlocking' />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
              <label htmlFor='ManagementCompany'>Is Management Company ?</label>
          </div>
          <div className='col'>
              <Field name='isManagementCompany' component={BootstrapInput} type='checkbox' id='ManagementCompany' />
          </div>
        </div>
        <Condition when='isManagementCompany' is={true}>
					<div className='row'>
						<div className='col'>
							<label htmlFor='managedCompanies'>Managed Companies: </label>
						</div>
						<div className='col'>
              <Field name="managedCompanies">
						  	{({ input, meta }) => {
						  		return (
						  			<Select
						  				className="basic-single"
						  				classNamePrefix="select"
                      id="managedCompanies"
                      isMulti
                      defaultValue={this.props.company.managedCompanies}
						  				onChange={this.onCompanySelected}
						  				options={companiesList}
						  			/>
						  		);
						  	}}
						</Field>
						</div>
					</div>
        </Condition>
        <div className='row'>
          <div className='col'>
            <label htmlFor='withVacation'>Notes:</label>
          </div>
          <div className='col'>
            <Field name='notes' component='textarea' className='form-control' />
          </div>
        </div>
      </>
    );
  };

  render() {
    const companiesList = companiesStore.companiesList.map(c => ({ value: c._id, label: c.name }));
    const isEditMode = this.props.company && this.props.company._id;
    const defaultValues = new Company(this.props.company);
    return (
      <Form
        onSubmit={this.onSubmitForm}
        initialValues={defaultValues}
        validate={this.validateForm}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} id='form1'>
              <div className='row'>
                <div className='col'>Company Name*:</div>
                <div className='col'>
                  <Field name='name' component={BootstrapInput} />
                  <Error name='name' />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label htmlFor='isActive'>Active</label>
                </div>
                <div className='col'>
                  <Field name='isActive' component={BootstrapInput} type='checkbox' id='isActive' />
                </div>
              </div>
              <div className='row'>
                <div className='col'>Max users:</div>
                <div className='col'>
                  {/*<NumberInput field="maxUsers" />*/}
                  <Field name='maxUsers' component={BootstrapInput} type='number' />
                </div>
              </div>
              <div className='row'>
                <div className='col'>Max parkings:</div>
                <div className='col'>
                  {/*<NumberInput field="maxParkings" />*/}
                  <Field name='maxParkings' component={BootstrapInput} type='number' />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label htmlFor='withGps'>GPS ?</label>
                </div>
                <div className='col'>
                  {/*<Checkbox field="isWithGPS" id="withGps" />*/}
                  <Field name='isWithGPS' component={BootstrapInput} type='checkbox' id='withGps' />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label htmlFor='virtualParking'>Virtual Parking ?</label>
                </div>
                <div className='col'>
                  {/*<Checkbox field="isWithVirtualParking" id="virtualParking" />*/}
                  <Field name='isWithVirtualParking' component={BootstrapInput} type='checkbox' id='virtualParking' />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label htmlFor='booking'>Booking ?</label>
                </div>
                <div className='col'>
                  <Field name='isWithBooking' component={BootstrapInput} type='checkbox' id='booking' />
                </div>
              </div>

              {/* is With Working Station */}
							<div className='row'>
                <div className='col'>
                  <label htmlFor='workstation'>Is Using Workstation ? </label>
                </div>
                <div className='col'>
                  <Field name='isWithWorkstation' component={BootstrapInput} type='checkbox' id='workstation' />
                </div>
              </div>
              {/* is With workstation  Multiple  */}
              <Condition when='isWithWorkstation' is={true}>
                <div className='row'>
                  <div className='col'>
                    <label htmlFor='isWithWorkstationMultiple'>Is Multiple constant employee ? </label>
                  </div>
                  <div className='col'>
                    <Field name='isWithWorkstationMultiple' component={BootstrapInput} type='checkbox' />
                    <Error name='isWithWorkstationMultiple' />
                  </div>

                </div>
              </Condition>


              <div className='row'>
                <div className='col'>
                  <label htmlFor='autoRelease'>Auto Release Geofence:</label>
                </div>
                <div className='col'>
                  <Field name='isAutoReleaseGeofence' component={BootstrapInput} type='checkbox' id='autoRelease' />
                </div>
              </div>
							<div className='row'>
                <div className='col'>
                  <label htmlFor='usingDpmLpr'>Is using DPM LPR ?</label>
                </div>
                <div className='col'>
                  <Field name='isUsingDpmLpr' component={BootstrapInput} type='checkbox' id='usingDpmLpr' />
                </div>
              </div>
							<Condition when='isUsingDpmLpr' is={true}>
								<div className='row'>
									<div className='col'>
										<label>Max LPR parkings: </label>
									</div>
									<div className='col'>
										<Field name='maxLprParking' component={BootstrapInput} type='number' />
										<Error name='maxLprParking' />
									</div>
								</div>
								<div className='row'>
									<div className='col'>
										<label htmlFor='isWithOpenGateDpmLpr'>Can open gate manually?: </label>
									</div>
									<div className='col'>
										<Field name='isWithOpenGateDpmLpr' component={BootstrapInput} type='checkbox' id="isWithOpenGateDpmLpr" />
										<Error name='isWithOpenGateDpmLpr' />
									</div>
								</div>
							</Condition>
							<div className='row'>
                <div className='col'>
                  <label htmlFor='lightMode'>Is Using LPR Lightmode ?</label>
                </div>
                <div className='col'>
                  <Field name='isUsingLightmode' component={BootstrapInput} type='checkbox' id='lightMode' />
                </div>
              </div>
              {isEditMode && this.renderEditForm(companiesList)}
              <button type='submit' disabled={submitting}>
                Save
              </button>
            </form>
          );
        }}
      />
    );
  }
}

EditCompanyModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  company: PropTypes.object
};

export default EditCompanyModal;
