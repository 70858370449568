import React, { Component } from 'react';
import queryString from 'query-string'
import { Link, withRouter } from 'react-router-dom';
import stores from '../../stores';
import { DPMlogo } from '../../assets';
import { ROUTES } from '../../utils/common';
import API from '../../utils/API/api';
import { toast } from 'react-toastify';

@withRouter
class ResetPassword extends Component {
  state = {
    newPassword: '',
    confirmNewPassword: '',
    isTokenValid: true
  };


  componentWillMount() {
    document.body.classList.add("loginBg");
  }

  componentWillUnmount() {
    document.body.classList.remove("loginBg");
  }

  componentDidMount() {
    // api request to server to check if the reset token is valid
    const queryParams = queryString.parse(this.props.location.search);
    const { token } = queryParams;
    API.auth.isResetTokenValid(token).then(response => {
      if (response.result !== 1) {
        this.setState({ isTokenValid: false });
      }
    });
  }

  handleNewPasswordChange = e => this.setState({ newPassword: e.target.value });
  handleConfirmNewPasswordChange = e => this.setState({ confirmNewPassword: e.target.value });

  handleSubmitForm = (e) => {
    e.preventDefault();
    const queryParams = queryString.parse(this.props.location.search);
    const { token } = queryParams;
    const { newPassword, confirmNewPassword } = this.state;
    if (newPassword === confirmNewPassword) {
      stores.commonStore.setIsLoading(true);
      stores.authStore.resetPasswordViaEmail(token, this.state.newPassword)
        .then((user) => {
          stores.commonStore.setIsLoading(false);
          toast("Password has changed! you can now login with your new password", {
            type: toast.TYPE.SUCCESS,
            position: "top-center",
            autoClose: 5000
          });
          this.props.history.replace(ROUTES.LOGIN);
        })
        .catch(err => {
          if (err && err.data && err.data.message) {
            toast(err.data.message, { type: toast.TYPE.ERROR });
          }
          console.error(err);
          stores.commonStore.setIsLoading(false);
        })
    }
    else {
      alert("New Password and Confirm password should be the same");
    }
  };

  renderError = () => (
    <>
      <p>Your token is expires, please return to login and click "forgot password"</p>
      <Link to={ROUTES.LOGIN}>Return to Login</Link>
    </>
  );

  renderForm = (newPassword, confirmNewPassword) => {
    return (
      <>
        <div className="row center-xs middle-xs">
          <label>Enter new password:</label>
          <input autoFocus="autofocus" className="textbox" type="password"
                 name="txtNewPassword" autoComplete="off"
                 value={newPassword}
                 onChange={this.handleNewPasswordChange} />
        </div>

        <div className="row center-xs middle-xs">
          <label>Confirm new password:</label>
          <input className="textbox" type="password" name="txtConfirmNewPassword" value={confirmNewPassword}
                 onChange={this.handleConfirmNewPasswordChange}
                 autoComplete="off" />
        </div>
        <div>
          <button type="submit">
            Reset Password
          </button>
        </div>
      </>
    )
  };

  render() {
    const { newPassword, confirmNewPassword, isTokenValid } = this.state;
    const errorMessage = this.renderError();
    const form = this.renderForm(newPassword, confirmNewPassword);
    return (
      <div className="auth-page">
        <div className="dpm-logo-container">
          <img src={DPMlogo} alt="DPM Parkings" />
        </div>
        <div className="row center-xs middle-xs">
          <div className="col-sm-6">
            <form className="login-box" onSubmit={this.handleSubmitForm}>
              <div className="login-logo">
                <h1>Reset Your Password</h1>
              </div>
              {!isTokenValid ? errorMessage : form}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
