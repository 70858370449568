import CrudRoute from '../crudRoute';
import queryString from 'query-string';

class ParkingsApi extends CrudRoute {
	constructor(apiInstance) {
		super('api/parkings', apiInstance);
	}

	getSpecialParkings() {
		let url = this.baseRoute + `/getSpecialParkinglots`;
		return this.apiInstance.get(url);
	}

	getParkinglotJsonNames() {
		let url = this.baseRoute + `/getParkinglotJsonNames`;
		return this.apiInstance.get(url);
	}

	getParkinglotCompanyNames() {
		let url = this.baseRoute + `/getParkinglotCompaniesJsonNames`;
		return this.apiInstance.get(url);
	}

	getParking(parkingId) {
		const url = `${this.baseRoute}/${parkingId}`;
		return this.apiInstance.get(url);
	}

	getExternalParkings() {
		return this.apiInstance.get(`${this.baseRoute}/externalParkings/list`);
	}

	setIsPartner(parkingId, bool) {
		return this.apiInstance.get(`${this.baseRoute}/setIsPartner/${parkingId}/${bool}`);
	}

	setPartnerOf(partnerParking, currentParking) {
		return this.apiInstance.get(
			`${this.baseRoute}/setPartnerOf/${partnerParking}/${currentParking}`,
		);
	}

	getPartnerExternalParkings() {
		return this.apiInstance.get(`${this.baseRoute}/partnerExternalParkings/list`);
	}

	releaseParking(parkingId) {
		return this.apiInstance.post(`${this.baseRoute}/release/${parkingId}`);
	}

	holdParking(guestName, guestPhone, guestCarNumber, userId, parkingId) {
		const url = `${this.baseRoute}/hold/${parkingId}`;
		const data = {
			guest: {
				name: guestName,
				phone: guestPhone,
				carNumbers: guestCarNumber ? [guestCarNumber] : undefined,
				employeeId: userId,
			},
		};
		return this.apiInstance.post(url, data);
	}

	employeeNameAutoComplete(query, type) {
		let url = `api/employees/autoComplete?q=${encodeURIComponent(query)}`;
		if (type) {
			url += `&type=${type}`;
		}
		return this.apiInstance.get(url);
	}

	carNumberAutoComplete(query, type) {
		let url = `api/employees/autoComplete?carNumber=${encodeURIComponent(query)}`;
		if (type) {
			url += `&type=${type}`;
		}
		return this.apiInstance.get(url);
	}

	getTodayResponses(userID, employeeId) {
		const url = `phpQueries/employees/getResponse.php?userID=${userID}&employeeId=${employeeId}`;
		return this.apiInstance.get(url);
	}

	getEmployeeByCarNumber(userID, carNumber) {
		const url = `phpQueries/employees/getEmployeeByCarNumber.php?userID=${userID}&carNumber=${carNumber}`;
		return this.apiInstance.get(url);
	}
}

class BranchesApi extends CrudRoute {
	constructor(apiInstance) {
		super(`api/branches`, apiInstance);
	}
}

class ParksApi extends CrudRoute {
	constructor(apiInstance) {
		super(`api/parks`, apiInstance);
	}
}

class EmployeesApi extends CrudRoute {
	constructor(apiInstance) {
		super(`api/employees`, apiInstance);
	}

	changePassword(userId, newPassword) {
		const url = `${this.baseRoute}/${userId}/changePassword`;
		return this.apiInstance.put(url, { newPassword });
	}

	importExcelFile(file) {
		const url = `${this.baseRoute}/import`;
		const formData = new FormData();
		formData.append('file', file);
		return this.apiInstance.post(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}

	exportExcel() {
		const url = `${this.baseRoute}/export`;
		return window.open(`${this.apiInstance.defaults.baseURL}/${url}`, '_blank');
	}
}

class GuestsApi extends CrudRoute {
	constructor(apiInstance) {
		super(`api/guests`, apiInstance);
	}

	changePassword(userId, newPassword) {
		const url = `${this.baseRoute}/${userId}/changePassword`;
		return this.apiInstance.put(url, { newPassword });
	}

	importExcelFile(file) {
		const url = `${this.baseRoute}/import`;
		const formData = new FormData();
		formData.append('file', file);
		return this.apiInstance.post(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}
}

class VacationsApi extends CrudRoute {
	constructor(apiInstance) {
		super(`api/vacations`, apiInstance);
	}
}

class DetailsApi {
	constructor(apiInstance) {
		this.baseRoute = 'api/details';
		this.apiInstance = apiInstance;
	}

	updateUserDetails(userDetails) {
		return this.apiInstance.put(`${this.baseRoute}/updateUserDetails`, { user: userDetails });
	}

	updateCompanyDetails(userDetails) {
		return this.apiInstance.put(`${this.baseRoute}/updateCompanyDetails`, { company: userDetails });
	}
}

class ReportsApi {
	constructor(apiInstance) {
		this.baseRoute = `/api/reports`;
		this.apiInstance = apiInstance;
	}

	reportUrl(reportName, params = {}) {
		const querystring = queryString.stringify(params);
		return `${this.baseRoute}/${reportName}?${querystring}`;
	}

	exportExcelUrl(reportName, params = {}) {
		const querystring = queryString.stringify({
			startDate: params.startDate,
			endDate: params.endDate
		});
		return `${this.baseRoute}/${reportName}/export?${querystring}`;
	}

	getGuestsReport(params = {}) {
		const url = `${this.reportUrl('guests', params)}`;
		return this.apiInstance.get(url);
	}

	getEmployeesReport(params = {}) {
		const url = `${this.reportUrl('employees', params)}`;
		return this.apiInstance.get(url);
	}

	getParkingsReport(params = {}) {
		const url = `${this.reportUrl('parkings', params)}&parkingId=${params.parking}`;
		return this.apiInstance.get(url);
	}

	getParkingsEfficiencyReport(params = {}) {
		const url = `${this.reportUrl('efficiency', params)}`;
		return this.apiInstance.get(url);
	}

	getLprEfficiencyReport(params = {}) {
		const url = `${this.reportUrl('lprefficiency', params)}`;
		return this.apiInstance.get(url);
	}

	getConstantEmployeeReport(params = {}) {
		const url = `${this.reportUrl('constantEmployee', params)}`;
		return this.apiInstance.get(url);
	}

	getArrivalLogsReport(params = {}) {
		const url = `${this.reportUrl('arrivalLogs', params)}`;
		return this.apiInstance.get(url);
	}

	getApplicationReport(params = {}) {
		const url = `${this.reportUrl('application', params)}`;
		return this.apiInstance.get(url);
	}

	getLprLogsReport(params = {}) {
		const url = this.reportUrl('lprLogs', params);
		return this.apiInstance.get(url);
	}

	getLprLogsNotUsersReport(params = {}) {
		const url = this.reportUrl('lprLogsNotUsers', params);
		return this.apiInstance.get(url);
	}

	getLprLogsReportPerPark(params = {}) {
		const url = this.reportUrl('lprLogsPerPark', params);
		return this.apiInstance.get(url);
	}

	exportGuestsReport(params = {}) {
		const url = `${this.exportExcelUrl('guests', params)}&guestId=${params.guestId}`;
		return window.open(`${this.apiInstance.defaults.baseURL}${url}`, '_blank');
	}

	exportEmployeesReport(params = {}) {
		const url = `${this.exportExcelUrl('employees', params)}&employeeId=${params.employeeId}`;
		return window.open(`${this.apiInstance.defaults.baseURL}${url}`, '_blank');
	}

	exportParkingsReport(params = {}) {
		const url = `${this.exportExcelUrl('parkings', params)}&parkingId=${params.parkingId}`;
		return window.open(`${this.apiInstance.defaults.baseURL}${url}`, '_blank');
	}

	exportParkingsEfficiency(params = {}) {
		const url = `${this.exportExcelUrl('efficiency', params)}&parkingLotId=${params.parkingLotId}`;
		return window.open(`${this.apiInstance.defaults.baseURL}${url}`, '_blank');
	}

	exportConstantEmployeeReport(params = {}) {
		const url = `${this.exportExcelUrl('constantEmployee', params)}&employeeId=${
			params.employeeId
		}`;
		return window.open(`${this.apiInstance.defaults.baseURL}${url}`, '_blank');
	}

	exportArrivalLogsReport(params = {}) {
		const url = `${this.exportExcelUrl('arrivalLogs', params)}&employeeId=${params.employeeId}`;
		return window.open(`${this.apiInstance.defaults.baseURL}${url}`, '_blank');
	}

	exportApplicationReport(params = {}) {
		const url = `${this.exportExcelUrl('application', params)}&reportType=${params.reportType}`;
		return window.open(`${this.apiInstance.defaults.baseURL}${url}`, '_blank');
	}

	exportLprLogsReport(params = {}) {
		const url = `${this.exportExcelUrl('lprLogs', params)}&carNumber=${params.carNumber}`;
		return window.open(`${this.apiInstance.defaults.baseURL}${url}`, '_blank');
	}

	exportLprLogsNotUsersReport(params = {}) {
		const url = `${this.exportExcelUrl('lprLogsNotUsers', params)}&carNumber=${params.carNumber}`;
		return window.open(`${this.apiInstance.defaults.baseURL}${url}`, '_blank');
	}

	exportLprLogsReportPerPark(params = {}) {
		const url = `${this.exportExcelUrl('lprLogsPerPark', params)}&carNumber=${params.carNumber}`;
		return window.open(`${this.apiInstance.defaults.baseURL}${url}`, '_blank');
	}
}

class ReservationsApi extends CrudRoute {
	constructor(apiInstance) {
		super(`/api/reservations`, apiInstance);
	}
	getCalendarEventsOfThisWeek(firstDay, lastDay, companyId) {
		let ISOfirstDay = firstDay.toISOString();
		let ISOlastDay = lastDay.toISOString();
		return this.apiInstance.get(
			`${this.baseRoute}?firstDay=${ISOfirstDay}&lastDay=${ISOlastDay}&companyId=${companyId}`,
		);
	}
}

class PushNotificationsApi {
	constructor(apiInstance) {
		this.apiInstance = apiInstance;
	}

	sendPush(title, message, screen) {
		let url = `api/notifications/send`;
		return this.apiInstance.post(url, { title, message, screen });
	}
}

class BookingsApi {
	constructor(apiInstance) {
		this.apiInstance = apiInstance;
	}

	getFutureBookings() {
		let url = `api/bookings`;
		return this.apiInstance.get(url);
	}

	deleteBooking(bookingId) {
		return this.apiInstance.delete(`api/bookings/${bookingId}`);
	}
}

class LprDashboardApi {
	constructor(apiInstance) {
		this.apiInstance = apiInstance;
	}

	getLprDashboardReport(fromDate, toDate, carNumber, employee, showAllTransactions) {
		let url = `/api/lprDashboard/logs?`;

		const params = {
			from: fromDate,
			to: toDate,
			carNumber,
			employee,
			showAllTransactions
		};

		const querystring = queryString.stringify(params);
		const finalURL = url + querystring;
		return this.apiInstance.get(finalURL);
	}

	getCameras() {
		let url = `/api/lprDashboard/cameras`;
		return this.apiInstance.get(url);
	}

	openGate(cameraId) {
		let url = `/api/lprDashboard/${cameraId}/open`;
		return this.apiInstance.post(url);
	}

	getCounters(date) {
		let url = `/api/lprDashboard/counters?`

		if (date) {
			url += `date=${date}`;
		}
		return this.apiInstance.get(url);
	}
	getCountersPerPark(date) {
		let url = `/api/lprDashboard/countersPerPark?`

		if (date) {
			url += `date=${date}`;
		}
		return this.apiInstance.get(url);

	}

	entryGuid(carNumber , guid){
		let url =`/lpr/lpr?carNumber=${carNumber}&guid=${guid}`
		return this.apiInstance.get(url);
	}
}

class CompanyApi {
	constructor(apiInstance) {
		this.branches = new BranchesApi(apiInstance);
		this.parkings = new ParkingsApi(apiInstance);
		this.parks = new ParksApi(apiInstance);
		this.employees = new EmployeesApi(apiInstance);
		this.vacations = new VacationsApi(apiInstance);
		this.reservations = new ReservationsApi(apiInstance);
		this.guests = new GuestsApi(apiInstance);
		this.details = new DetailsApi(apiInstance);
		this.reports = new ReportsApi(apiInstance);
		this.notifications = new PushNotificationsApi(apiInstance);
		this.bookings = new BookingsApi(apiInstance);
		this.bookings = new BookingsApi(apiInstance);
		this.lprDashboard = new LprDashboardApi(apiInstance);
	}
}

export default CompanyApi;
