import React, { Component } from 'react';
import stores from '../../../../stores/index';
import { observer } from 'mobx-react';
import DataTable from '../../../../components/DataTable/DataTable';
import EfficiencyFilters from './EfficiencyFilters';

const { efficiencyReportStore } = stores;

@observer
class EfficiencyReport extends Component {
  componentDidMount() {
    efficiencyReportStore.getReport();
  }

  onExportClick = (submittedValues) => {
    const params = this.parseParams(submittedValues);
    efficiencyReportStore.exportReport(params);
  };

  parseParams = (submittedValues) => {
    let params = {};
    if (submittedValues.parkingLotId) {
      params.parkingLotId = submittedValues.parkingLotId;
    }
    if (submittedValues.startDate) {
      params.startDate = submittedValues.startDate.toISOString();
    }
    if (submittedValues.endDate) {
      params.endDate = submittedValues.endDate.toISOString();
    }
    return params;
  };

  onFiltersSearch = (submittedValues) => {
    const params = this.parseParams(submittedValues);

    efficiencyReportStore.getReport(params);
  };

  render() {
    return (
      <div>
        <h1 className="page-title">Efficiency Report</h1>
        <div className="actions">
          <EfficiencyFilters onSearch={this.onFiltersSearch} onExportClick={this.onExportClick} />
        </div>
        <DataTable
          columns={efficiencyReportStore.tableColumns} data={efficiencyReportStore.currentReport.slice()}
          defaultSorted={[
            {
              id: "date",
              desc: true
            }
          ]} />
      </div>
    );
  }
}

export default EfficiencyReport;