const editIcon = require('./images/edit.png');
const deleteIcon = require('./images/delete.png');
const camerasIcon = require('./images/cameras.png');
const changePasswordIcon = require('./images/change_password.png');
const notActiveIcon = require('./images/not-active.png');
const activeIcon = require('./images/active.png');
const mapIcon = require('./images/map.png');
const logo = require('./images/logo-dpm.png');
const DPMlogo = require('./images/DPM_logo.png');
const openGate = require('./images/open-gate.png');
const search = require('./images/search.png');

export {
  editIcon,
  deleteIcon,
  camerasIcon,
  changePasswordIcon,
  notActiveIcon,
  activeIcon,
  mapIcon,
  logo,
  DPMlogo,
  openGate,
  search
}
