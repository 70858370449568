import { action, observable } from 'mobx';
import prettyMs from 'pretty-ms';
import React from 'react';
import API from '../../../utils/API/api';
import ReportsStore from '../reportsStore';
import ReportStartDateCell from '../../../components/Reports/ReportStartDateCell';
import ReportEndDateCell from '../../../components/Reports/ReportEndDateCell';

const tableColumns = [
  {
    Header: 'Used By',
    accessor: 'username',
    Footer: (columnProps) => {
      return (
        <span>
          <strong>Total: </strong>{columnProps.data.length}
        </span>
      )
    }
  }, {
    Header: 'Parking Name',
    accessor: 'parking'
  }, {
    Header: 'Start Date',
    accessor: 'start',
    Cell: ReportStartDateCell
  }, {
    Header: 'End Date',
    accessor: 'end',
    Cell: ReportEndDateCell
  }, {
    Header: 'Duration',
    accessor: 'duration',
    Cell: ({ value }) => Number.isFinite(value) && prettyMs(value)
  }
];

class ParkingsReportStore extends ReportsStore {
  @observable selectedParking;

  constructor(stores) {
    super(stores, tableColumns, API.company.reports.getParkingsReport, API.company.reports.exportParkingsReport)
  }

  @action
  setSelectedParking = (parking) => {
    this.selectedParking = parking;
  };
}

export default ParkingsReportStore;