import { action, computed, observable } from 'mobx';

class CompaniesViewStore {
  @observable _selectedCompany;
  @observable isEditCompanyModalShown;

  @computed
  get selectedCompany() {
    return this._selectedCompany;
  }

  @computed
  get isEditModalShown() {
    return this.isEditCompanyModalShown;
  };

  @action
  editCompany = (company) => {
    this._selectedCompany = company;
    this.showEditCompanyModal();
  };

  @action
  newCompany = () => {
    this._selectedCompany = null;
    this.showEditCompanyModal();
  };

  @action
  showEditCompanyModal = () => {
    this.isEditCompanyModalShown = true;
  };

  @action
  hideEditCompanyModal = () => {
    this.isEditCompanyModalShown = false
  };
}

export default CompaniesViewStore;