import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import './filters.scss';
import PrivateRoute from '../../routes/PrivateRoute';
import Authorization from '../../routes/Auhtorization';
import { COMPANY_USER } from '../../utils/common';
import GuestsReport from './ReportsComponents/GuestsReport/GuestsReport';
import EmployeesReport from './ReportsComponents/EmployeesReport/EmployeesReport';
import EfficiencyReport from './ReportsComponents/EfficiencyReport/EfficiencyReport';
import ParkingsReport from './ReportsComponents/ParkingsReport/ParkingsReport';
import ConstantEmployeeReport from './ReportsComponents/ConstantEmployeeReport/ConstantEmployeeReport';
import ReportsIndex from './ReportsIndex';
import ArrivalLogsReport from './ReportsComponents/ArrivalLogsReport/ArrivalLogsReport';
import ApplicationReport from './ReportsComponents/ApplicationReport/ApplicationReport';
import LprLogsReport from './ReportsComponents/LprLogsReport/LprLogsReport';
import LprLogsNotUsersReport from './ReportsComponents/LprLogsNotUsersReport/LprLogsNotUsersReport';
import LprEfficiencyReport from './ReportsComponents/LprEfficiencyReport/LprEfficiencyReport';

class Reports extends Component {
  render() {
    const companyOnly = Authorization(COMPANY_USER);
    return (
      <div className="full-height">
        <Switch>
          <PrivateRoute path="/reports/guests" component={companyOnly(GuestsReport)} />
          <PrivateRoute path="/reports/employees" component={companyOnly(EmployeesReport)} />
          <PrivateRoute path="/reports/efficiency" component={companyOnly(EfficiencyReport)} />
          <PrivateRoute path="/reports/parkings" component={companyOnly(ParkingsReport)} />
          <PrivateRoute path="/reports/constantEmployee" component={companyOnly(ConstantEmployeeReport)} />
          <PrivateRoute path="/reports/arrivalLogs" component={companyOnly(ArrivalLogsReport)} />
          <PrivateRoute path="/reports/application" component={companyOnly(ApplicationReport)} />
          <PrivateRoute path="/reports/lprlogs" component={companyOnly(LprLogsReport)} />
          <PrivateRoute path="/reports/lprlogsNotUsers" component={companyOnly(LprLogsNotUsersReport)} />
          <PrivateRoute path="/reports/lprefficiency" component={companyOnly(LprEfficiencyReport)} />
          <PrivateRoute path="/reports" component={companyOnly(ReportsIndex)} />
        </Switch>
      </div>
    );
  }
}

export default Reports;
