import dayjs from 'dayjs';
import PerformedBy from '../../pages/Reports/ReportsComponents/PerformedBy';
import React from 'react';

const ReportEndDateCell = ({ row, value }) => {
  if (value) {
    return (
      <p className="text-left">
        <span>{dayjs(value).format('YYYY-MM-DD HH:mm:ss')} [<PerformedBy by={row._original.releaseBy} />]</span>
      </p>
    )
  }
  return value;
};

export default ReportEndDateCell;