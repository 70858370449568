import { action, observable } from 'mobx';
import { authorize } from '../../components/AuthrorizeHOC/AuthorizeHOC';

class CrudStore {
  @observable list = observable.array();

  constructor(stores, apiService, permissions = {}) {
    this.api = apiService;
    this.permissions = permissions;
    this.commonStore = stores.commonStore;
  }

  @action
  getById(id) {
    if (!authorize(this.permissions.getById)) {
      return;
    }
    this.commonStore.setIsLoading(true);
    return this.api
      .getById(id)
      .then(
        action((response) => {
          if (response.results) {
            this.list.replace([response.results]);
          }
          this.commonStore.setIsLoading(false);
        })
      )
      .catch(() => {
        this.commonStore.setIsLoading(false);
      });
  }

  @action
  getAll(isSilent = false) {
    if (!authorize(this.permissions.getAll)) {
      return;
    }
    if (!isSilent) {
      this.commonStore.setIsLoading(true);
    }
    return this.api
      .getAll()
      .then(
        action((response) => {
          if (response.results) {
            this.list.replace(response.results);
          }
        })
      )
      .finally(() => {
        if (!isSilent) {
          this.commonStore.setIsLoading(false);
        }
      });
  }

  @action
  create(object) {
    if (!authorize(this.permissions.create)) {
      return;
    }
    this.commonStore.setIsLoading(true);
    return this.api
      .create(object)
      .then(
        action((response) => {
          if (response.results && response.results._id) {
            this.list.push(response.results);
          }
        })
      )
      .finally(() => {
        this.commonStore.setIsLoading(false);
      });
  }

  @action
  update(object) {
    if (!authorize(this.permissions.update)) {
      return;
    }
    this.commonStore.setIsLoading(true);
    return this.api
      .update(object)
      .then(
        action((response) => {
          if (response.results && response.results._id) {
            const idx = this.list.findIndex((c) => c._id === object._id);
            this.list[idx] = response.results;
          }
          return response;
        })
      )
      .finally(() => {
        this.commonStore.setIsLoading(false);
      });
  }

  @action
  delete(id) {
    if (!authorize(this.permissions.delete)) {
      return;
    }
    this.commonStore.setIsLoading(true);
    return this.api
      .delete(id)
      .then(
        action((response) => {
          const idx = this.list.findIndex((c) => c._id === id);
          this.list.splice(idx, 1);
          this.commonStore.setIsLoading(false);
        })
      )
      .catch(() => {
        this.commonStore.setIsLoading(false);
      });
  }
}

export default CrudStore;
