import { Redirect } from 'react-router-dom';
import { ROUTES } from '../utils/common';
import React from 'react';
import stores from '../stores/index';
import { observer } from 'mobx-react';

@observer
class DefaultRoute extends React.Component {
	render() {
		if (stores.userStore.isAdmin) {
			return <Redirect to={ROUTES.COMPANIES} />;
		} else if (stores.userStore.isAuthenticated) {
			if (stores.userStore.currentCompany.isSpecial) {
				return <Redirect to={ROUTES.SPECIAL_PARKINGS} />;
			} else if (stores.userStore.currentCompany.isUsingLightmode || stores.userStore.currentCompany.isManagementCompany) {
				return <Redirect to={ROUTES.LPR_DASHBOARD} />;
			} else {
				return <Redirect to={ROUTES.PARKINGS} />;
			}
		}
		return <Redirect to={ROUTES.LOGIN} />;
	}
}

export default DefaultRoute;
