import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import Error from '../../../components/forms/Error';

import stores from '../../../stores';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import BootstrapInput from '../../../components/BootstrapInput/BootstrapInput';

const { userStore } = stores;

@observer
class ChangePassword extends Component {

  onSubmitForm = (submittedValues) => {
    userStore.changePassword(submittedValues.currentPassword, submittedValues.newPassword).then(response => {
      toast("Password Changed!", { type: toast.TYPE.SUCCESS });
    }).catch(e => {
      if (e && e.data && e.data.results && e.data.results.error) {
        toast(e.data.results.error, { type: toast.TYPE.ERROR });
      }
      else {
        toast("Error while changing password", { type: toast.TYPE.ERROR });
      }
      console.error(e);
    });
  };

  validateForm = (values) => {
    if (values.newPassword !== values.confirmPassword) {
      return {
        confirmPassword: 'Confirm password does not match'
      }
    }
  };

  render() {
    return (
      <div className="text-left">
        <h1 className="page-title text-center">Change Password</h1>
        <Form onSubmit={this.onSubmitForm}
              validate={this.validateForm}
              render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
                return (
                  <div className="row">
                    <div className="col-sm-4 col-sm-offset-4">
                      <div className="form-container">
                        <form className="mt-2" onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label>Current password:</label>
                            <Field name="currentPassword" component={BootstrapInput} type="password" />
                            <Error name="currentPassword" />
                          </div>
                          <div className="form-group">
                            <label>New password:</label>
                            <Field name="newPassword" component={BootstrapInput} type="password" />
                          </div>
                          <div className="form-group">
                            <label>Confirm password:</label>
                            <Field name="confirmPassword" component={BootstrapInput} type="password" />
                            <Error name="confirmPassword" />
                          </div>
                          <button type="submit" className="btn btn-primary">Change password</button>
                        </form>
                      </div>
                    </div>
                  </div>
                )
              }
              } />
      </div>
    )
  }
}

export default ChangePassword;
