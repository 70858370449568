import dayjs from 'dayjs';
import React from 'react';
import API from '../../../utils/API/api';
import ReportsStore from '../reportsStore';

const tableColumns = [
  {
    Header: 'Date',
    accessor: 'date',
    Cell: ({ row, value }) => {
      const date = dayjs(value);
      return (
        <span>{date.format('DD/MM/YYYY')} [{date.format('ddd')}]</span>
      )
    }
  }, {
    Header: 'Parking Lot',
    accessor: 'parkingLot',
    Cell: ({ value }) => {
      if (!value) return 'All';
      return value;
    }
  }, {
    Header: 'Total Parkings',
    accessor: 'totalParkings',
  }, {
    Header: 'Used',
    accessor: 'usedCount'
  }, {
    Header: 'Unused',
    accessor: 'unusedCount'
  }
];

class EfficiencyReportStore extends ReportsStore {
  constructor(stores) {
    super(stores, tableColumns, API.company.reports.getParkingsEfficiencyReport, API.company.reports.exportParkingsEfficiency)
  }
}

export default EfficiencyReportStore;
