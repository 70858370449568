import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import DataTable from '../../components/DataTable/DataTable';
import stores from '../../stores';
import CamerasList from './CamerasList';
import Filters from './Filters';
import './lprDashboard.style.scss';
import { ROUTES } from '../../utils/common';
import CountersView from './CountersView';
import { activeIcon, notActiveIcon } from '../../assets';
import EditLprGuest from './EditLprGuest';
import Modal from '../../components/modal/Modal';
const { lprDashboardStore, parksStore, userStore } = stores;
const minuteInterval = 1000 * 30; // 30 seconds

@withRouter
@observer
class LprDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			allParksPerCompany: undefined,
			newGuest: null,
			showModel : false,
		}
	}

	intervalId;
	allParksPerCompany = [];
	currentCompany = userStore.currentCompany;

	componentDidMount() {

		lprDashboardStore.getLprLogs();
		// lprDashboardStore.getCounters();
		lprDashboardStore.getCountersPerPark();
		clearInterval(this.intervalId);
		this.intervalId = setInterval(() => {
			lprDashboardStore.getLastLprLogs();
		}, minuteInterval);


		if (this.currentCompany.isManagementCompany)
		{
			this.currentCompany.managedCompanies.map(company =>
			{
				parksStore.getParksByCompany(company.value)
				.then(res => {
					this.allParksPerCompany.push({
							company : company,
							parks : res
						})
				});
			})
			this.setState({ allParksPerCompany: this.allParksPerCompany })
		}
		else
		{
			parksStore.getParksByCompany(this.currentCompany._id)
				.then(res => {
					this.allParksPerCompany.push({
						company : this.currentCompany,
						parks : res
					})
					// console.log("res:", res);
					// this.setState({ allParksPerCompany: res })
					this.setState({ allParksPerCompany: this.allParksPerCompany })
				});
		}
	}

	componentWillUnmount() {
		clearInterval(this.intervalId);
	}

	onSearch = values => {
		lprDashboardStore.search(values);
	};

	onOpenGateClick = camera => {
		return lprDashboardStore.openGate(camera);
	};

	onRowSelected = row => {
		lprDashboardStore.onLogSelected(row._original);
	};

	handleDoubleClick = (rowInfo) => {
		if (rowInfo && rowInfo.row && rowInfo.row.carNumber) {
			const newGuest = {
				name: rowInfo.row.carNumber.toString(),
				phone : rowInfo.row.carNumber,
				startDate: new Date(),
				endDate: new Date(),
				carNumbers: [rowInfo.row.carNumber],
				isActive : true,
				shouldSentSMS : true
			};
			this.setState({
				newGuest: newGuest,
				showModal: true, // Correctly set showModal here
			});
		}
	};

	showEditModal = () => {
		this.setState({
			showModal: true, // Correct the typo here
		});
	};

	hideEditModal = () => {
		this.setState({
			showModal: false, // Ensure this matches the corrected state variable name
		});
	};


	isSelected = row => {
		const rowOriginal = row._original;
		let classNameProps = {};
		if (rowOriginal._id && lprDashboardStore.selectedLog && lprDashboardStore.selectedLog._id) {
			classNameProps['selected-row'] = rowOriginal._id === lprDashboardStore.selectedLog._id;
		}
		if (!rowOriginal.employee && !rowOriginal.guest) {
			classNameProps['unrecognized'] = true;
		}
		let className = '';
		for (const [key, value] of Object.entries(classNameProps)) {
			if (value) {
				className += ` ${key}`;
			}
		}
		return className;
	};


	render() {
		const {   parksStore } = stores;
		const { lprLogsList, selectedLog, cameras, counters } = lprDashboardStore;

		const columns = [];
		columns.push({
			Header: 'Date',
			accessor: 'createdAt',
			Cell: ({ value }) => {
				return dayjs(value).format('DD/MM/YYYY HH:mm');
			},
		});
		if (userStore.isAdmin || this.currentCompany.isManagementCompany) {
			columns.push({
				Header: 'Company Name',
				accessor: 'company',
				Cell: ({ value }) => (value && value.name) || '',
			});
		}
		columns.push(
			{
				id: 'parker',
				Header: 'Name',
				accessor: row => row.employee || row.guest,
				Cell: (row) => {
					if (row.value) {
						const isEmployee = !!row.original.employee;
						const linkUrl = isEmployee ? `${ROUTES.EMPLOYEES}/${row.value._id}` : `${ROUTES.GUESTS}/${row.value._id}`;
						return row.value._id && <Link to={linkUrl}>{row.value.name}</Link> || '';
					}
					return '';
				},
			},
			{
				Header: 'Present?',
				accessor: 'isPresent',
				Cell: ({ value, original }) => {
					if (!original.employee && !original.guest) return null;
					return value && <img src={activeIcon} alt="Present" /> || <img src={notActiveIcon} alt="Not present" />
				}
			},
			{
				Header: 'Car Number',
				accessor: 'carNumber',
			},
			{
				Header: 'Camera',
				accessor: 'camera',
				Cell: ({ value, original }) => {
					const cameraLabel = ((value && (<p key="1"><span>{value.name} [{(value.type || '').toUpperCase()}]</span></p>)) || null);
					return [cameraLabel];
				},
			},
			{
				Header: 'Status',
				accessor: 'statusMessage',
			},
		);
		return (
			<div className="lprDashboard full-height">
				<h1 className="page-title">LPR Dashboard</h1>
				<div className="page">
					<div className="sidebar">
						{
							this.state.allParksPerCompany && userStore.currentCompany.isUsingLightmode ?  <CountersView counters={counters} parksPerCompany={this.state.allParksPerCompany} /> : null
						}
						{this.currentCompany.isWithOpenGateDpmLpr && (<div className="camerasList border-bottom">
							<CamerasList cameras={cameras} openGate={this.onOpenGateClick} />
						</div>)}
						{selectedLog ? (
							<>
								<div className="divider" />
								<div className="plateImage border-bottom">
									<h4>Plate</h4>
									{selectedLog && (selectedLog.plateImage) ?
										<img src={selectedLog.plateImage} /> : ''}
								</div>
								<div className="divider" />
								<div className="cameraImage border-bottom">
									<h4>Car</h4>
									{selectedLog && (selectedLog.cameraImage) ?
										<img src={selectedLog.cameraImage} /> : ''}
								</div>
								<div className="divider" />
							</>
						) : (
							''
						)}
					</div>
					<div className="content">
						<div className="actions">
							<Filters onSubmit={this.onSearch} initialValues={{}} />
						</div>
						<div className="table-container">
							{this.state.showModal && this.state.newGuest && (
								<Modal
									title="New LPR Guest"
									show={this.state.showModal} // Use showModal for controlling visibility
									handleClose={this.hideEditModal} // Ensure this is passed to control modal visibility
								>
									<EditLprGuest parkingLots={parksStore.getDropdownList}
																	 guest={this.state.newGuest}
																	 closeModal={this.hideEditModal} />
								</Modal>
							)}
							<DataTable
								data={lprLogsList.slice()}
								columns={columns}
								defaultPageSize={50}
								minRows={50}
								showPagination={false}
								getTrProps={(state, rowInfo) => {
									// Initialize an empty props object
									let props = {};
									if (rowInfo && rowInfo.row) {
										// Attach the double-click event handler
										props.onDoubleClick = () => this.handleDoubleClick(rowInfo);
										// Determine the class name based on whether the row is selected
										const className = this.isSelected(rowInfo.row);
										// Attach the click event handler and class name
										props.onClick = () => this.onRowSelected(rowInfo.row);
										props.className = className;
									}
									// Return the props object, which now includes onClick, onDoubleClick, and className
									return props;
								}}

							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default LprDashboard;
