import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import FileInput from '../FileInput/FileInput';

class UploadFileModal extends Component {
  constructor(props) {
    super(props);
  }

  onSubmitForm = (submittedValues) => {
    this.props.onFileUploaded(submittedValues.file);
    this.props.closeModal();
  };

  validateForm = (values) => {
    const errors = {};
    if (!values.file) {
      errors.file = 'Please select or drop a .xlsx file';
    }
    return errors
  };

  render() {
    return (
      <Form onSubmit={this.onSubmitForm} validate={this.validateForm}
            render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <Field name="file" component={FileInput} />
                    </div>
                  </div>
                  <button type="submit" disabled={submitting} className="btn btn-primary">
                    Upload File
                  </button>
                </form>
              )
            }} />
    );
  }
}

UploadFileModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func.isRequired
};

export default UploadFileModal;