import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import { withRouter } from 'react-router-dom';
import { deleteIcon, editIcon } from '../../assets';
import Modal from '../../components/modal/Modal';
import stores from '../../stores';
import EditReservationModal from './EditReservationModal';
import './react-big-calendar.css';
import './reservations.style.scss';

const localizer = BigCalendar.momentLocalizer(moment);

@withRouter
@observer
class Reservations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalEvents: [],
      eventsForShow: [],
      modal: false,
      facilitySelectId: '',
      facilitySelectName: '',
      facilitySelectorError: false,
      showButtons: false,
      editMode: false,
      currentSelectedEvent: null,
      lastDayOfWeek: null,
      firstDayOfWeek: null,
      calendarStartHour: 8,
      calendarFinishHour: 22
    };
  }
  componentDidMount() {
    // Attach listeners to Back & Next navigation buttons
    document.getElementsByClassName('rbc-btn-group')[0].childNodes[0].addEventListener('click', this.todayButton);
    document.getElementsByClassName('rbc-btn-group')[0].childNodes[1].addEventListener('click', this.backButton);
    document.getElementsByClassName('rbc-btn-group')[0].childNodes[2].addEventListener('click', this.nextButton);

    // Set First & Last Day of the week
    let daysOfWeek = this.getDaysOfTheWeek(new Date());
    this.setState({ firstDayOfWeek: daysOfWeek[0], lastDayOfWeek: daysOfWeek[6] });

    // Load Reservations from db
    this.getCaledarEventsOfThisWeek(daysOfWeek[0], daysOfWeek[6]);
  }

  componentWillUnmount() {
    // Remove listeners to Back & Next navigation buttons
    document.getElementsByClassName('rbc-btn-group')[0].childNodes[0].removeEventListener('click', this.todayButton);
    document.getElementsByClassName('rbc-btn-group')[0].childNodes[1].removeEventListener('click', this.backButton);
    document.getElementsByClassName('rbc-btn-group')[0].childNodes[2].removeEventListener('click', this.nextButton);
  }
  // Load Reservations from db
  getCaledarEventsOfThisWeek = (firstDay, lastDay) => {
    stores.reservationsStore
      .getCalendarEventsOfThisWeek(firstDay, lastDay, stores.userStore.currentCompany._id)
      .then((response) => {
        let events = this.mapReservations(response.results);
        this.setState({ totalEvents: events }, () => {
          if (this.state.facilitySelectId.length > 0) {
            let eventsForShow = this.state.totalEvents.filter((item) => {
              return item.facilityId === this.state.facilitySelectId;
            });
            this.setState({ eventsForShow : eventsForShow });
          }
        });
      })
      .finally(() => {
        stores.reservationsStore.stopLoading();
      });
  };

  getDaysOfTheWeek = (date) => {
    let curr = date;
    let week = [];

    for (let i = 0; i <= 6; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first));
      week.push(day);
    }
    return week;
  };

  todayButton = () => {
    // Setting Days Range of Last Week
    let daysOfWeek = this.getDaysOfTheWeek(new Date());

    this.setState({ firstDayOfWeek: daysOfWeek[0], lastDayOfWeek: daysOfWeek[6] }, () => {
      // Load Reservations from db
      this.getCaledarEventsOfThisWeek(daysOfWeek[0], daysOfWeek[6]);
    });
  };

  backButton = () => {
    // Setting Days Range of Last Week
    let lastWeekFirstDay = new Date(this.state.firstDayOfWeek.getTime() - 7 * 24 * 60 * 60 * 1000);
    let daysOfWeek = this.getDaysOfTheWeek(lastWeekFirstDay);

    this.setState({ firstDayOfWeek: daysOfWeek[0], lastDayOfWeek: lastWeekFirstDay }, () => {
      // Load Reservations from db
      this.getCaledarEventsOfThisWeek(daysOfWeek[0], lastWeekFirstDay);
    });
  };

  nextButton = () => {
    // Setting Days Range of Next Week
    let nextWeekLastDay = new Date(this.state.lastDayOfWeek.getTime() + 7 * 24 * 60 * 60 * 1000);
    let daysOfWeek = this.getDaysOfTheWeek(nextWeekLastDay);
    this.setState({ firstDayOfWeek: daysOfWeek[0], lastDayOfWeek: nextWeekLastDay }, () => {
      // Load Reservations from db
      this.getCaledarEventsOfThisWeek(daysOfWeek[0], nextWeekLastDay);
    });
  };

  // Map Reservations from db to calendar events object
  mapReservations = (reservations) => {
    let eventsArray = [];

    reservations.forEach((reservation) => {
			let comments = (reservation.employee || {}).name + ' ' + reservation.comments;
      let event = {
        _id: reservation._id,
        facilityId: reservation.facilityId,
				title: comments,
        employee: reservation.employee,
        comments: reservation.comments,
        date: reservation.date,
        allDay: false,
        start: new Date(reservation.startTime),
        end: new Date(reservation.finishTime)
      };
      eventsArray.push(event);
    });
    return eventsArray;
  };

  onSelectEventHandler = (event) => {
    this.setState({ showButtons: true, currentSelectedEvent: event });
  };

  onEditClicked = () => {
    this.setState({ modal: true, editMode: true });
  };

  onDeleteClicked = () => {
    this.removeEvent(this.state.currentSelectedEvent._id);
    stores.reservationsStore
      .delete(this.state.currentSelectedEvent._id)
      .then(() => {
        this.removeEvent(this.state.currentSelectedEvent._id);
        this.setState({ selectedEvent: null, showButtons: false });
      })
      .catch((err) => {
        console.log('failure:', err);
      });
  };

  removeEvent = (id) => {
    let filterTotalEvents = this.state.totalEvents.filter((item) => item._id !== id);

    this.setState({ totalEvents: filterTotalEvents }, () => {
      let filterEventsForShow = this.state.totalEvents.filter((item) => item.facilityId === this.state.facilitySelectId);
      this.setState({ eventsForShow: filterEventsForShow });
    });
  };

  facilitySelectorHandler = (event) => {
    // Get Facility Id & Name
    let facilityId = event.target.value;
    let facilityName = event.target.value !== '' && stores.userStore.currentCompany.facilities[facilityId].facilityName;
    // filter events by facility id
    let events = this.state.totalEvents.filter((item) => {
      return parseInt(item.facilityId) === parseInt(facilityId);
    });
    this.setState({
      facilitySelectId: facilityId,
      facilitySelectName: facilityName,
      facilitySelectorError: '',
      eventsForShow: events
    });
  };

  showModal = () => {
    if (this.state.facilitySelectId) {
      this.setState({ modal: true, facilitySelectorError: false });
    } else {
      this.setState({ facilitySelectorError: true });
    }
  };

  closeModal = () => {
    this.setState({ modal: false, editMode: false });
  };

  renderFacilitiesOptions(companyFacilities) {
    return companyFacilities.map((facilityObj, index) => {
      if (facilityObj.facilityName && facilityObj.facilityName.trim()) {
        return (
          <option value={facilityObj.id} key={index}>
            {facilityObj.facilityName}
          </option>
        );
      } else {
        return null;
      }
    });
  }

  render() {
    const companyFacilities = toJS(stores.userStore.currentCompany.facilities);

    return (
      <div className='reservations-container'>
        <div className='reservations-header'>
          <div className='reservations-header-one'>Facility:</div>
          <div className='reservations-header-two'>
            <select
              onChange={this.facilitySelectorHandler}
              className={`${this.state.facilitySelectorError ? 'select-red' : ''}`}>
              <option value=''>Choose:</option>
              {this.renderFacilitiesOptions(companyFacilities)}
            </select>
          </div>
          <div className='reservations-header-three'>
            <h1 className='page-title'>Reservations</h1>
          </div>
          {/*Spacer */}
          <div className='reservations-header-four' />
        </div>
        <div
          className={`add-event ${this.state.facilitySelectorError ? 'add-event-red' : ''}`}
          onClick={this.showModal}>
          +
        </div>
        {this.state.showButtons && (
          <>
            <div className={`edit-event`} onClick={this.onEditClicked}>
              <img src={editIcon} alt='Edit' />
            </div>
            <div className={`delete-event`} onClick={this.onDeleteClicked}>
              <img src={deleteIcon} alt='Delete' />
            </div>
          </>
        )}
        <Modal title='Make a Reservation:' show={this.state.modal} handleClose={this.closeModal}>
          <EditReservationModal
            facilityId={this.state.facilitySelectId}
            facilityName={this.state.facilitySelectName}
            editMode={this.state.editMode}
            selectedEvent={this.state.currentSelectedEvent}
            removeEvent={this.removeEvent}
            closeModal={this.closeModal}
            addEventToCalendar={this.addEventToCalendar}
            startHour={this.state.calendarStartHour}
            finishHour={this.state.calendarFinishHour}
            getCalendarEventsOfThisWeek={this.getCaledarEventsOfThisWeek}
            lastDayOfWeek={this.state.lastDayOfWeek}
            firstDayOfWeek={this.state.firstDayOfWeek}
          />
        </Modal>
        <div className='reservations-calendar'>
          <BigCalendar
            defaultView='week'
            views={['week']}
            localizer={localizer}
            events={this.state.eventsForShow}
            startAccessor='start'
            endAccessor='end'
            onClick={this.clickEvent}
            onSelectEvent={this.onSelectEventHandler}
            min={new Date(2019, 5, 16, this.state.calendarStartHour, 0)}
            max={new Date(2019, 5, 16, this.state.calendarFinishHour, 0)}
          />
        </div>
      </div>
    );
  }
}

export default Reservations;

// eventPropGetter={(event) => ({
//   style: {
//     backgroundColor: event.start.getDay() < 5 ? '#ad4ca4' : '#3174ad'
//   }
// })}
// Show Hours from 8 AM to 10 PM
// components={{
//   event: (e) => {
//     return <div style={{ backgroundColor: 'red', height: '100%' }}>{e.title}</div>;
//   }
// }}
