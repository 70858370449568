import { action, observable } from 'mobx';
import API from '../../utils/API/api';
import dayjs from 'dayjs';
import moment from 'moment';

class ReportsStore {
  @observable currentReport = observable.array([]);
  @observable fromDate;
  @observable toDate;
  @observable tableColumns;

  getReportFunc;
  exportReportFunc;

  constructor(stores, tableColumns, getReportFunc, exportReportFunc, fromDate, toDate) {
    this.api = API.company.reports;
    this.commonStore = stores.commonStore;
    this.tableColumns = tableColumns;
    this.fromDate = fromDate || dayjs().startOf('month').toDate();
    this.toDate = toDate || new Date();
    this.getReportFunc = getReportFunc;
    this.exportReportFunc = exportReportFunc;
    /*stores.userStore.onCompanyUserLoaded((user) => {
      stores.parksStore.getAll();
      stores.employeesStore.getAll();
    })*/
  }

  @action
  setFromDate(fromDate) {
    if (fromDate) {
      this.fromDate = moment(fromDate).startOf('day').toDate();
    }
  }

  @action
  setToDate(toDate) {
    if (toDate) {
      this.toDate = moment(toDate).endOf('day').toDate();
    }
  }

  @action
  setSelectedGuest = (guest) => {
    this.selectedGuest = guest;
  };

  @action
  onReportResponse = (response) => {
		this.currentReport = response.results;
    this.commonStore.setIsLoading(false);
  };

  @action
  onGetResponseFailed = (e) => {
    this.commonStore.setIsLoading(false);
  };

  @action
  getReport(params) {
    this.commonStore.setIsLoading(true);
    const mergedParams = Object.assign({}, params, {
      startDate: this.fromDate.toISOString(),
      endDate: this.toDate.toISOString()
    });
    this.getReportFunc.apply(this.api, [mergedParams]).then(this.onReportResponse).catch(this.onGetResponseFailed);
  }

  @action
  exportReport(params) {
    const mergedParams = Object.assign({}, params, {
      startDate: this.fromDate.toISOString(),
      endDate: this.toDate.toISOString()
    });
    this.exportReportFunc.apply(this.api, [mergedParams]);
  }

	@action
	getLprLogsPerPark(parkId) {
		const mergedParams = {parkId: parkId};
    return this.getReportFunc.apply(this.api, [mergedParams])

	}
}

export default ReportsStore;
