export const defaultVacationObject = {
  id: null,
  employee: null,
  startTime: new Date(),
  endTime: new Date(),
  comments: '',
  company: null
};

class Vacation {
  constructor(rawVacation = defaultVacationObject) {
    this._id = rawVacation._id;
    this.comments = rawVacation.comments || '';
    this.startTime = new Date(rawVacation.startTime);
    this.endTime = new Date(rawVacation.endTime);
    this.company = rawVacation.company && rawVacation.company !== '' && rawVacation.company._id ? rawVacation.company._id : rawVacation.company;
    this.employee = rawVacation.employee && rawVacation.employee !== '' && rawVacation.employee._id ? rawVacation.employee._id : rawVacation.employee;
  }
}

export default Vacation;