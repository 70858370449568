import React, { Component } from 'react';
import isAuthorized from '../../../utils/isAuthorized';
import { ROLES, ROUTES } from '../../../utils/common';
import { observer } from 'mobx-react';
import AdminView from './AdminView';
import NavbarLink from '../NavbarLink';
import './responsiveNavBar.css'

const defaultPermissions = [ROLES.COMPANY, ROLES.SENIOR_SECRETARY, ROLES.SECRETARY];

@observer
class LoggedInView extends Component {
	render() {
		const { currentUser } = this.props;
		if (!currentUser) return null;

		if (currentUser && defaultPermissions.indexOf(currentUser.type) > -1) {
			return (
				<ul className="nav navbar-nav pull-xs-right">
					{((currentUser.company.isUsingDpmLpr && currentUser.company.isUsingLightmode) || currentUser.company.isManagementCompany) && (
						<li className="nav-item">
							<NavbarLink to="/lprDashboard">LPR Dashboard</NavbarLink>
						</li>
					)}
					{currentUser.company.isSpecial ? (
						<>
							{!currentUser.company.isUsingLightmode && (
								<li className="nav-item">
									<NavbarLink to="/special-parkings">Special Parkings</NavbarLink>
								</li>
							)}
							<li className="nav-item">
								<NavbarLink to="/employees">Users</NavbarLink>
							</li>
							{!currentUser.company.isUsingLightmode && currentUser.company.isWithReservations && (
								<li className="nav-item">
									<NavbarLink to="/reservations">Reservations</NavbarLink>
								</li>
							)}
							<li className="nav-item">
								<NavbarLink to="/reports">Reports</NavbarLink>
							</li>
						</>
					) : (
						<>
							{
								!currentUser.company.isManagementCompany &&
								<li className="nav-item">
									<NavbarLink to="/branches">Branches</NavbarLink>
								</li>
							}
							{currentUser.company.isUsingDpmLpr && !currentUser.company.isUsingLightmode && !currentUser.company.isManagementCompany && (
								<li className="nav-item">
									<NavbarLink to="/lprDashboard">LPR Dashboard</NavbarLink>
								</li>
							)}
							{
								!currentUser.company.isManagementCompany &&
								<li className="nav-item">
									<NavbarLink to="/parkingLots">
										{currentUser.company.isWithWorkstation ? 'Locations' : 'Parking Lots'}
									</NavbarLink>
								</li>
							}


							{!currentUser.company.isUsingLightmode && !currentUser.company.isManagementCompany && (
								<li className="nav-item">
									<NavbarLink to="/parkings">
										{currentUser.company.isWithWorkstation ? 'Workstations' : 'Parkings'}
									</NavbarLink>
								</li>
							)}

							<li className="nav-item">
								<NavbarLink to="/employees">Users</NavbarLink>
							</li>
							{
								<li className="nav-item">
									<NavbarLink to="/guests">Guests</NavbarLink>
								</li>
							}

							<li className="nav-item">
								<NavbarLink to="/reports">Reports</NavbarLink>
							</li>
							{!currentUser.company.isUsingLightmode && currentUser.company.isWithReservations && !currentUser.company.isManagementCompany && (
								<li className="nav-item">
									<NavbarLink to="/reservations">Reservations</NavbarLink>
								</li>
							)}

							{!currentUser.company.isUsingLightmode && currentUser.company.isWithBooking && !currentUser.company.isManagementCompany && (
								<li className="nav-item">
									<NavbarLink to={ROUTES.BOOKINGS}>Bookings</NavbarLink>
								</li>
							)}
							{!currentUser.company.isUsingLightmode && !currentUser.company.isManagementCompany && (
								<li className="nav-item">
									<NavbarLink to="/vacations">Vacations</NavbarLink>
								</li>
							)}
						</>
					)}
					{isAuthorized([ROLES.COMPANY], currentUser) && (
						<>
							<li className="nav-item">
								<NavbarLink to="/settings">Settings</NavbarLink>
							</li>

							<li className="nav-item">
								<NavbarLink to="/me">My Details</NavbarLink>
							</li>
							{!currentUser.company.isUsingLightmode && currentUser.company.isWithManualPush && (
								<li className="nav-item">
									<NavbarLink to="/sendPush">Push</NavbarLink>
								</li>
							)}
						</>
					)}

					<li className="nav-item">
						<NavbarLink to="/logout">Logout</NavbarLink>
					</li>
				</ul>
			);
		} else if (currentUser.type === 'admin') {
			return <AdminView {...this.props} />;
		}
		return null;
	}
}

export default LoggedInView;
