import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import arrayMutators from 'final-form-arrays';
import 'react-datepicker/dist/react-datepicker.css';
import './editParking.style.scss';
import ExternalParkingSelector from './ExternalParkingSelector';
import stores from '../../../../stores';
import { Field, Form, FormSpy } from 'react-final-form';
import Error from '../../../../components/forms/Error';
import Parking, { AVAILABILITY } from '../../../../models/Parking';
import Select from 'react-select';
import { generateOption, PARKING_STATUS } from '../../../../utils/common';
import dayjs from 'dayjs';
import EmployeesGuestSelector from '../../../../components/EmployeesGuestSelector/EmployeesGuestSelector';
import BootstrapInput from '../../../../components/BootstrapInput/BootstrapInput';
import { FieldArray } from 'react-final-form-arrays';
import { log } from '../../../../utils/log';
import { toast } from 'react-toastify';

const { parkingsStore, userStore } = stores;

const availabilityOptions = [
	generateOption('Enabled', AVAILABILITY.ENABLED),
	generateOption('Disabled', AVAILABILITY.DISABLED),
	generateOption('Temp Disabled', AVAILABILITY.TEMP_DISABLED),
];

const Condition = ({ when, is, children }) => (
	<Field name={when} subscription={{ value: true }}>
		{({ input: { value } }) => (value === is ? children : null)}
	</Field>
);

class EditParking extends Component {
	constructor(props) {
		super(props);
		this.state = {
			parking: props.parking,
			employeeNames: [],
		};
	}

	componentDidMount() {
		log && console.log('EditModalOpen:', window.localStorage.getItem('EditModalOpen'));
	}

	componentWillUnmount() {
		window.localStorage.setItem('EditModalOpen', false);
	}

	/*static getDerivedStateFromProps(nextProps, state) {
    return {
      parking: nextProps.parking,
      // employeeName: (nextProps.parking && nextProps.parking.contsantEmployeeName ? nextProps.parking.contsantEmployeeName : state.employeeName)
    }
  }*/

	renderTemporaryEnabled(parking) {
		let fromDate = new Date();
		const maxTime = dayjs()
			.endOf('day')
			.toDate();
		if (parking.status && parking.status.startTime) {
			if (moment(parking.status.startTime).isValid()) {
				fromDate = moment(parking.status.startTime).toDate();
			}
		}
		return (
			<div>
				<div className="row">
					<div className="col">Enable it from date*:</div>
					<div className="col">
						<Field name="status.startTime">
							{({ input, meta }) => {
								const now = dayjs();
								const value =
									typeof input.value === 'string' && input.value === ''
										? undefined
										: dayjs(input.value).toDate();
								const minTime =
									value && dayjs(value).isAfter(now)
										? dayjs()
												.startOf('day')
												.toDate()
										: new Date();
								return (
									<DatePicker
										selected={value}
										showTimeSelect
										onChange={input.onChange}
										onBlur={input.onBlur}
										onFocus={input.onFocus}
										minDate={new Date()}
										minTime={minTime}
										maxTime={maxTime}
										dateFormat="dd/MM/yyyy HH:mm"
										timeFormat="HH:mm"
									/>
								);
							}}
						</Field>
						<Error name="status.startTime" />
					</div>
				</div>
				<div className="row">
					<div className="col">Enable it to date*:</div>
					<div className="col">
						<Field name="status.endTime">
							{({ input, meta }) => {
								const value =
									typeof input.value === 'string' && input.value === ''
										? undefined
										: dayjs(input.value).toDate();
								const inputDayjs = dayjs(input.value);
								const fromDateDayjs = dayjs(fromDate);
								let minTime;
								if (inputDayjs.isAfter(fromDateDayjs) && fromDateDayjs.day() < inputDayjs.day()) {
									minTime = dayjs()
										.startOf('day')
										.toDate();
								} else {
									minTime = dayjs(fromDate)
										.add(30, 'minutes')
										.toDate();
								}
								return (
									<DatePicker
										selected={value}
										showTimeSelect
										onChange={input.onChange}
										onBlur={input.onBlur}
										onFocus={input.onFocus}
										minDate={fromDate}
										minTime={minTime}
										maxTime={maxTime}
										dateFormat="dd/MM/yyyy HH:mm"
										timeFormat="HH:mm"
									/>
								);
							}}
						</Field>
						<Error name="status.endTime" />
					</div>
				</div>
			</div>
		);
	}

	renderTemporaryDisabled(parking) {
		let fromDate = new Date();
		const maxTime = dayjs()
			.endOf('day')
			.toDate();
		if (parking.status && parking.status.startTime) {
			if (moment(parking.status.startTime).isValid()) {
				fromDate = moment(parking.status.startTime).toDate();
			}
		}
		return (
			<div>
				<div className="row">
					<div className="col">Disable it from date*:</div>
					<div className="col">
						<Field name="status.startTime">
							{({ input, meta }) => {
								const now = dayjs();
								const value =
									typeof input.value === 'string' && input.value === ''
										? undefined
										: dayjs(input.value).toDate();
								const minTime =
									value && dayjs(value).isAfter(now)
										? dayjs()
												.startOf('day')
												.toDate()
										: new Date();
								return (
									<DatePicker
										selected={value}
										showTimeSelect
										onChange={input.onChange}
										onBlur={input.onBlur}
										onFocus={input.onFocus}
										minDate={new Date()}
										minTime={minTime}
										maxTime={maxTime}
										dateFormat="dd/MM/yyyy HH:mm"
										timeFormat="HH:mm"
									/>
								);
							}}
						</Field>
						<Error name="status.startTime" />
					</div>
				</div>
				<div className="row">
					<div className="col">Disable it to date*:</div>
					<div className="col">
						<Field name="status.endTime">
							{({ input, meta }) => {
								const value =
									typeof input.value === 'string' && input.value === ''
										? undefined
										: dayjs(input.value).toDate();
								const inputDayjs = dayjs(input.value);
								const fromDateDayjs = dayjs(fromDate);
								let minTime;
								if (inputDayjs.isAfter(fromDateDayjs) && fromDateDayjs.day() < inputDayjs.day()) {
									minTime = dayjs()
										.startOf('day')
										.toDate();
								} else {
									minTime = dayjs(fromDate)
										.add(30, 'minutes')
										.toDate();
								}
								return (
									<DatePicker
										selected={value}
										showTimeSelect
										onChange={input.onChange}
										onBlur={input.onBlur}
										onFocus={input.onFocus}
										minDate={fromDate}
										minTime={minTime}
										maxTime={maxTime}
										dateFormat="dd/MM/yyyy HH:mm"
										timeFormat="HH:mm"
									/>
								);
							}}
						</Field>
						<Error name="status.endTime" />
					</div>
				</div>
			</div>
		);
	}

	renderBooking(parking) {
		let fromDate = new Date();
		const maxTime = dayjs()
			.endOf('day')
			.toDate();
		if (parking.booking && parking.booking.startTime) {
			if (moment(parking.booking.startTime).isValid()) {
				fromDate = moment(parking.booking.startTime).toDate();
			}
		}
		return (
			<div>
				<div className="row">
					<div className="col">Book for*:</div>
					<div className="col">
						<Field name="booking.bookedFor" component={EmployeesGuestSelector} />
						<Error name="booking.bookedFor" />
					</div>
				</div>
				<div className="row">
					<div className="col">Book from date*:</div>
					<div className="col">
						<Field name="booking.startTime">
							{({ input, meta }) => {
								const now = dayjs();
								const value =
									typeof input.value === 'string' && input.value === ''
										? undefined
										: dayjs(input.value).toDate();
								const minTime =
									value && dayjs(value).isAfter(now)
										? dayjs()
												.startOf('day')
												.toDate()
										: new Date();
								return (
									<DatePicker
										selected={value}
										showTimeSelect
										onChange={input.onChange}
										onBlur={input.onBlur}
										onFocus={input.onFocus}
										minDate={new Date()}
										minTime={minTime}
										maxTime={maxTime}
										dateFormat="dd/MM/yyyy HH:mm"
										timeFormat="HH:mm"
									/>
								);
							}}
						</Field>
						<Error name="booking.startTime" />
					</div>
				</div>
				<div className="row">
					<div className="col">Book to date*:</div>
					<div className="col">
						<Field name="booking.endTime">
							{({ input, meta }) => {
								const value =
									typeof input.value === 'string' && input.value === ''
										? undefined
										: dayjs(input.value).toDate();
								const inputDayjs = dayjs(input.value);
								const fromDateDayjs = dayjs(fromDate);
								let minTime;
								if (inputDayjs.isAfter(fromDateDayjs) && fromDateDayjs.day() < inputDayjs.day()) {
									minTime = dayjs()
										.startOf('day')
										.toDate();
								} else {
									minTime = dayjs(fromDate)
										.add(30, 'minutes')
										.toDate();
								}
								return (
									<DatePicker
										selected={value}
										showTimeSelect
										onChange={input.onChange}
										onBlur={input.onBlur}
										onFocus={input.onFocus}
										minDate={fromDate}
										minTime={minTime}
										maxTime={maxTime}
										dateFormat="dd/MM/yyyy HH:mm"
										timeFormat="HH:mm"
									/>
								);
							}}
						</Field>
						<Error name="booking.endTime" />
					</div>
				</div>
			</div>
		);
	}

	validateHours(fieldName, values) {
		const { parking } = this.props;
		const errors = {
			[fieldName]: {},
		};
		if (!parking) {
			return errors;
		}
		if (
			parking[fieldName] &&
			values[fieldName] &&
			parking[fieldName].startTime === values[fieldName].startTime &&
			parking[fieldName].endTime === values[fieldName].endTime
		) {
			return errors;
		}
		if (
			!values[fieldName] ||
			values[fieldName].startTime === undefined ||
			values[fieldName].startTime === null
		) {
			errors[fieldName].startTime = 'Start time is required for Temp Enabled status';
		}
		if (
			!values[fieldName] ||
			values[fieldName].endTime === undefined ||
			values[fieldName].endTime === null
		) {
			errors[fieldName].endTime = 'End time is required for Temp Enabled status';
		}
		if (values[fieldName] && values[fieldName].startTime && values[fieldName].endTime) {
			const now = dayjs();
			const startTime = dayjs(values[fieldName].startTime);
			const endTime = dayjs(values[fieldName].endTime);
			errors[fieldName] = {};
			if (startTime.isAfter(endTime)) {
				errors[fieldName] = {
					startTime: 'Start time cannot be after end time',
				};
			} else if (startTime.isSame(endTime)) {
				errors[fieldName] = {
					startTime: 'Start time cannot be the same end time',
				};
			} else if (startTime.isBefore(now)) {
				errors[fieldName] = {
					startTime: 'Start time is already passed',
				};
			} else if (endTime.isBefore(now)) {
				errors[fieldName] = {
					endTime: 'End time is already passed',
				};
			}
		}
		return errors;
	}

	validateForm = values => {
		let errors = {};
		if (!values.name || values.name.length === 0) {
			errors.name = 'Parking name is required';
		}
		if (!values.status || !values.status.type) {
			errors.status = 'Parking status is required';
		}
		if (!values.park) {
			errors.park = 'Parking lot is required';
		}

		if (values.isWithPartnerParking && values.isInnerParking) {
			errors.innerParkingError = 'Please uncheck inner parking';
		}
		if (values.isWithPartnerParking && !values.parkingPartner) {
			errors.innerParkingError = 'Please choose a partner';
		}
		if (values.status && values.status.type && values.status.type === PARKING_STATUS.TEMP_ENABLED) {
			errors = Object.assign({}, errors, this.validateHours('status', values));
		}
		if (values.status && values.status.type && values.status.type === PARKING_STATUS.BOOKED) {
			errors = Object.assign({}, errors, this.validateHours('booking', values));
			if (values.booking && !values.booking.bookedFor) {
				errors.booking.bookedFor = 'Select someone who will book this parking';
			}
			if (!values.booking) {
				errors.booking = {
					bookedFor: 'Select someone who will book this parking',
					startTime: 'Please select a start date',
					endTime: 'Please select an end date',
				};
			}
		}

		return errors;
	};

	onSubmitForm = submittedValues => {
		const that = this;
		const parking = new Parking(submittedValues);

		const isEditMode = this.props.parking && this.props.parking._id;
		const tempParkingPartner = parking.parkingPartner;

		if (!submittedValues.isInnerParking) {
			parking.externalParking = null;
		}
		if (!submittedValues.isVirtualParking) {
			parking.virtualParking = [];
		}
		if (!submittedValues.isWithPartnerParking) {
			parking.parkingPartner = null;
		}

		if (submittedValues && submittedValues.employee &&  Array.isArray(submittedValues.employee)) {
			submittedValues.employee =
				submittedValues
					.employee
					.filter(e => e && e._id && e.name);
		}

		submittedValues.employee = submittedValues.employee || null;

		parking.isWorkingStation = userStore.currentCompany.isWithWorkstation ? true : false

		if (!isEditMode) {
			return parkingsStore
				.create(parking)
				.then(async response => {
					log && console.log('response', response);
					await that.handleIsPartner(submittedValues, parking, tempParkingPartner);
					await that.setPartnerOf(submittedValues, tempParkingPartner, parking._id);

					toast(`Successfully created employee: ${parking.name}` ||
						'Successfully saved employee.',
						{ type: toast.TYPE.SUCCESS });
				})
				.then(response => {
					log && console.log('response', response);
					that.props.closeModal();
				})
				.catch(err => {
					log && console.error(err);
					toast(err.message || 'Error creating parking.',{type: toast.TYPE.WARNING});
				});
		} else {
			parkingsStore
				.update(parking)
				.then(async response => {
					log && console.log('response', response);
					await that.handleIsPartner(submittedValues, parking, tempParkingPartner);
					await that.setPartnerOf(submittedValues, tempParkingPartner, parking._id);

					toast(`Successfully updated employee: ${parking.name}` ||
						'Successfully updated employee.',
						{ type: toast.TYPE.SUCCESS });
				})
				.then(response => {
					log && console.log('response', response);
					that.props.closeModal();
				})
				.catch(err => {
					log && console.error(err);
					toast(err ? err.message : 'Error updating parking.', {type: toast.TYPE.WARNING});
				});
		}
	};

	setPartnerOf = async (submittedValues, partnerParking, currentParking) => {
		if (submittedValues.isWithPartnerParking) {
			parkingsStore.setPartnerOf(partnerParking, currentParking);
		} else {
			if (partnerParking) {
				parkingsStore.setPartnerOf(partnerParking, 0);
			}
		}
	};

	handleIsPartner = async (submittedValues, parking, parkingPartner) => {
		if (submittedValues.isWithPartnerParking) {
			await parkingsStore.setIsPartner(parking.parkingPartner, true);
		} else {
			if (parkingPartner) {
				await parkingsStore.setIsPartner(parkingPartner, false);
			}
		}
	};

	render() {
		const { parkingLots, parking, externalParkings, partnerExternalParkings } = this.props;
		const currentUser = userStore.currentUser;
		const { isWithWorkstation,isWithWorkstationMultiple} = userStore.currentCompany

		if (!parking) return null;

		// Filter and sort external parkings
		const externalParkingsFiltered = externalParkings
			.filter(p => p._id !== parking._id)
			.sort((a, b) => a.name.localeCompare(b.name));

		// Filter partner external parkings
		let partnerExternalParkingsFiltered = partnerExternalParkings
			.filter(p => p._id !== parking._id && !p.isPartner)
			.sort((a, b) => a.name.localeCompare(b.name));

		// Add parking partner if exists
		if (parking.parkingPartner) {
			const partnerParking = partnerExternalParkings.find(p => p._id === parking.parkingPartner);
			if (partnerParking) {
				partnerExternalParkingsFiltered.push(partnerParking);
			}
		}

		// Create Parking object
		const parkingObj = new Parking(this.props.parking || undefined);

		const isArrayEmployee = Array.isArray(parking.employee);
		const isWorkstation = isWithWorkstation;
		const isMulti = isWithWorkstationMultiple;
		let defaultValues = {
			...parkingObj,
			isInnerParking: !!parkingObj.externalParking,
			isVirtualParking: parkingObj.virtualParking && parkingObj.virtualParking.length > 0,
			isWithPartnerParking: !!parkingObj.parkingPartner,
		};

		// Check if it is not a workstation
		if (!isWorkstation) {
			// If it is not an array of employees, do nothing as the default is already set
			if (!isArrayEmployee) {
				// Do nothing, default values are good
			} else {
				// If it is an array of employees, set the first employee
				defaultValues = {
					...defaultValues,
					employee: parkingObj.employee[0]
				};
			}
		} else {
			// If it is a workstation
			if (isArrayEmployee) {
				// If it is an array of employees
				if (isMulti) {
					// If multiple employees, set the entire array of employees
					defaultValues = {
						...defaultValues,
						employee: parkingObj.employee
					};
				} else {
					// If not multiple, set the first employee
					defaultValues = {
						...defaultValues,
						employee: parkingObj.employee[0]
					};
				}
			} else {
				// If it is not an array of employees, set the single employee
				defaultValues = {
					...defaultValues,
					employee: parkingObj.employee
				};
			}
		}
	
		const shouldTranslate = this.props.displayLanguageModel === 'he';

		// Labels for fields
		const labels = {
			name: shouldTranslate ? 'שם:*' : 'Name*:',
			constantEmployee: shouldTranslate ? 'עובד קבוע:' : 'Constant Employee:',
			locationOrParkingLot: shouldTranslate
				? currentUser.company.isWithWorkstation
					? 'מיקום:*'
					: 'חניון:*'
				: currentUser.company.isWithWorkstation
				? 'Location*'
				: 'Parking Lot*:',
			status: shouldTranslate ? 'סטטוס:*' : 'Status*:',
			parkingPartner: shouldTranslate ? 'שותף חניה:' : 'Parking Partner:',
			choosePartner: shouldTranslate ? 'בחר שותף:' : 'Choose Partner:',
			inner: shouldTranslate ? 'פנימי?' : 'Inner?',
			outerParking: shouldTranslate ? 'חניה חיצונית' : 'Outer Parking',
			virtual: shouldTranslate ? 'וירטואלי?' : 'Virtual?',
			betweenParking: shouldTranslate ? 'בין חניה' : 'Between Parking',
			and: shouldTranslate ? 'ו' : 'And',
			preference: shouldTranslate ? 'העדפה:' : 'Preference:',
			saveChanges: shouldTranslate ? 'שמור שינויים' : 'Save Changes',
		};

		return (
			<Form
				onSubmit={this.onSubmitForm}
				keepDirtyOnReinitialize={true}
				initialValues={defaultValues}
				validate={this.validateForm}
				mutators={{ ...arrayMutators }}
				render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
					let availableStatuses = [...availabilityOptions];
					if (values.employee) {
						availableStatuses = [
							...availabilityOptions,
							generateOption(shouldTranslate ? 'מופעל זמני' : 'Temp Enabled', AVAILABILITY.TEMP_ENABLED),
						];
					} else if (userStore.currentCompany.isWithBooking) {
						availableStatuses = [
							...availabilityOptions,
							generateOption(shouldTranslate ? 'הזמנה' : 'Booking', AVAILABILITY.BOOKED),
						];
					}
					return (
						<form onSubmit={handleSubmit}>
							<div className="editParking">
								<div className="row">
									<div className="col">{labels.name}</div>
									<div className="col">
										<Field name="name" component={BootstrapInput} type="text" />
										<Error name="name" />
									</div>
								</div>

								{/* Employee Section */}
								<div className="row">
									<div className="col">{labels.constantEmployee}</div>
									{
										isWithWorkstation && isWithWorkstationMultiple ? (

											<div className="col">
												<button
													disabled
													type="button"
													style={{
														display: 'flex',
														justifyContent: 'center',
														fontSize: '.8rem',
														width: '100%',
														cursor:'not-allowed'
													}}
													onClick={() => {
														form.mutators.push('employee', undefined)
													}
													}
												>
													Add employee
												</button>

												<FieldArray name="employee">
													{({ fields }) => {
														return (
															<>
																{fields.map((name, index) => (
																	<div key={name} className="row">
																		<div className="col-6">
																			<label style={{ fontSize: '.8rem' ,margin:'auto 1rem auto auto'}}>{`Employee #${index + 1}`}</label>
																		</div>
																		<div className="col">
																			<Field name={name}>
																				{({ input }) => (
																					<span style={{
																						display: 'block', padding: '5px', border: '1px solid #ccc', borderRadius: '4px', backgroundColor: '#f9f9f9', cursor: 'not-allowed'
																					}}>{input.value.name ? input.value.name : input.value}</span>
																				)}
																			</Field>
																		</div>

																		{/* <div className="col-sm-8">
																			<Field
																				name={`${name}`}
																				component={EmployeesGuestSelector}
																				readOnly={true}
																				disabled={true}
																			/>
																		</div>
																		<div className="col-sm-1">
																			<span
																				onClick={() => fields.remove(index)}
																				style={{ cursor: 'pointer' }}
																			>
																				&times;
																			</span>
																		</div> */}
																		<Error name={`employee[${index}]`} />
																	</div>
																))}
																<Error name="employee" />
															</>
														)
													}}
												</FieldArray>
											</div>

										) : (

											<div className="col">
												<Field name="employee" component={EmployeesGuestSelector} />
												<Error name="employee" />
											</div>
										)
									}
								</div>


								<div className="row">
									<div className="col">{labels.locationOrParkingLot}</div>
									<div className="col">
										<Field name="park">
											{({ input, meta }) => {
												return (
													<Select
														className="basic-single"
														classNamePrefix="select"
														{...input}
														value={parkingLots.find(({ value }) => value === input.value._id)}
														onChange={({ value }) => input.onChange(value)}
														options={parkingLots}
													/>
												);
											}}
										</Field>
										<Error name="park" />
									</div>
								</div>
								<div className="row">
									<div className="col">{labels.status}</div>
									<div className="col">
										<Field name="status.type">
											{({ input, meta }) => {
												return (
													<Select
														className="basic-single"
														classNamePrefix="select"
														{...input}
														value={availableStatuses.find(({ value }) => {
															if (!input.value) return false;
															return value.type === input.value;
														})}
														onChange={({ value }) => input.onChange(value.type)}
														options={availableStatuses}
													/>
												);
											}}
										</Field>
									</div>
								</div>
								<FormSpy subscription={{ values: true }}>
									{({ values }) => {
										if (values.status && values.status.type === AVAILABILITY.TEMP_ENABLED.type) {
											return this.renderTemporaryEnabled(values);
										} else if (values.status && values.status.type === AVAILABILITY.TEMP_DISABLED.type) {
											return this.renderTemporaryDisabled(values);
										} else if (
											values.status &&
											values.status.type === AVAILABILITY.BOOKED.type &&
											userStore.currentCompany.isWithBooking
										) {
											return this.renderBooking(values);
										}
										return null;
									}}
								</FormSpy>
								{userStore.currentCompany.isWithPartnerParking ? (
									<>
										<div className="row">
											<div className="col">
												<label htmlFor="withPartnerParking">{labels.parkingPartner}</label>
											</div>
											<div className="col">
												<Field
													name="isWithPartnerParking"
													component={BootstrapInput}
													type="checkbox"
													id="withPartnerParking"
												/>
											</div>
										</div>
										<Condition when="isWithPartnerParking" is={true}>
											<div className="row">
												<div className="col">{labels.choosePartner}</div>
												<div className="col">
													<Field name="parkingPartner">
														{({ input, meta }) => {
															return (
																<ExternalParkingSelector
																	selected={input.value}
																	placeHolder={shouldTranslate ? 'בחר שותף' : 'Select partner'}
																	onSelect={option => {
																		input.onChange(option ? option._id : undefined);
																	}}
																	externalParkings={partnerExternalParkings}
																/>
															);
														}}
													</Field>
													<Error name="innerParkingError" />
												</div>
											</div>
										</Condition>
									</>
								) : null}
								<Condition when="isWithPartnerParking" is={false}>
									<div className="row">
										<div className="col">{labels.inner}</div>
										<div className="col">
											<Field name="isInnerParking" type="checkbox">
												{({ input, meta }) => {
													return (
														<div className="form-check">
															<input
																className="form-check-input"
																type="checkbox"
																id="isInnerParking"
																{...input}
																checked={input.value}
															/>
															<label className="form-check-label" htmlFor="isInnerParking">
																{shouldTranslate ? 'פנימי' : 'Inner'}
																{currentUser.company.isWithWorkstation ? (shouldTranslate ? ' תחנת עבודה' : ' Workstation') : (shouldTranslate ? ' חניה' : ' Parking')}
															</label>
														</div>
													);
												}}
											</Field>
										</div>
									</div>
									{values.isInnerParking && (
										<div className="row">
											<div className="col">{labels.outerParking}</div>
											<div className="col">
												<Field name="externalParking">
													{({ input, meta }) => {
														return (
															<ExternalParkingSelector
																selected={input.value}
																placeHolder={shouldTranslate ? 'בחר חניה חיצונית' : 'Select outer parking'}
																onSelect={option => {
																	input.onChange(option ? option._id : undefined);
																}}
																externalParkings={externalParkings}
															/>
														);
													}}
												</Field>
											</div>
										</div>
									)}
								</Condition>
								{userStore.currentCompany.isWithVirtualParking && (
									<>
										<div className="row">
											<div className="col">{labels.virtual}</div>
											<div className="col">
												<Field name="isVirtualParking">
													{({ input, meta }) => {
														return (
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="checkbox"
																	id="isVirtualParking"
																	{...input}
																	checked={input.value}
																/>
																<label className="form-check-label" htmlFor="isVirtualParking">
																	{shouldTranslate ? 'חניה וירטואלית' : 'Virtual Parking'}
																</label>
															</div>
														);
													}}
												</Field>
											</div>
										</div>
										{values.isVirtualParking && (
											<div className="row">
												<div className="col">{labels.betweenParking}</div>
												<div className="col">
													<Field name="virtualParking[0]">
														{({ input, meta }) => {
															return (
																<ExternalParkingSelector
																	selected={input.value}
																	placeHolder={shouldTranslate ? 'בחר חניה וירטואלית 1' : 'Select virtual parking 1'}
																	onSelect={option =>
																		input.onChange(option ? option._id : undefined)
																	}
																	externalParkings={externalParkings}
																/>
															);
														}}
													</Field>
													<span>{labels.and}</span>
													<Field name="virtualParking[1]">
														{({ input, meta }) => {
															return (
																<ExternalParkingSelector
																	selected={input.value}
																	placeHolder={shouldTranslate ? 'בחר חניה וירטואלית 2' : 'Select virtual parking 2'}
																	onSelect={option =>
																		input.onChange(option ? option._id : undefined)
																	}
																	externalParkings={externalParkings}
																/>
															);
														}}
													</Field>
												</div>
											</div>
										)}
									</>
								)}
								<div className="row">
									<div className="col">{labels.preference}</div>
									<div className="col">
										<Field name="preference" component={BootstrapInput} type="text" />
										<Error name="preference" />
									</div>
								</div>
								<div className="row center">
									<button type="submit" disabled={submitting}>
										{labels.saveChanges}
									</button>
								</div>
							</div>
						</form>
					);
				}}
			/>
		)
	}

}

EditParking.propTypes = {
	parking: PropTypes.object,
	parkingLots: PropTypes.array.isRequired,
	externalParkings: PropTypes.array.isRequired,
	partnerExternalParkings: PropTypes.array.isRequired,
	closeModal: PropTypes.func.isRequired,
	displayLanguageModel: PropTypes.string.isRequired,
};

export default EditParking;
