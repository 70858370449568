import React, { Component } from 'react';
import './modal.style.scss';
import classnames from 'classnames';

const ESC = 27;

class Modal extends Component {

  onKeyPress = (event) => {
    if (event.keyCode === ESC && this && this.props)  {
      this.props.handleClose();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyPress, false);
  }
  componentWillUnmount() {
      document.removeEventListener("keydown", this.onKeyPress, false);
  }

  onClickBackground = (event) => {
    if (event.target.classList.contains('modal')) {
      if (this.props.children.reset) {
        this.props.children.reset();
      }
      this.props.handleClose();
    }
  };

  render() {
    const { title, handleClose, show = false, children } = this.props;

    const modalClassNames = classnames({
      'modal': true,
      'modal-effect-1': true,
      'modal-show': show
    });

    const overlayClassNames = classnames({
      'modal-overlay': true,
      'show': show,
    });

    return (
      <div className={overlayClassNames}>
        <div className={modalClassNames} onClick={this.onClickBackground}>
          <section className="modal-main">
            <section className="modal-header">
              <h2>{title}</h2>
              <button onClick={handleClose}>&times;</button>
            </section>
            <div className="modal-content">
              {children}
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Modal;
