import React from 'react';
import API from '../../../utils/API/api';
import ReportsStore from '../reportsStore';
import { action, observable } from 'mobx';

const tableColumns = [
  {
    Header: 'Employee',
    accessor: 'employee'
  }, {
    Header: 'Arrived',
    accessor: 'arrivedCount',
  }, {
    Header: 'Doesn\'t arrive',
    accessor: 'doesntArriveCount'
  }, {
    Header: 'Not responded',
    accessor: 'notRespondedCount'
  }, {
    Header: 'Employee Vacation',
    accessor: 'employeeVacationsCount'
  }, {
    Header: 'Company Vacation',
    accessor: 'companyVacationsCount'
  }, {
    Header: 'Weekend Days',
    accessor: 'weekendDays'
  }, {
    Header: 'Total Days',
    accessor: 'totalDays'
  },
];

class ConstantEmployeeReportStore extends ReportsStore {
  @observable selectedEmployee;

  constructor(stores) {
    super(stores, tableColumns, API.company.reports.getConstantEmployeeReport, API.company.reports.exportConstantEmployeeReport)
  }

  @action
  setSelectedEmployee(selectedEmployee) {
    this.selectedEmployee = selectedEmployee;
  }
}

export default ConstantEmployeeReportStore;
