import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import Error from '../../../components/forms/Error';

import stores from '../../../stores';
import User from '../../../models/User';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import BootstrapInput from '../../../components/BootstrapInput/BootstrapInput';

const { userStore } = stores;

@observer
class UserDetails extends Component {

  onSubmitForm = (submittedValues) => {
    const currentUser = new User(submittedValues);
    userStore.updateUser(currentUser).then(response => {
      toast("User Details saved!", { type: toast.TYPE.SUCCESS });
    }).catch(e => {
      toast("Error while saving user details", { type: toast.TYPE.ERROR });
      console.error(e);
    });
  };

  render() {
    const defaultValues = new User(userStore.currentUser);
    return (
      <div className="text-left">
        <h1 className="page-title text-center">User Details</h1>
        <Form onSubmit={this.onSubmitForm} initialValues={defaultValues}
              validate={this.validateForm}
              render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
                return (
                  <div className="row">
                    <div className="col-sm-4 col-sm-offset-4">
                      <div className="form-container">
                        <form className="mt-2" onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label>Full Name:</label>
                            <Field name="name" component={BootstrapInput} type="text" />
                            <Error name="name" />
                          </div>
                          <div className="form-group">
                            <label>Username:</label>
                            <Field name="username" disabled component={BootstrapInput} type="text" />
                          </div>
                          <div className="form-group">
                            <label>Phone:</label>
                            <Field name="phone" component={BootstrapInput} type="text" />
                            <Error name="phone" />
                          </div>
                          <div className="form-group">
                            <label>Email:</label>
                            <Field name="email" component={BootstrapInput} type="text" />
                            <Error name="email" />
                          </div>
                          <button type="submit" className="btn btn-primary">Save</button>
                        </form>
                      </div>
                    </div>
                  </div>
                )
              }
              } />
      </div>
    )
  }
}

export default UserDetails;