import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import  './mobileFit.css'

class DataTable extends Component {
	constructor(props) {
		super(props);
		this.tableContainerRef = React.createRef();
	}

	componentDidMount() {
		this.setTableHeight();
		window.addEventListener('resize', this.setTableHeight);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.setTableHeight);
	}

	setTableHeight = () => {
		// console.log('browser resized, calculating table height');
		if (this.tableContainerRef && this.tableContainerRef.current && (this.props.autoHeight !== false)) {
			const height = document.body.offsetHeight - this.tableContainerRef.current.offsetTop;
			this.tableContainerRef.current.style.height = `${height}px`;
		}
	};

	render() {
		// const height = window.outerHeight - table.offsetTop;
		return (
			<ReactTable
				data={this.props.data}
				columns={this.props.columns}
				defaultPageSize={this.props.defaultPageSize || 250}
				minRows={this.props.minRows || 50}
				pageSizeOptions={[50, 100, 150, 200, 250, 500, 1000]}
				style={{
					height: "100%" // This will force the table body to overflow and scroll, since there is not enough room
				}}
				className="-striped -highlight"
				{...this.props}
			>
				{(state, makeTable, instance) => {
					return (
						<div
							ref={this.tableContainerRef}
							style={{
								overflow: "hidden",
							}}
						>
							{makeTable()}
						</div>
					);
				}}
			</ReactTable>
		);
	}
}

DataTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
};

export default DataTable;
