import React, { Component } from 'react';
import stores from '../../stores';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { deleteIcon, editIcon } from '../../assets';
import Modal from '../../components/modal/Modal';
import EditBranchModal from './BranchesComponents/EditBranchModal';
import DataTable from '../../components/DataTable/DataTable';

const { branchesStore } = stores;

@withRouter
@observer
class Branches extends Component {
  constructor(props) {
    super(props);
  }

  onEditRow = (row) => {
    branchesStore.editBranch(row._original);
  };

  onDeleteRow = (row) => {
    const answer = window.confirm("Are you sure you want to delete this forever?");
    if (answer) {
      branchesStore.delete(row._original._id);
    }
  };

  onNewBranchClick = () => {
    branchesStore.newBranch();
  };

  render() {
    const { list, isEditModalShown, hideEditModal, selectedBranch } = branchesStore;
    const columns = [
      {
        Header: 'Branch Name',
        accessor: 'name',
        filterMethod: (filter, row) => {
          if (!row['name']) return false;
          return row['name'].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
        },
        Footer: (columnProps) => {
          return (
            <span>
            <strong>Total: </strong>{columnProps.data.length}
          </span>
          )
        },
      }, {
        Header: 'Edit',
        Cell: ({ row }) => <img src={editIcon} onClick={this.onEditRow.bind(this, row)} />,
        sortable: false,
        filterable: false,
      }, {
        Header: 'Delete',
        Cell: ({ row }) => <img src={deleteIcon} onClick={this.onDeleteRow.bind(this, row)} />,
        sortable: false,
        filterable: false,
      }
    ];

    return (
      <div className="full-height">
        <h1 className="page-title">Branches</h1>
        <div className="actions">
          <button type="button" onClick={this.onNewBranchClick}>New Branch</button>
        </div>
        <DataTable
          filterable
          data={list.slice()}
          columns={columns}
          defaultSorted={[
            {
              id: "name",
              desc: false
            }
          ]}
        />
        <Modal title="New Branch" show={isEditModalShown} handleClose={hideEditModal}>
          <EditBranchModal branch={selectedBranch} closeModal={hideEditModal} />
        </Modal>
      </div>
    );
  }
}

Branches.propTypes = {
  //myProp: PropTypes.object.isRequired
};

Branches.defaultProps = {
  //myProp: <defaultValue>
};

export default Branches;
