import React from 'react';
import { ResponseType } from '../../../models/Employee';
import { notActiveIcon } from '../../../assets';

export default function EmployeeResponseCell(props) {
  const parking = props.original;
  const { isSendingHoursNow } = props;
  if (!parking) return null;

  const employeeResponse = props.value;
	if (employeeResponse === ResponseType.ARRIVE ) {
		return <span className="font-weight-bold">Arrived</span>;
	}
  if (employeeResponse === ResponseType.NOT_RESPONDED && !isSendingHoursNow) {
    return <span className="font-weight-bold">Not Responded</span>;
  }
  else if (employeeResponse === ResponseType.DOES_NOT_ARRIVE) {
    return <span className="font-weight-bold">Doesn't arrive</span>;
  }
  else if (parking.employee) {
    return (<img src={notActiveIcon} />);
  }
  return null;
};
