import { action, observable } from 'mobx';
import stores from '../index';
import API from '../../utils/API/api';

class AuthStore {
  @observable inProgress = false;
  @observable errors = undefined;
  @observable isLoggedIn = false;
  @observable isForgotPasswordModalShown = false;

  @observable values = {
    username: '',
    password: '',
  };

  constructor() {
    this.api = API.auth;
  }

  @action setUsername(username) {
    this.values.username = username;
  }

  @action setPassword(password) {
    this.values.password = password;
  }

  @action reset() {
    this.values.username = '';
    this.values.password = '';
  }

  @action showForgotPassword = () => {
    this.isForgotPasswordModalShown = true;
  };

  @action hideForgotPassword = () => {
    this.isForgotPasswordModalShown = false;
  };

  @action checkLogin() {
    return this.api.isLoggedIn()
      .then(() => {
        return stores.userStore.pullUser()
      })
      .then(() => {
        this.loadingUser = false;
      })
  }

  @action login() {
    this.inProgress = true;
    this.errors = undefined;
    return this.api.login(this.values.username, this.values.password)
      .then((response) => {
        stores.userStore.setUser(response);
        return response;
      })
      .catch(action((err) => {
        this.errors = err.response && err.response.body && err.response.body.errors;
        throw err;
      }))
      .finally(action(() => {
        this.inProgress = false;
      }));
  }

  @action forgotPassword(email, phone) {
    return this.api.forgotPassword(email, phone);
  }

  @action resetPasswordViaEmail(token, newPassword) {
    return this.api.resetPasswordViaEmail(token, newPassword);
  }

  @action logout() {
    this.isLoggedIn = false;
    stores.userStore.forgetUser();
    return this.api.logout();
  }
}

export default AuthStore;
