import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from '../../stores';
import Select from 'react-select';
import './smsLogs.styles.scss';
import DataTable from '../../components/DataTable/DataTable';
import dayjs from 'dayjs';

const { smsLogsViewStore, companiesStore } = stores;

@withRouter
@observer
class SmsLogs extends Component {
  componentDidMount() {
    smsLogsViewStore.loadSmsLogs();
  }

  onMonthSelected = (selectedMonth) => {
    smsLogsViewStore.setSelectedMonth(selectedMonth);
  };

  onCompanySelected = (selectedCompany) => {
    smsLogsViewStore.setSelectedCompany(selectedCompany);
  };

  onSearch = () => {
    smsLogsViewStore.loadSmsLogs();
  };

  render() {
    const { smsLogsList, monthsList, selectedMonth, selectedCompany } = smsLogsViewStore;
    const companiesList = companiesStore.companiesList.map(c => ({ value: c._id, label: c.name }));
    const columns = [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => {
          return dayjs(value).format('YYYY-MM-DD (dddd)')
        },
        Footer: (columnProps) => {
          return (
          <span>
            <strong>Total Days: </strong>
            {Object.keys(columnProps.data.reduce((prev, current) => {
                if (prev[current.date] === undefined) {
                  prev[current.date] = 1;
                }
                return prev;
            }, {}
            ) || {}).length || 0}
          </span>
          )
        },
      }, {
        Header: 'Company Name',
        accessor: 'company'
      }, {
        Header: 'Quantity SMS Sent',
        accessor: 'quantity',
        Footer: (
          <span>
            <strong>Total:</strong>{" "}
            {smsLogsList.reduce((prev, current) => {
                prev += current.quantity;
                return prev;
              }, 0
            ) || 0}
          </span>
        )
      }
    ];
    return (
      <div className="sms-logs-page full-height">
        <h1 className="page-title mt-1">SMS Logs</h1>
        <div className="actions">
          <div className="row">
            <div className="col-sm-2">
              Month
            </div>
            <div className="col-sm-2">
              <Select
                className="basic-single"
                classNamePrefix="select"
                name="company"
                value={selectedMonth}
                onChange={this.onMonthSelected}
                options={monthsList.slice()}
              />
            </div>
            <div className="col-sm-2">
              Company
            </div>
            <div className="col-sm-2">
              <Select
                className="basic-single"
                classNamePrefix="select"
                name="company"
                value={selectedCompany}
                isClearable={true}
                onChange={this.onCompanySelected}
                options={companiesList.slice()}
              />
            </div>
            <div className="col-sm-2">
              <button onClick={this.onSearch}>Search</button>
            </div>
          </div>
        </div>
        <DataTable
          data={smsLogsList.slice()}
          columns={columns}
        />
      </div>
    );
  }
}

export default SmsLogs;
