import React from 'react';
import { activeIcon, notActiveIcon } from '../../../assets';
import { PARKING_STATUS } from '../../../utils/common';
import { Link } from 'react-router-dom';

function ParkerDetails({parker, showCarNumbers = false}) {
	if (parker.by) {
		return (
			<>
				<p><Link to={{
					pathname: `employees/${parker.by}`,
					state: { employeeId: parker.by }
				}}>{parker.name}</Link></p>
				{showCarNumbers && (<p>{(parker.carNumbers || []).join('\n')}</p>)}
			</>
		)
	}
	return (
		<>
			<p>{parker.name}</p>
			<p>{parker.phone}</p>
		</>
	)
}

// export default function ParkingAvailableCell(props) {
// 	const parking = props.original;
// 	const { status, unavailableStatus } = parking;

// 	if (parking.isAvailable === true && !parking.isWorkingStation) {
// 		return <img src={activeIcon} />;
// 	}

// 	if (parking.isWorkingStation && parking.status && parking.status.type && (parking.status.type === PARKING_STATUS.DISABLED || parking.status.type === PARKING_STATUS.AVAILABLE || parking.status.type === PARKING_STATUS.TEMP_DISABLED)) {
// 		if (unavailableStatus) {
// 			if (unavailableStatus.type === PARKING_STATUS.OCCUPIED) {
// 				if (!!unavailableStatus.previous) {
// 					return (
// 						<div>
// 							<p><b>Occupied: </b></p>
// 							<ParkerDetails parker={unavailableStatus} />
// 							<p><b>Previous: </b></p>
// 							<ParkerDetails parker={unavailableStatus.previous} showCarNumbers={true} />
// 						</div>
// 					)
// 				}
// 				else if (unavailableStatus.name && !unavailableStatus.by) {
// 					return (
// 						<div>
// 							<p><b>{unavailableStatus.isBlocked ? 'Blocked' : 'Guest'}: </b></p>
// 							<ParkerDetails parker={unavailableStatus} />
// 						</div>
// 					)
// 				}
// 				else if (unavailableStatus.by !== null) {
// 					return (
// 						<div>
// 							{unavailableStatus.isBlocked && (<p><b>Blocked:</b></p>)}
// 							<p>{unavailableStatus.name}</p>
// 						</div>
// 					)
// 				}
// 			}
// 			else if (unavailableStatus.type === PARKING_STATUS.DISABLED || unavailableStatus.type === PARKING_STATUS.BOOKED) {
// 				return <img src={notActiveIcon} />;
// 			}
// 		}
// 		return parking.status.type === PARKING_STATUS.DISABLED || parking.status.type === PARKING_STATUS.TEMP_DISABLED ? <img src={notActiveIcon} /> : <img src={activeIcon} />;
// 	}
	
// 	else if (status.type === PARKING_STATUS.TEMP_DISABLED && !parking.isAvailable) {
// 		return <img src={notActiveIcon} />;
// 	}
	
// 	else if (unavailableStatus) {
// 		if (unavailableStatus.type === PARKING_STATUS.OCCUPIED) {
// 			if (!!unavailableStatus.previous) {
// 				return (
// 					<div>
// 						<p><b>Occupied: </b></p>
// 						<ParkerDetails parker={unavailableStatus} />
// 						<p><b>Previous: </b></p>
// 						<ParkerDetails parker={unavailableStatus.previous} showCarNumbers={true} />
// 					</div>
// 				)
// 			}
// 			else if (unavailableStatus.name && !unavailableStatus.by) {
// 				return (
// 					<div>
// 						<p><b>{unavailableStatus.isBlocked ? 'Blocked' : 'Guest'}: </b></p>
// 						<ParkerDetails parker={unavailableStatus} />
// 					</div>
// 				)
// 			}
// 			else if (unavailableStatus.by !== null) {
// 				return (
// 					<div>
// 						{unavailableStatus.isBlocked && (<p><b>Blocked:</b></p>)}
// 						<p>{unavailableStatus.name}</p>
// 					</div>
// 				)
// 			}
// 		}
// 		else if (unavailableStatus.type === PARKING_STATUS.DISABLED || unavailableStatus.type === PARKING_STATUS.BOOKED) {
// 			return <img src={notActiveIcon} />;
// 		}
// 	}
// 	else {
// 		return (
// 			<div>

// 			</div>
// 		);
// 	}

// }

export default function ParkingAvailableCell(props) {
    const parking = props.original;
    const { status, unavailableStatus } = parking;

    // Check if parking is available and not a working station
    if (parking.isAvailable === true && !parking.isWorkingStation) {
        return <img src={activeIcon} />;
    }

    // Check if parking is a working station and handle various status types
    if (parking.isWorkingStation && status && status.type) {
        // Handle occupied status with previous parking details
        if (unavailableStatus && unavailableStatus.type === PARKING_STATUS.OCCUPIED) {
            if (unavailableStatus.previous) {
                return (
                    <div>
                        <p><b>Occupied: </b></p>
                        <ParkerDetails parker={unavailableStatus} />
                        <p><b>Previous: </b></p>
                        <ParkerDetails parker={unavailableStatus.previous} showCarNumbers={true} />
                    </div>
                );
            }

            // Handle blocked or guest status without a "by" field
            if (unavailableStatus.name && !unavailableStatus.by) {
                return (
                    <div>
                        <p><b>{unavailableStatus.isBlocked ? 'Blocked' : 'Guest'}: </b></p>
                        <ParkerDetails parker={unavailableStatus} />
                    </div>
                );
            }

            // Handle blocked status with a "by" field
            if (unavailableStatus.by !== null) {
                return (
                    <div>
                        {unavailableStatus.isBlocked && (<p><b>Blocked:</b></p>)}
                        <p>{unavailableStatus.name}</p>
                    </div>
                );
            }
        } else if(unavailableStatus && unavailableStatus.type === PARKING_STATUS.DISABLED && status && status.type === PARKING_STATUS.TEMP_ENABLED || status.type === PARKING_STATUS.AVAILABLE){
			return <img src={activeIcon} />;
		}

        // Handle disabled or booked status
        if (unavailableStatus && (unavailableStatus.type === PARKING_STATUS.DISABLED || unavailableStatus.type === PARKING_STATUS.BOOKED)) {
            return <img src={notActiveIcon} />;
        }

        // Default handling for working station status
        return (status.type === PARKING_STATUS.DISABLED || status.type === PARKING_STATUS.TEMP_DISABLED) ? <img src={notActiveIcon} /> : <img src={activeIcon} />;
    }

    // Handle temporary disabled status when parking is not available
    if (status.type === PARKING_STATUS.TEMP_DISABLED && !parking.isAvailable) {
        return <img src={notActiveIcon} />;
    }

    // General handling for unavailable status
    if (unavailableStatus) {
        if (unavailableStatus.type === PARKING_STATUS.OCCUPIED) {
            if (unavailableStatus.previous) {
                return (
                    <div>
                        <p><b>Occupied: </b></p>
                        <ParkerDetails parker={unavailableStatus} />
                        <p><b>Previous: </b></p>
                        <ParkerDetails parker={unavailableStatus.previous} showCarNumbers={true} />
                    </div>
                );
            }

            if (unavailableStatus.name && !unavailableStatus.by) {
                return (
                    <div>
                        <p><b>{unavailableStatus.isBlocked ? 'Blocked' : 'Guest'}: </b></p>
                        <ParkerDetails parker={unavailableStatus} />
                    </div>
                );
            }

            if (unavailableStatus.by !== null) {
                return (
                    <div>
                        {unavailableStatus.isBlocked && (<p><b>Blocked:</b></p>)}
                        <p>{unavailableStatus.name}</p>
                    </div>
                );
            }
        }

        if (unavailableStatus.type === PARKING_STATUS.DISABLED || unavailableStatus.type === PARKING_STATUS.BOOKED) {
            return <img src={notActiveIcon} />;
        }
    }

    // Default return for empty or unhandled states
    return <div></div>;
}
