import React, { Component } from 'react';
import dayjs from 'dayjs';
import stores from '../../stores';
import { observer } from 'mobx-react';
import DataTable from '../../components/DataTable/DataTable';
import ParkingEmployeeName from '../Parkings/ParkingsComponents/ParkingEmployeeName';
import { deleteIcon } from '../../assets';
import moment from "moment";


const { bookingsStore , userStore} = stores;

@observer
class Bookings extends Component {
  render() {
    const tableColumns = [
      {
        Header: 'Employee Name',
        accessor: 'bookedFor',
        Cell: ParkingEmployeeName,
      },
			{
        Header: userStore.currentUser.company.isWithWorkstation ? 'Workstation Name ': 'Parking Name',
        accessor: 'parking.name',
      },{
					Header: userStore.currentUser.company.isWithWorkstation ? 'Location': 'Parking lot',
					accessor:'parking.park.name'
			},
			{
        Header: 'Note',
        accessor: 'note',
      },
			{
        Header: 'Start Date',
        accessor: 'startTime',
				Cell: ({ value }) => (new Date(value).getHours()===0 || new Date(value).getHours()===3) ? (
					<span>{ moment(value).format('DD/MM/YY')}</span>
				): (
					<span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>
				),
      },
			{
        Header: 'End Date',
        accessor: 'endTime',
        Cell: ({ value }) => (new Date(value).getHours()===0 || new Date(value).getHours()===3) ? (
					<span>{ moment(value).format('DD/MM/YY')}</span>
				): (
          <span>{moment(value).format('DD/MM/YYYY HH:mm')}</span>
        ),
      },
			{
        Header: 'Booked At',
        accessor: 'createdAt',
        Cell: ({ value }) => (
          <span>{dayjs(value).format('YYYY-MM-DD')}</span>
        ),
      }, {

        Header: 'Delete',
        Cell: ({ row }) => <img src={deleteIcon} onClick={() => bookingsStore.deleteBooking(row._original._id)} />,
        sortable: false,
        filterable: false,
      },
    ];

    const {bookings} = bookingsStore;
    return (
      <div>
        <h1 className="page-title">Bookings</h1>
        <DataTable columns={tableColumns} data={bookings} />
      </div>
    );
  }
}

export default Bookings;
