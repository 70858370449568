export function generateOption(label, value) {
  return {
    label,
    value,
  };
}

export const ROUTES = {
  SPECIAL_PARKINGS: '/special-parkings',
  COMPANIES: '/companies',
  USERS: '/users',
  BRANCHES: '/branches',
  PARKING_LOTS: '/parkingLots',
  PARKINGS: '/parkings',
  LOGIN: '/login',
  EMPLOYEES: '/employees',
  GUESTS: '/guests',
  VACATIONS: '/vacations',
  REPORTS: '/reports',
  SETTINGS: '/settings',
  MY_DETAILS: '/me',
  PUSH_NOTIFICATIONS: '/sendPush',
  RESERVATIONS: '/reservations',
  BOOKINGS: '/bookings',
  LPR_DASHBOARD: '/lprDashboard',
	ADMIN_VACATIONS: '/adminVacation'
};

export const PERFORMED_BY = {
  APP: 'app',
  CRON: 'cron',
  SMS: 'sms',
  WEB: 'web',
  LPR: 'lpr',
  OCCUPIED: 'occupied',
};

export const REPORTS_NAMES = {
  GUESTS: 'guests',
  EMPLOYEES: 'employees',
  PARKINGS: 'parkings',
  EFFICIENCY: 'efficiency',
  CONSTANT_EMPLOYEE: 'constantEmployee',
  APPLICATION: 'application',
};

export const PARKING_STATUS = {
  AVAILABLE: 'available',
  DISABLED: 'disabled',
  TEMP_ENABLED: 'temp_enabled',
  TEMP_DISABLED: 'temp_disabled',
  OCCUPIED: 'occupied',
  BOOKED: 'booked',
};

export const ROLES = {
  ADMIN: 'admin',
  COMPANY: 'company',
  SECRETARY: 'secretary',
  SENIOR_SECRETARY: 'senior_secretary',
};

export const NO_PERMISSION_ERROR = 'You do not have permissions to do that';

export const COMPANY_USER = [ROLES.COMPANY, ROLES.SECRETARY, ROLES.SENIOR_SECRETARY];
export const ADMIN_USER = [ROLES.ADMIN];
export const CAR_NUMBER_EXISTS_ERROR = 'Those car number already exists in the company';
export const SAME_USER_AND_PASSWORD_EXISTS =
  'This user already exists in the system, please chose another password';
