import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { openGate } from '../../assets';
import './CamerasList.style.scss';

class Camera extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	async onOpenGateClick() {
		try {
			if (this.state.loading) return;

			this.setState({ loading: true });
			const openGateResponse = await this.props.openGate(this.props.camera);
			if (openGateResponse.status !== true) {
				alert('Error trying open gate');
			}
			else {
				alert('Gate is open now!');
			}
		} catch (err) {
			console.error('error in openGate', err);
			alert('Error trying open gate');
		} finally {
			this.setState({ loading: false });
		}
	}

	render() {
		const loadingClassName = this.state.loading ? 'loading' : '';
		const apiUrlClassName = this.props.camera.apiUrl ? 'hasApiUrl' : '';

		return (
			<div
				className={`camera-container ${apiUrlClassName} ${loadingClassName}`}
				onClick={this.onOpenGateClick.bind(this)}
			>
				<img src={openGate} style={{ width: 50 }} />
				<span>{this.props.camera.name}</span>
			</div>
		);
	}
}

const CamerasList = observer(({ cameras, openGate }) => {
	return (
		<div className="camera-list-container">
			{cameras.map((camera, index) => {
				return <Camera key={`${camera._id}_${index}`} camera={camera} openGate={openGate} />;
			})}
		</div>
	);
});

export default CamerasList;
