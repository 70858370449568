import { action, observable } from 'mobx';
import API from '../../utils/API/api';

class LogsViewStore {
  @observable autoReleaseLogsList = observable.array();

  constructor() {
    this.api = API.admin.logs;
  }

  @action
  loadArrivalLogs(fromDate, toDate, companyId) {
    return this.api.getAutoReleaseLogs(fromDate, toDate, companyId).then(action(response => {
        this.autoReleaseLogsList.replace(response.results);
      }
    ));
  }
}

export default LogsViewStore;