import { action, observable } from 'mobx';
import stores from '../index';
import { generateOption } from '../../utils/common';

class CamerasViewStore {
  @observable selectedCamera;

  @observable isEditCameraModalShown;

  @observable companiesBranches = observable.array();

  @observable companiesParks = observable.array();

  @action
  editCamera = async (camera) => {
    this.selectedCamera = camera;
    this.loadCompanyData(camera.company._id).then(result => {
			this.showEditCameraModal();
		});
  };

  @action
	loadCompanyData = (companyId) => {
		const branchesPromise = stores.branchesStore.getBranchesByCompany(companyId);
		const parksPromise = stores.parksStore.getParksByCompany(companyId);

		return Promise.all([branchesPromise, parksPromise]).then(action((results) => {
			const branches = results[0];
			const parks = results[1];
			this.companiesBranches.replace(branches);
			this.companiesParks.replace(parks.map(park => generateOption(park.name, park._id)));
		}))
		.catch(e => {
				console.error(e);
			});
	}

  @action
  newCamera = async (companyId) => {
    this.selectedCamera = null;
		this.loadCompanyData(companyId).then(action(() => {
      this.showEditCameraModal();
    }));
  };

  @action
  showEditCameraModal = () => {
    this.isEditCameraModalShown = true;
  };

  @action
  hideEditCameraModal = () => {
    this.isEditCameraModalShown = false
  };
}

export default CamerasViewStore;
