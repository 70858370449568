import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import stores from '../../../../stores';
import DatePicker from 'react-datepicker';
import EmployeesGuestSelector from '../../../../components/EmployeesGuestSelector/EmployeesGuestSelector';

const { arrivalLogsReportStore } = stores;

class ArrivalLogsFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: arrivalLogsReportStore.fromDate,
      endDate: arrivalLogsReportStore.toDate,
      employee: arrivalLogsReportStore.selectedEmployee,
    };
  }

  onSubmitForm = submittedValues => {
    arrivalLogsReportStore.setSelectedEmployee(submittedValues.employee);
    arrivalLogsReportStore.setFromDate(submittedValues.startDate);
    arrivalLogsReportStore.setToDate(submittedValues.endDate);
    this.setState({
      ...submittedValues,
    });
    this.props.onSearch(submittedValues);
  };

  render() {
    const initialValues = this.state;
    return (
      <div className="reports-filters">
        <Form
          onSubmit={this.onSubmitForm}
          initialValues={initialValues}
          render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <h3 className="text-left">Filters</h3>
                <div className="row col-sm-10 col-sm-offset-1">
                  <div className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Employee</label>
                      </div>
                      <div className="col-sm-8">
                        <Field name="employee" component={EmployeesGuestSelector} type="employee" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-2">
                        <label>From</label>
                      </div>
                      <div className="col-sm-10">
                        <Field name="startDate">
                          {({ input, meta }) => {
                            return (
                              <DatePicker
                                selected={input.value}
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                onFocus={input.onFocus}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-2">
                        <label>To</label>
                      </div>
                      <div className="col-sm-10">
                        <Field name="endDate">
                          {({ input, meta }) => {
                            return (
                              <DatePicker
                                selected={input.value}
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                onFocus={input.onFocus}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <button type="submit" disabled={submitting}>
                      Search
                    </button>
                    <button
                      type="button"
                      className="ml-1"
                      onClick={() => this.props.onExportClick(values)}
                    >
                      Export to Excel
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

ArrivalLogsFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};

ArrivalLogsFilters.defaultProps = {};

export default ArrivalLogsFilters;
