import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from '../../stores';
import '../../styles/react-table.styles.scss';
import Modal from '../../components/modal/Modal';
import { changePasswordIcon, deleteIcon, editIcon } from '../../assets';
import { toast } from 'react-toastify';
import EditGuestModal from './GuestsComponents/EditGuestModal';
import filterCarNumbers from '../../utils/filterCarNumbers';
import DataTable from '../../components/DataTable/DataTable';
import UploadFileModal from '../../components/UploadFileModal/UploadFileModal';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';

const { guestsStore, parksStore } = stores;

const CalendarContainer = ({children}) => {
	const appRoot = document.getElementById('root');

	return ReactDOM.createPortal(
		children,
		appRoot
	);
}

@withRouter
@observer
class Guests extends Component {

  onEditRow = (row) => {
    guestsStore.editGuest(row._original);
  };

  onDeleteRow = (row) => {
    const answer = window.confirm("Are you sure you want to delete this forever?");
    if (answer) {
      guestsStore.delete(row._original._id);
    }
  };

  onChangePassword = (row) => {
    const newPassword = window.prompt("New Password", "Type new password ...");
    if (newPassword !== null && newPassword !== "Type new password ...") {
      // call api to change company's user password
      guestsStore.changePassword(row._original._id, newPassword).then(response => {
        toast("Password has changed!", { type: toast.TYPE.SUCCESS });
      }).catch(err => toast(err.message, { type: toast.TYPE.ERROR }));
    }
  };

  onFileUploaded = (files) => {
    if (files.length > 0) {
      guestsStore.uploadEmployeesFile(files[0]).then(response => {
        if (response.results) {
          if (response.results.error) {
            toast(response.results.error, { type: toast.TYPE.ERROR });
          }
          else if (response.results.success) {
            toast(`Imported ${response.results.success} employees!`, { type: toast.TYPE.SUCCESS });
          }
        }
        else {
          console.error(response);
        }
      }).catch(e => {
        toast("Error while uploading Excel file", { type: toast.TYPE.ERROR });
        console.error(e);
      });
    }
  };

  render() {
    const { list, isEditModalShown, isUploadFileModalShown, hideUploadModal, onUploadExcelButtonClick, newGuest, hideEditModal, selectedGuest } = guestsStore;
    const columns = [
      {
        Header: 'Full Name',
        accessor: 'name',
        filterMethod: (filter, row) => {
          if (!row['name']) return false;
          return row['name'].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
        },
        Footer: (columnProps) => {
          return (
            <span>
            <strong>Total: </strong>{columnProps.data.length}
          </span>
          )
        },
      }, {
        Header: 'ID',
        accessor: 'customId'
      }, {
        Header: 'Username (Phone)',
        accessor: 'phone'
      }, {
        Header: 'Email',
        accessor: 'email',
        filterMethod: (filter, row) => {
          if (!row['email']) return false;
          return row['email'].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
        }
      }, {
        Header: 'Car No.',
        accessor: 'carNumbers',
        filterMethod: (filter, row) => {
          return filterCarNumbers(row['carNumbers'] || [], filter.value);
        }
      }, {
        Header: 'Start Date',
        accessor: 'startDate',
				Cell: ({ value }) => {
					if (value) {
						return dayjs(value).format('DD/MM/YYYY');
					}
					return null;
				},
				filterMethod: (filter, row) => {
        	if (!filter.value) return true;
					if (!row[filter.id]) return false;
					return dayjs(row[filter.id]).isSame(filter.value, 'date');
				},
				Filter: ({ filter, onChange }) => {
        	const val = filter ? filter.value : undefined;
        	return (
						<DatePicker
							selected={val}
							onChange={onChange}
							dateFormat="dd/MM/yyyy"
							isClearable
							popperContainer={CalendarContainer}
						/>
					)
				}
      }, {
        Header: 'End Date',
        accessor: 'endDate',
				Cell: ({ value }) => {
					if (value) {
						return dayjs(value).format('DD/MM/YYYY');
					}
					return null;
				},
				filterMethod: (filter, row) => {
					if (!filter.value) return true;
					if (!row[filter.id]) return false;
					const endDate = dayjs(filter.value).endOf('day');
					return dayjs(row[filter.id]).isSame(endDate, 'date');
				},
				Filter: ({ filter, onChange }) => {
					const val = filter ? filter.value : undefined;
					return (
						<DatePicker
							selected={val}
							onChange={onChange}
							dateFormat="dd/MM/yyyy"
							isClearable
							popperContainer={CalendarContainer}
						/>
					)
				}
      }, {
        Header: 'Change password',
        Cell: ({ row }) => <img src={changePasswordIcon} onClick={this.onChangePassword.bind(this, row)} />,
        sortable: false,
        filterable: false,
        width: 100
      },{
				Header: 'Company Name',
				accessor: 'company.name',
				filterable: false
			}, {
        Header: 'Edit',
        Cell: ({ row }) => <img src={editIcon} onClick={this.onEditRow.bind(this, row)} />,
        sortable: false,
        filterable: false,
        width: 80
      }, {
        Header: 'Delete',
        Cell: ({ row }) => <img src={deleteIcon} onClick={this.onDeleteRow.bind(this, row)} />,
        sortable: false,
        filterable: false,
        width: 80
      }
    ];
    return (
      <div className="companies-page full-height" id="calendar-portal">
        <h1 className="page-title">Guests</h1>
        <div className="actions">
          <button type="button" onClick={newGuest}>New Guest</button>
          <button type="button" onClick={onUploadExcelButtonClick}>Upload Excel</button>
        </div>
        <DataTable
          filterable
          data={list.slice()}
          columns={columns}
          defaultSorted={[
            {
              id: "name",
              desc: false
            },
          ]}
        />
        <Modal title="New Guest" show={isEditModalShown} handleClose={hideEditModal}>
          <EditGuestModal parkingLots={parksStore.getDropdownList} guest={selectedGuest}
                          closeModal={hideEditModal} />
        </Modal>
        <Modal title="Upload guests excel file" show={isUploadFileModalShown} handleClose={hideUploadModal}>
          <UploadFileModal closeModal={hideUploadModal} onFileUploaded={this.onFileUploaded} />
        </Modal>
      </div>
    );
  }
}

export default Guests;
