import BootstrapInput from '../../../../components/BootstrapInput/BootstrapInput';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import stores from '../../../../stores/index';
import DatePicker from 'react-datepicker';
import { observer } from 'mobx-react';

const { lprLogsReportStore } = stores;

@observer
class LprLogsFilters extends Component {
	onSubmitForm = submittedValues => {
		lprLogsReportStore.setFromDate(submittedValues.startDate);
		lprLogsReportStore.setToDate(submittedValues.endDate);
		lprLogsReportStore.setselectedCarNumber(submittedValues.carNumber);
		this.props.onSearch(submittedValues);
	};

	render() {
		const initialValues = {
			startDate: lprLogsReportStore.fromDate,
			endDate: lprLogsReportStore.toDate,
			carNumber: lprLogsReportStore.selectedCarNumber,
		};
		return (
			<div className="reports-filters">
				<Form
					onSubmit={this.onSubmitForm}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
						return (
							<form onSubmit={handleSubmit}>
								<h3 className="text-left">Filters</h3>
								<div className="row col-sm-10 col-sm-offset-1">
									<div className="col-sm-3">
										<div className="row">
											<div className="col-sm-5">
												<label>Car Number</label>
											</div>
											<div className="col-sm-7">
												<Field name="carNumber" component={BootstrapInput} type="text" />
											</div>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="row">
											<div className="col-sm-2">
												<label>From</label>
											</div>
											<div className="col-sm-10">
												<Field name="startDate">
													{({ input, meta }) => {
														return (
															<DatePicker
																selected={input.value}
																onChange={input.onChange}
																onBlur={input.onBlur}
																onFocus={input.onFocus}
																dateFormat="dd/MM/yyyy"
																className="form-control"
															/>
														);
													}}
												</Field>
											</div>
										</div>
									</div>
									<div className="col-sm-3">
										<div className="row">
											<div className="col-sm-2">
												<label>To</label>
											</div>
											<div className="col-sm-10">
												<Field name="endDate">
													{({ input, meta }) => {
														return (
															<DatePicker
																selected={input.value}
																onChange={input.onChange}
																onBlur={input.onBlur}
																onFocus={input.onFocus}
																dateFormat="dd/MM/yyyy"
																className="form-control"
															/>
														);
													}}
												</Field>
											</div>
										</div>
									</div>
									<div className="col-sm-3">
										<button type="submit" disabled={submitting} className="btn btn-primary">
											Search
										</button>
										<button
											type="button"
											className="btn btn-primary ml-1"
											onClick={() => this.props.onExportClick(values)}
										>
											Export to Excel
										</button>
									</div>
								</div>
							</form>
						);
					}}
				/>
			</div>
		);
	}
}

LprLogsFilters.propTypes = {
	onSearch: PropTypes.func.isRequired,
	onExportClick: PropTypes.func.isRequired,
	startDate: PropTypes.object,
	endDate: PropTypes.object,
};

export default LprLogsFilters;
