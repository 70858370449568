import { action, observable } from 'mobx';
import API from '../../utils/API/api';
import dayjs from 'dayjs';

class SmsLogsViewStore {
  @observable smsLogsList = observable.array();
  @observable monthsList = observable.array();
  @observable selectedMonth;
  @observable selectedCompany;

  constructor(stores) {
    this.api = API.admin.logs;
    this.commonStore = stores.commonStore;
    this.fillMonthsList();
  }

  fillMonthsList() {
    const current = dayjs();
    const maxMonth = dayjs().subtract(24, 'month');
    const monthsList = [];
    for (let month = dayjs(current); month.isAfter(maxMonth); month = month.subtract(1, 'month')) {
      monthsList.push({ value: month.format('YYYY-MM'), label: month.format('YYYY-MM')});
    }
    this.monthsList.replace(monthsList);
  }

  @action
  loadSmsLogs() {
    this.commonStore.setIsLoading(true);
    const selectedMonth = this.selectedMonth ? this.selectedMonth.value : undefined;
    const selectedCompany = this.selectedCompany ? this.selectedCompany.value : undefined;
    const from = dayjs(selectedMonth).startOf('month').toISOString();
    const to = dayjs(selectedMonth).endOf('month').toISOString();
    return this.api.getSmsLogs(from, to, selectedCompany)
      .then(action(response => {
        this.smsLogsList.replace(response.results);
        this.commonStore.setIsLoading(false);
      }))
      .catch(e => {
        this.commonStore.setIsLoading(false);
      });
  }

  @action
  setSelectedMonth(selectedMonth) {
    this.selectedMonth = selectedMonth;
  }

  @action
  setSelectedCompany(selectedCompany) {
    this.selectedCompany = selectedCompany;
  }
}

export default SmsLogsViewStore;
