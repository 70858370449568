import { action, computed, observable, reaction } from 'mobx';
import API from '../../utils/API/api';
import { ADMIN_USER, COMPANY_USER, ROLES } from '../../utils/common';
import stores from '../index';
import moment from 'moment';
import dayjs from 'dayjs';

class UserStore {
  @observable currentUser;
  @observable currentCompany;
  @observable loadingUser;
  @observable updatingUser;
  @observable updatingUserErrors;

  constructor(stores) {
    this.api = API.user;
    this.onCompanyUserLoaded(user => {
      stores.branchesStore.getAll();
      stores.parksStore.getAll();
    });
    this.onAdminUserLoaded(user => {
      // stores.branchesStore.getAll();
      // stores.parksStore.getAll();
    });
  }

  onUserLoaded = (callback) => {
    reaction(
      () => this.currentUser,
      (user) => callback(user)
    )
  };

  onAdminUserLoaded = (callback) => {
    reaction(
      () => this.currentUser,
      (user) => {
        if (user && ADMIN_USER.indexOf(user.type) > -1) {
          callback(user);
        }
      }
    )
  };

  onCompanyUserLoaded = (callback) => {
    reaction(
      () => this.currentUser,
      (user) => {
        if (user && COMPANY_USER.indexOf(user.type) > -1) {
          callback(user);
        }
      }
    )
  };

  @action pullUser() {
    this.loadingUser = true;
    return this.api.currentUser()
      .then(action((user) => {
        this.currentUser = user;
        this.currentCompany = user.company;
				if (user && user.company && user.company.autoReleaseTime) {
					// offset the auto release time by 10 minutes to match the real time of autorelease by the cronjob in the BE
          // this.currentCompany.autoReleaseTime = dayjs(user.company.autoReleaseTime).subtract(10, 'minute').toISOString();
          this.currentCompany.autoReleaseTime = dayjs(user.company.autoReleaseTime)
            .subtract(0, 'minute')
            .toISOString();
        }
        return this.currentUser;
      }))
      .finally(action(() => {
        this.loadingUser = false;
      }))
  }

  @action setUser(user) {
    this.currentUser = user;
    if (user) {
      this.currentCompany = user.company;
    }
  }

  @action
  updateCompanyDetails(company) {
    if (company) {
      company._id = this.currentUser.company._id;
			if (company.autoReleaseTime) {
				// offset the auto release time by 10 minutes to match the real time of autorelease by the cronjob in the BE
				// company.autoReleaseTime = dayjs(company.autoReleaseTime).add(10, 'minute').toISOString();
				company.autoReleaseTime = dayjs(company.autoReleaseTime).add(0, 'minute').toISOString();
			}
      stores.commonStore.setIsLoading(true);
      return API.company.details.updateCompanyDetails(company).then(action(() => {
        this.currentCompany = company;
        this.currentUser.company = company;
      })).finally(() => stores.commonStore.setIsLoading(false));
    }
    stores.commonStore.setIsLoading(false);
    return Promise.reject();
  }

  @action updateUser(userDetails) {
    this.updatingUser = true;
    return API.company.details.updateUserDetails(userDetails)
      .then(this.pullUser.bind(this))
      .finally(action(() => {
        this.updatingUser = false;
      }))
  }

  @action forgetUser() {
    this.currentUser = undefined;
  }

  @computed
  get isAuthenticated() {
    return this.currentUser && this.currentUser._id;
  }

  @computed
  get isAdmin() {
    return this.currentUser && this.currentUser.type === ROLES.ADMIN;
  }

  @computed
  get isSendingHoursNow() {
    if (!this.currentCompany) return false;
    const timeFormat = "HH:mm";
    const nowHour = moment().format(timeFormat);
    const now = moment(nowHour, timeFormat);

    const todayName = now.format('dddd').toLowerCase();
    const smsSendingHours = this.currentCompany.smsSendingHours;
    if (smsSendingHours && smsSendingHours[todayName]) {
      const rawSendHour = smsSendingHours[todayName].sendAt;
      const rawWaitUntilHour = smsSendingHours[todayName].waitUntil;
      if (rawSendHour && rawWaitUntilHour) {
        // extract only the hour
        const sendHour = moment(rawSendHour).format(timeFormat);
        const waitUntilHour = moment(rawWaitUntilHour).format(timeFormat);

        const sendDate = moment(sendHour, timeFormat);
        const waitUntilDate = moment(waitUntilHour, timeFormat);
        return now.valueOf() >= sendDate.valueOf() && now.valueOf() < waitUntilDate.valueOf();
      }
    }
    return false;
  }

  @action
  changePassword(currentPassword, newPassword) {
    return this.api.changePassword(currentPassword, newPassword);
  }
}

export default UserStore;
