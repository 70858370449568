import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from '../../stores';
import './login.style.scss';
import Modal from '../../components/modal/Modal';
import ForgotPasswordModal from './LoginComponents/ForgotPasswordModal';
import { DPMlogo, logo } from '../../assets';
import { ROLES, ROUTES } from '../../utils/common';

@withRouter
@observer
class Login extends Component {
  componentWillMount() {
    document.body.classList.add('loginBg');
  }

  componentWillUnmount() {
    stores.authStore.reset();
    document.body.classList.remove('loginBg');
  }

  handleUsernameChange = e => stores.authStore.setUsername(e.target.value);
  handlePasswordChange = e => stores.authStore.setPassword(e.target.value);

  handleSubmitForm = e => {
    console.time('loginSpeed');
    e.preventDefault();
    stores.commonStore.setIsLoading(true);
    stores.authStore
      .login()
      .then(user => {
        stores.commonStore.setIsLoading(false);
        if (user && user.type && user.type === ROLES.ADMIN) {
          this.props.history.replace(ROUTES.COMPANIES);
        } else {
          if (user && user.company && user.company.isSpecial) {
            this.props.history.replace(ROUTES.SPECIAL_PARKINGS);
          } else if (user && user.company && user.company.isUsingLightmode || user.company.isManagementCompany) {
            this.props.history.replace(ROUTES.LPR_DASHBOARD);
          } else {
            this.props.history.replace(ROUTES.PARKINGS);
          }
        }
        console.timeEnd('loginSpeed');
      })
      .catch(response => {
        stores.commonStore.setIsLoading(false);
        if (response.status === 401) {
          alert('Username or password are incorrect');
        } else {
          console.log(response);
        }
        console.timeEnd('loginSpeed');
      });
  };

  render() {
    const {
      values,
      errors,
      inProgress,
      isForgotPasswordModalShown,
      hideForgotPassword,
      showForgotPassword,
    } = stores.authStore;
    if (stores.userStore.isAdmin) {
      return <Redirect to={ROUTES.COMPANIES} />;
    } else if (stores.userStore.isAuthenticated) {
      return <Redirect to={ROUTES.PARKINGS} />;
    }
    return (
      <div className="auth-page">
				<div className="col d-flex align-items-center justify-content-center">
          <div className="col-sm-6" >
            <form className="login-box" onSubmit={this.handleSubmitForm}>
              <div className="login-logo">
                <a href="http://www.dpm-parking.com" rel="noopener noreferrer" target="_blank">
                  <img src={logo} width="450" />
                </a>
              </div>
              <div className="row center-xs middle-xs">
                {/*<label>Username:</label>*/}
                <input
                  autoFocus="autofocus"
                  className="textbox"
                  type="text"
                  name="txtUserName"
                  autoComplete="on"
                  placeholder="Username"
                  value={values.email}
                  onChange={this.handleUsernameChange}
                />
              </div>

              <div className="row center-xs middle-xs">
                {/*<label>Password:</label>*/}
                <input
                  className="textbox"
                  type="password"
                  name="txtPass"
                  value={values.password}
                  onChange={this.handlePasswordChange}
                  placeholder="Password"
                />
              </div>
              <div className="row center-xs middle-xs">
                <a onClick={() => showForgotPassword()}>Forgot Password?</a>
              </div>

              <div>
                <button type="submit" disabled={inProgress}>
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
        <Modal
          title="Forgot Password"
          show={isForgotPasswordModalShown}
          handleClose={hideForgotPassword}
        >
          <ForgotPasswordModal closeModal={hideForgotPassword} />
        </Modal>
      </div>
    );
  }
}

export default Login;
