import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import stores from '../../stores';
import { observer } from 'mobx-react';

const {userStore } = stores;

@observer
class ReportsIndex extends Component {
  render() {
    const {currentCompany, currentUser} = userStore;
    return (
      <div>
        <h1 className="page-title">Reports</h1>
        <div className="d-flex align-items-center flex-column">
          {(!currentCompany.isUsingLightmode) && <div className="col-sm-2 mb-2">
            <Link to="/reports/guests">
              <button className="btn-block">Guests</button>
            </Link>
          </div>}
          {(!currentCompany.isUsingLightmode) && <div className="col-sm-2 mb-2">
            <Link to="/reports/employees">
              <button className="btn-block">Employees</button>
            </Link>
          </div>}
          {(!currentCompany.isUsingLightmode) && <div className="col-sm-2 mb-2">
            <Link to="/reports/parkings">
              <button className="btn-block">{currentUser.company.isWithWorkstation ? 'Workstations' : 'Parkings'}</button>
            </Link>
          </div>}
          {(!currentCompany.isUsingLightmode) && <div className="col-sm-2 mb-2">
            <Link to="/reports/constantEmployee">
              <button className="btn-block">Constant Employee</button>
            </Link>
          </div>}
          {(!currentCompany.isUsingLightmode) && <div className="col-sm-2 mb-2">
            <Link to="/reports/efficiency">
              <button className="btn-block">Efficiency</button>
            </Link>
          </div>}
          {(!currentCompany.isUsingLightmode) && <div className="col-sm-2 mb-2">
            <Link to="/reports/arrivalLogs">
              <button className="btn-block">Arrivals</button>
            </Link>
          </div>}
          {(!currentCompany.isUsingLightmode) && currentCompany.isWithReporting && <div className="col-sm-2 mb-2">
            <Link to="/reports/application">
              <button className="btn-block">Application Reports</button>
            </Link>
          </div>}
					{currentCompany.isUsingDpmLpr && (
					<>
						<div className="col-sm-2 mb-2">
							<Link to="/reports/lprlogs">
								<button className="btn-block">LPR Logs</button>
							</Link>
						</div>
						<div className="col-sm-2 mb-2">
							<Link to="/reports/lprefficiency">
								<button className="btn-block">LPR Efficiency</button>
							</Link>
						</div>
					</>
					)}
          {
            currentCompany.isUsingDpmLpr && currentCompany.isManagementCompany &&
            <div className="col-sm-2 mb-2">
							<Link to="/reports/lprlogsNotUsers">
								<button className="btn-block">LPR Logs Not Users</button>
							</Link>
						</div>
          }
        </div>
      </div>
    );
  }
}

export default ReportsIndex;
