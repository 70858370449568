	import React, { Component } from 'react';
	import PropTypes from 'prop-types';
	import { Field, Form } from 'react-final-form';
	import arrayMutators from 'final-form-arrays';
	import { FieldArray } from 'react-final-form-arrays';
	import stores from '../../../stores';
	import Error from '../../../components/forms/Error';
	import Employee, { employeeSerializer } from '../../../models/Employee';
	import BootstrapInput from '../../../components/BootstrapInput/BootstrapInput';
	import SmsByDaysComponent from './smsByDaysComponent'
	import SmsByDaysComponentWorkstation from './smsByDaysComponentWorkstation';
	import Select from "react-select";
	import { toJS } from 'mobx';
	import { toast } from 'react-toastify';
	import { log } from '../../../utils/log'

	const { employeesStore} = stores;

	class EditEmployeeModal extends Component {
		constructor(props) {
			super(props);
			this.state = {
				smsByDaysData: [],
				employeeDefaultValues:{}
			};
			this.isOpen = React.createRef();
			this.smsByDaysComponentRef = React.createRef();  // Create a ref to SmsByDaysComponentWorkstation
		}

		handleSmsByDaysData = (data) => {
			this.setState({ smsByDaysData: data});
			this.isOpen.current = true;
		}

		onCompanySelected = (option) => {
			stores.userStore.currentUser.company.managedCompanies = option;
		}

		updateSmsByDaysData(smsByDaysData) {
			const defaultDays = {
				Sunday: { isChecked: false, parking: null },
				Monday: { isChecked: false, parking: null },
				Tuesday: { isChecked: false, parking: null },
				Wednesday: { isChecked: false, parking: null },
				Thursday: { isChecked: false, parking: null },
				Friday: { isChecked: false, parking: null },
				Saturday: { isChecked: false, parking: null },
			};

			if (!smsByDaysData || !Object.keys(smsByDaysData).length) {
				return defaultDays;
			}

			// Iterate over the days and update the data
			Object.keys(smsByDaysData).forEach(day => {
				const dayData = smsByDaysData[day];
				if (dayData.isChecked && !dayData.parking) {
					dayData.isChecked = false;
				}
			});

			return smsByDaysData;
		}

		onSubmitForm = (submittedValues) => {
			
			const  { company } = stores.userStore.currentUser
			const isWorkstation = company.isWithWorkstation &&
				company.isWithWorkstationMultiple ||
				false

			let smsByDaysData = isWorkstation ?
				this.smsByDaysComponentRef.current.getCurrentDaysData() :
				{};

			smsByDaysData = this.updateSmsByDaysData(smsByDaysData)

			submittedValues.shouldSentSMS = this.isOpen.current
			const employee = { ...employeeSerializer({ ...submittedValues }), smsByDays: smsByDaysData };
			
			// eslint-disable-next-line no-unused-expressions
			company.isManagementCompany ? Object.assign(employee, { ...{ managedCompanies: company.managedCompanies } }) : null;
			const isEditMode = this.props.employee && this.props.employee._id;
			const obj = { ...employee, isWorkstation, employee: toJS(employeesStore.selectedEmployee), company: company._id }

			if (!isEditMode) {
				stores.employeesStore
					.create(obj)
					.then(response => {
						toast(`Successfully created employee: ${employee.name}` ||
							'Successfully saved employee.',
							{ type: toast.TYPE.SUCCESS });
						this.props.closeModal();
					})
					.catch(err => {
						log && console.error(err);
						toast(err.message || 'Error saving employee.',{type: toast.TYPE.WARNING});
						toast(`Check your details and try again.`, {type: toast.TYPE.INFO});
					});
			} else {
				stores.employeesStore
					.update(obj)
					.then(response => {
						toast(`Successfully edited ${employee.name}` || 'Successfully edited employee.',
							{ type: toast.TYPE.SUCCESS });
						this.props.closeModal();
					})
					.catch(err => {
						log && console.error(err);
						toast(err ? err.message : 'Error saving employee.', {type: toast.TYPE.WARNING});
						toast(`Check your details and try again.`, {type: toast.TYPE.WARNING});
					});
			}
		};

		validateForm = values => {
			const errors = {};
			if (!values.name) {
				errors.name = 'Employee name is required';
			}
			if (!values.phone) {
				errors.phone = 'Username is required';
			}
			if (!values.password && values._id === undefined) {
				errors.password = 'Password is required';
			}
			return errors;
		};

		componentDidMount(){
			log && console.log('selectedEmployee',toJS(employeesStore.selectedEmployee) )
			this.setState({employeeDefaultValues:toJS(employeesStore.selectedEmployee)})
		}

		render() {
			const employee = toJS(employeesStore.selectedEmployee) || {};
			employee.smsByDays = {...(employee.smsByDays || {}),...this.state.smsByDaysData};
			const defaultValues = new Employee( employee || undefined);

			const isEditMode = defaultValues._id !== undefined;
			const { parkingLots } = this.props;
			
			const currentUser = stores.userStore.currentUser;
			if (!isEditMode) {
				defaultValues.allowedParks = stores.userStore.currentUser.company.isManagementCompany ? [] : parkingLots.map(p => p.value);
			}

			const shouldTranslate = this.props.displayLanguageModel === 'he';

			const labels = {
				fullName: shouldTranslate ? 'שם מלא:*' : 'Full Name*:',
				username: shouldTranslate ? 'שם משתמש (מספר טלפון):*' : 'Username (phone number)*:',
				password: shouldTranslate ? 'סיסמא:*' : 'Password*:',
				email: shouldTranslate ? 'אימייל:' : 'Email:',
				customId: shouldTranslate ? 'מספר זיהוי:' : 'ID:',
				holdRange: shouldTranslate ? 'טווח החזקה (במטרים):' : 'Hold Range (in meters):',
				carNumbers: shouldTranslate ? 'מספרי רכב:' : 'Car numbers:',
				addCarNumber: shouldTranslate ? 'הוסף מספר רכב' : 'Add Car number',
				managedCompanies: shouldTranslate ? 'חברות מנוהלות:' : 'Managed Companies:',
				parkingLots: shouldTranslate ? 'חניונים:' : (currentUser.company.isWithWorkstation ? 'Locations:' : 'Parking Lots:'),
				carsLimit: shouldTranslate ? 'הגבלת מכוניות:' : 'Cars Limit:',
				active: shouldTranslate ? 'פעיל' : 'Active',
				changePassword: shouldTranslate ? 'צריך לשנות סיסמא לאחר התחברות' : 'Should change password after login',
				sendSms: shouldTranslate ? 'שלח סמס לעובד קבוע?' : 'Send SMS to constant employee?',
				saveButton: shouldTranslate ? 'שמור' : 'Save'
			};
			
			const { 
				isWithSendingSMSForArrival = false, 
				isWithSendingSMSForArrivalByDays = false ,
				isWithWorkstation = false,
				isWithWorkstationMultiple = false
			} = stores.userStore.currentUser.company;
			
			const flag = isWithWorkstation && isWithWorkstationMultiple || false

			const that = this;
			
			return (
				<Form
					onSubmit={this.onSubmitForm.bind(this)}
					mutators={arrayMutators }
					initialValues={defaultValues}
					validate={this.validateForm}
					render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
						if (values.shouldSentSMS) {
							that.isOpen.current = true;
						}
					
						return (
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col">{labels.fullName}</div>
									<div className="col">
										<Field name="name" component={BootstrapInput} type="text" />
										<Error name="name" />
									</div>
								</div>
								<div className="row">
									<div className="col">{labels.username}</div>
									<div className="col">
										<Field name="phone" component={BootstrapInput} type="text" />
										<Error name="phone" />
									</div>
								</div>
								{!isEditMode && (
									<div className="row">
										<div className="col">{labels.password}</div>
										<div className="col">
											<Field name="password" component={BootstrapInput} type="text" />
											<Error name="password" />
										</div>
									</div>
								)}
								<div className="row">
									<div className="col">{labels.email}</div>
									<div className="col">
										<Field name="email" component={BootstrapInput} type="text" />
										<Error name="email" />
									</div>
								</div>
								<div className="row">
									<div className="col">{labels.customId}</div>
									<div className="col">
										<Field name="customId" component={BootstrapInput} type="text" />
										<Error name="customId" />
									</div>
								</div>
								<div className="row">
									<div className="col">{labels.holdRange}</div>
									<div className="col">
										<Field name="holdRange" component={BootstrapInput} type="number" />
										<Error name="holdRange" />
									</div>
								</div>
								<div className="row">
									<div className="col">{labels.carNumbers}</div>
									<div className="col">
										<button type="button" onClick={() => form.mutators.push('carNumbers', undefined)}>
											{labels.addCarNumber}
										</button>
										<FieldArray name="carNumbers">
											{({ fields }) => {
												return fields.map((name, index) => (
													<div key={name} className="row">
														<div className="col-sm-3">
															<label>{`Car #${index + 1}`}</label>
														</div>
														<div className="col-sm-8">
															<Field
																name={`${name}`}
																component={BootstrapInput}
																placeholder="12345678"
															/>
														</div>
														<div className="col-sm-1">
															<span
																onClick={() => fields.remove(index)}
																style={{ cursor: 'pointer' }}
															>
																&times;
															</span>
														</div>
														<Error name={`carNumbers[${index}]`} />
													</div>
												));
											}}
										</FieldArray>
									</div>
								</div>
								<div className="row">
									{currentUser.company.isManagementCompany && (
										<>
											<div className="col">
												<label htmlFor="managedCompanies">{labels.managedCompanies}</label>
											</div>
											<div className="col">
												<Field name="managedCompanies" id="managedCompanies">
													{({ input, meta }) => (
														<Select
															className="basic-single"
															classNamePrefix="select"
															id="managedCompanies"
															isMulti
															defaultValue={null}
															onChange={this.onCompanySelected}
															options={stores.userStore.currentUser.company.managedCompanies}
														/>
													)}
												</Field>
											</div>
										</>
									)}
								</div>
								<div className="row">
									<div className="col">{labels.parkingLots}</div>
									<div className="col">
										<Field name="allowedParks">
											{({ input, meta }) => {
												const onChange = parkId => {
													const idx = input.value.indexOf(parkId);
													if (idx > -1) {
														input.onChange([
															...input.value.slice(0, idx),
															...input.value.slice(idx + 1, input.value.length),
														]);
													} else {
														input.onChange([...input.value, parkId]);
													}
												};
												return (
													<>
														{parkingLots.map(park => (
															<div className="form-group row" key={park.value}>
																<div className="col-sm-12">
																	<div className="form-check">
																		<input
																			className="form-check-input"
																			type="checkbox"
																			onChange={() => onChange(park.value)}
																			id={park.value}
																			value={park.value}
																			checked={input.value.includes(park.value)}
																		/>
																		<label className="form-check-label" htmlFor={park.value}>
																			{park.label}
																		</label>
																	</div>
																</div>
															</div>
														))}
													</>
												);
											}}
										</Field>
										<Error name="allowedParks" />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<label htmlFor="maxConcurrentCars">{labels.carsLimit}</label>
									</div>
									<div className="col">
										<Field
											name="maxConcurrentCars"
											component={BootstrapInput}
											type="number"
											id="maxConcurrentCars"
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<label htmlFor="isActive">{labels.active}</label>
									</div>
									<div className="col">
										<Field name="isActive" component={BootstrapInput} type="checkbox" id="isActive" />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<label htmlFor="shouldChangePasswordAfterLogin">{labels.changePassword}</label>
									</div>
									<div className="col">
										<Field
											name="shouldChangePasswordAfterLogin"
											component={BootstrapInput}
											type="checkbox"
											id="shouldChangePasswordAfterLogin"
										/>
									</div>
								</div>

								{/* shouldSentSMS */}
								<div className="row">
									<div className="col">
										<label htmlFor="shouldSendSms">{labels.sendSms}</label>
									</div>
									<div className="col">
										<input
											type="checkbox"
											id="shouldSentSMS"
											name="shouldSentSMS"
											checked={ that.isOpen.current }
											onChange={() => {
												that.isOpen.current = !that.isOpen.current
												form.change('shouldSentSMS', !values.shouldSentSMS)
											}}
										/>
										<Error name="shouldSentSMS" />
									</div>
								</div>

								{
									that.isOpen.current && (
										<div className="row" >
											<div className="col">
												{
													!isWithSendingSMSForArrival ? null : (
														isWithSendingSMSForArrivalByDays && !flag ? (
															<SmsByDaysComponent />
														) : (
															isWithSendingSMSForArrivalByDays && flag && (
																<SmsByDaysComponentWorkstation
																	handleSmsByDaysData={this.handleSmsByDaysData}
																	days={values.smsByDays}
																	ref={this.smsByDaysComponentRef}
																/>
															)
														)
													)
												}
											</div>
										</div>
									)
								}

								<button type="submit" disabled={isEditMode ? !values.name : !values.password || !values.name} style={{ cursor: isEditMode ? !values.name : !values.password || !values.name ? "not-allowed" : "pointer" }}>
									{shouldTranslate ? 'שמור' : 'Save'}
								</button>
							</form>
						);
					}}
				/>
			);
		}
	}

	EditEmployeeModal.propTypes = {
		closeModal: PropTypes.func.isRequired,
		employee: PropTypes.object,
		parkingLots: PropTypes.array.isRequired,
		displayLanguageModel : PropTypes.string.isRequired
	};

	EditEmployeeModal.defaultProps = {
		employee: undefined,
	};

	export default EditEmployeeModal;
