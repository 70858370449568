// import React, { Component } from 'react';
// import { Field } from 'react-final-form';
// import Select from 'react-select';
// import BootstrapInput from "../../../components/BootstrapInput/BootstrapInput";
// import Error from '../../../components/forms/Error';
// import stores from '../../../stores';
// import { observer } from 'mobx-react';
// import { toJS } from 'mobx';

// const { parkingsStore } = stores;

// @observer
// class SmsByDaysComponentWorkstation extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			days: this.initializeDays(props.days),
// 			parkings: [],
// 			errors: {},
// 		};
// 	}

// 	initializeDays(days) {
// 		const defaultDays = {
// 			Sunday: { isChecked: false, parking: null },
// 			Monday: { isChecked: false, parking: null },
// 			Tuesday: { isChecked: false, parking: null },
// 			Wednesday: { isChecked: false, parking: null },
// 			Thursday: { isChecked: false, parking: null },
// 			Friday: { isChecked: false, parking: null },
// 			Saturday: { isChecked: false, parking: null },
// 		};
// 		return JSON.stringify(days) !== "{}" ? { ...defaultDays, ...days } : defaultDays;
// 	}

// 	componentDidMount() {
// 		parkingsStore.getAll(true).then(() => {
// 			this.setState({ parkings: toJS(parkingsStore.list) });
// 		});
// 	}

// 	componentDidUpdate(prevProps) {
// 		if (JSON.stringify(this.props.days) !== JSON.stringify(prevProps.days)) {
// 			this.setState({ days: this.initializeDays(this.props.days) });
// 		}
// 	}

// 	handleCheckboxChange = (day, input) => {
// 		const { days, errors } = this.state;
// 		const isCurrentlyChecked = days[day].isChecked;
// 		const newDays = {
// 			...days,
// 			[day]: {
// 				...days[day],
// 				isChecked: !isCurrentlyChecked,
// 				parking: isCurrentlyChecked ? null : days[day].parking,
// 			},
// 		};

// 		this.setState({
// 			days: newDays,
// 			errors: {
// 				...errors,
// 				[day]: null,
// 			},
// 		});
// 	};

// 	handleParkingChange = (day, selectedParking, input) => {
// 		const { days, errors } = this.state;

// 		this.setState({
// 			days: {
// 				...days,
// 				[day]: {
// 					...days[day],
// 					parking: selectedParking,
// 				},
// 			},
// 			errors: {
// 				...errors,
// 				[day]: selectedParking ? null : 'Please select a parking',
// 			},
// 		});
// 	};

// 	getDaysData = () => {
// 		const { days } = this.state;
// 		const newErrors = {};
// 		Object.entries(days).forEach(([day, data]) => {
// 			if (data.isChecked && !data.parking) {
// 				newErrors[day] = 'Please select a parking';
// 			}
// 		});
// 		this.setState({ errors: newErrors });
// 		return days;
// 	};

// 	renderDayField(day) {
// 		const { days, parkings, errors } = this.state;
// 		if (!days[day]) {
// 			return null;
// 		}

// 		return (
// 			<div className="row align-items-center" key={day} style={{ marginBottom: '15px' }}>
// 				<div className="col">
// 					<label htmlFor={day} style={{ marginRight: '10px' }}>
// 						{day}
// 					</label>
// 				</div>
// 				<div className="col">
// 					<Field name={`${day}Checked`} type="checkbox">
// 						{({ input, meta }) => (
// 							<>
// 								<input
// 									{...input}
// 									id={day}
// 									type="checkbox"
// 									checked={days[day].isChecked}
// 									onChange={() => this.handleCheckboxChange(day, input)}
// 									style={{ marginRight: '10px' }}
// 								/>
// 								<Error meta={meta} />
// 							</>
// 						)}
// 					</Field>
// 				</div>
// 				<div className="col">
// 					{days[day].isChecked && (
// 						<>
// 							<Field name={`${day}Company`} component={BootstrapInput}>
// 								{({ input, meta }) => (
// 									<>
// 										<Select
// 											className="basic-single"
// 											classNamePrefix="select"
// 											{...input}
// 											value={days[day].parking}
// 											onChange={(selectedParking) => this.handleParkingChange(day, selectedParking, input)}
// 											options={parkings.map((parking) => ({
// 												label: parking.name,
// 												value: parking._id,
// 											}))}
// 											isSearchable={true}
// 											filterOption={(option, inputValue) => {
// 												return option.label.toLowerCase().includes(inputValue.toLowerCase());
// 											}}
// 										/>
// 										<Error meta={meta} />
// 									</>
// 								)}
// 							</Field>
// 							{errors[day] && <span style={{ color: 'red' }}>{errors[day]}</span>}
// 						</>
// 					)}
// 				</div>
// 			</div>
// 		);
// 	}

// 	render() {
// 		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
// 		return <div>{daysOfWeek.map((day) => this.renderDayField(day))}</div>;
// 	}
// }

// export default SmsByDaysComponentWorkstation;


import React, { Component } from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';
import BootstrapInput from "../../../components/BootstrapInput/BootstrapInput";
import Error from '../../../components/forms/Error';
import stores from '../../../stores';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

const { parkingsStore } = stores;

@observer
class SmsByDaysComponentWorkstation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			days: this.initializeDays(props.days),
			parkings: [],
			errors: {},
		};
	}

	initializeDays(days) {
		const defaultDays = {
			Sunday: { isChecked: false, parking: null },
			Monday: { isChecked: false, parking: null },
			Tuesday: { isChecked: false, parking: null },
			Wednesday: { isChecked: false, parking: null },
			Thursday: { isChecked: false, parking: null },
			Friday: { isChecked: false, parking: null },
			Saturday: { isChecked: false, parking: null },
		};
		return JSON.stringify(days) !== "{}" ? { ...defaultDays, ...days } : defaultDays;
	}

	componentDidMount() {
		parkingsStore.getAll(true).then(() => {
			this.setState({ parkings: toJS(parkingsStore.list) });
		});
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(this.props.days) !== JSON.stringify(prevProps.days)) {
			this.setState({ days: this.initializeDays(this.props.days) });
		}
	}

	handleCheckboxChange = (day, input) => {
		const { days, errors } = this.state;
		const isCurrentlyChecked = days[day].isChecked;
		const newDays = {
			...days,
			[day]: {
				...days[day],
				isChecked: !isCurrentlyChecked,
				parking: isCurrentlyChecked ? null : days[day].parking,
			},
		};

		this.setState({
			days: newDays,
			errors: {
				...errors,
				[day]: null,
			},
		});
	};

	handleParkingChange = (day, selectedParking, input) => {
		const { days, errors } = this.state;

		this.setState({
			days: {
				...days,
				[day]: {
					...days[day],
					parking: selectedParking,
				},
			},
			errors: {
				...errors,
				[day]: selectedParking ? null : 'Please select a parking',
			},
		});
	};

	// New method to retrieve the current state of days
	getCurrentDaysData = () => {
		return this.state.days;
	};

	renderDayField(day) {
		const { days, parkings, errors } = this.state;
		if (!days[day]) {
			return null;
		}

		return (
			<div className="row align-items-center" key={day} style={{ marginBottom: '15px' }}>
				<div className="col">
					<label htmlFor={day} style={{ marginRight: '10px' }}>
						{day}
					</label>
				</div>
				<div className="col">
					<Field name={`${day}Checked`} type="checkbox">
						{({ input, meta }) => (
							<>
								<input
									{...input}
									id={day}
									type="checkbox"
									checked={days[day].isChecked}
									onChange={() => this.handleCheckboxChange(day, input)}
									style={{ marginRight: '10px' }}
								/>
								<Error meta={meta} />
							</>
						)}
					</Field>
				</div>
				<div className="col">
					{days[day].isChecked && (
						<>
							<Field name={`${day}Company`} component={BootstrapInput}>
								{({ input, meta }) => (
									<>
										<Select
											className="basic-single"
											classNamePrefix="select"
											{...input}
											value={days[day].parking}
											onChange={(selectedParking) => this.handleParkingChange(day, selectedParking, input)}
											options={parkings.map((parking) => ({
												label: parking.name,
												value: parking._id,
											}))}
											isSearchable={true}
											filterOption={(option, inputValue) => {
												return option.label.toLowerCase().includes(inputValue.toLowerCase());
											}}
										/>
										<Error meta={meta} />
									</>
								)}
							</Field>
							{errors[day] && <span style={{ color: 'red' }}>{errors[day]}</span>}
						</>
					)}
				</div>
			</div>
		);
	}

	render() {
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		return <div>{daysOfWeek.map((day) => this.renderDayField(day))}</div>;
	}
}

export default SmsByDaysComponentWorkstation;
