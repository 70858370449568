export const defaultBranchObject = {
  name: '',
  company: ''
};

class Branch {
  constructor(rawBranch = defaultBranchObject) {
    this._id = rawBranch._id;
    this.name = rawBranch.name;
    this.company = rawBranch.company !== '' && rawBranch.company._id ? rawBranch.company._id : rawBranch.company;
  }
}

export default Branch;