import { PARKING_STATUS } from '../utils/common';

export const AVAILABILITY = {
	ENABLED: {
		type: PARKING_STATUS.AVAILABLE,
	},
	DISABLED: {
		type: PARKING_STATUS.DISABLED,
	},
	TEMP_ENABLED: {
		type: PARKING_STATUS.TEMP_ENABLED,
	},
	TEMP_DISABLED: {
		type: PARKING_STATUS.TEMP_DISABLED,
	},
	OCCUPIED: {
		type: PARKING_STATUS.OCCUPIED,
	},
	BOOKED: {
		type: PARKING_STATUS.BOOKED,
	},
};

const defaultParkingObject = {
	_id: undefined,
	name: '',
	park: undefined,
	company: undefined,
	employee: '',
	internalParking: undefined,
	externalParking: undefined,
	parkingPartner: undefined,
	isAvailable: true,
	virtualParking: [],
	status: AVAILABILITY.ENABLED,
	vacation: {},
	booking: {},
	preference: 0,
	blockedBy: undefined,
	isWithWorkstation:false
};

export default class Parking {
	constructor(rawParking = defaultParkingObject) {
		this._id = rawParking._id;
		this.employee = rawParking.employee; // && rawParking.employee._id ? { _id: rawParking.employee._id } : undefined;
		this.name = rawParking.name;
		this.park = rawParking.park;
		this.isAvailable = rawParking.isAvailable;
		this.company = rawParking.company;
		this.internalParking =
			(rawParking.internalParking && rawParking.internalParking._id) || rawParking.internalParking;
		this.externalParking =
			(rawParking.externalParking && rawParking.externalParking._id) || rawParking.externalParking;
		this.parkingPartner =
			(rawParking.parkingPartner && rawParking.parkingPartner._id) || rawParking.parkingPartner;
		this.virtualParking =
			rawParking.virtualParking &&
			rawParking.virtualParking
				.filter(p => p !== undefined)
				.map(p => (typeof p === 'string' ? p : p._id));
		this.status = rawParking.status;
		this.unavailableStatus = rawParking.unavailableStatus;
		// this.vacation = rawParking.status.type !== AVAILABILITY.TEMP_ENABLED ? undefined : rawParking.vacation;
		this.vacation = rawParking.vacation;
		this.booking =
			rawParking.booking && Array.isArray(rawParking.booking) && rawParking.booking.length > 0
				? rawParking.booking[0]
				: rawParking.booking
				? rawParking.booking
				: {};
		this.preference = rawParking.preference;
		this.blockedBy = rawParking.blockedBy;
		this.employeeTodayResponse = rawParking.employeeTodayResponse;
		this.isWithWorkstation = rawParking.isWithWorkstation
	}
}
