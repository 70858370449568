import { action, observable } from 'mobx';
import API from '../../utils/API/api';

class CamerasStore {

  @observable camerasList = observable.array();

  constructor() {
    this.api = API.admin.cameras;
  }

  @action
  getCamerasByCompany(companyId) {
    return this.api.getByCompanyId(companyId).then(action(response => {
      this.camerasList.replace(response.results);
    }));
  }

  @action
  createCamera(camera) {
    return this.api.create(camera).then(action(response => {
      if (response.results && response.results._id) {
        this.camerasList.push(response.results);
      }
    }));
  }

  @action
  updateCamera(camera) {
    return this.api.update(camera).then(action(response => {
      if (response.results && response.results._id) {
        const idx = this.camerasList.findIndex(c => c._id === camera._id);
        this.camerasList[idx] = response.results;
      }
    }));
  }

  @action
  deleteCamera(cameraId) {
    return this.api.delete(cameraId).then(action(response => {
      const idx = this.camerasList.findIndex(c => c._id === cameraId);
      this.camerasList.splice(idx, 1);
    }));
  }

}

export default CamerasStore;
