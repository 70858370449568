// import React from 'react';
// import dayjs from 'dayjs';
// import { PARKING_STATUS } from '../../../utils/common';

// export default function ParkingVacationCell({ row }) {

//   if ((row.status || {}).type === PARKING_STATUS.TEMP_DISABLED) {
//     if ((row.status || {}).startTime && (row.status || {}).endTime) {
//       if (!row.isAvailable || row.isAvailable) {
//         const from = dayjs(row.status.startTime).format('DD/MM/YYYY HH:mm');
//         const to = dayjs(row.status.endTime).format('DD/MM/YYYY HH:mm');
//         return <>
//           <p><strong>Temp Disabled</strong></p>
//           <p className="font-size-12"><strong>From:</strong> {from}</p>
//           <p className="font-size-12"><strong>To:</strong> {to}</p>
//         </>
//       }
//     }
//   }
//   else if (row.vacation && Array.isArray(row.vacation) && row.vacation.length > 0 ) {
//     // If vacation is an array, iterate over each vacation period
//     return row.vacation.map((vacation, index) => {
//       if(!vacation) return;

//       if (vacation.startTime && vacation.endTime) {
//         const from = dayjs(vacation.startTime).format('DD/MM/YYYY HH:mm');
//         const to = dayjs(vacation.endTime).format('DD/MM/YYYY HH:mm');
//         const comments = vacation.comments && vacation.comments.toLowerCase().indexOf('one day') > -1;

//         return (
//           <div key={index}>
//             <strong>{comments ? 'Absence' : 'Vacation'}</strong>
//             <div className="font-size-12">
//               <strong>From: </strong>
//               <span>{from}</span>
//             </div>
//             <div className="font-size-12">
//               <strong>To: </strong>
//               <span>{to}</span>
//             </div>
//           </div>
//         );
//       }
//       return null;
//     });
//   } else if (row.vacation && row.vacation.startTime && row.vacation.endTime) {
//     const from = dayjs(row.vacation.startTime).format('DD/MM/YYYY HH:mm');
//     const to = dayjs(row.vacation.endTime).format('DD/MM/YYYY HH:mm');
//     const comments = row.vacation.comments && row.vacation.comments.toLowerCase().indexOf('one day') > -1;
//     return (
//       <div>
//         <strong>{comments && `Absence` || 'Vacation'}</strong>
//         <div className="font-size-12">
//           <strong>From: </strong>
//           <span>{from}</span>
//         </div>
//         <div className="font-size-12">
//           <strong>To: </strong>
//           <span>{to}</span>
//         </div>
//       </div>
//     )
//   }
//   else if (row.booking && row.booking.startTime && row.booking.endTime) {
//     const from = (dayjs(row.booking.startTime).hour() == 0 || dayjs(row.booking.startTime).hour() == 3) ? dayjs(row.booking.startTime).format('DD/MM/YYYY') : dayjs(row.booking.startTime).format('DD/MM/YYYY HH:mm');
//     const to = (dayjs(row.booking.startTime).hour() == 0 || dayjs(row.booking.startTime).hour() == 3) ? dayjs(row.booking.endTime).format('DD/MM/YYYY') : dayjs(row.booking.endTime).format('DD/MM/YYYY HH:mm');
//     return (
//       <>
//         <p><strong>Booked for:</strong> {row.booking.bookedFor.name}</p>
//         <p className="font-size-12"><strong>From:</strong> {from}</p>
//         <p className="font-size-12"><strong>To:</strong> {to}</p>
//       </>
//     )
//   }


//   return null;
// };


import React from 'react';
import dayjs from 'dayjs';
import { PARKING_STATUS } from '../../../utils/common';

export default function ParkingVacationCell({ row }) {
  const formatDate = (date) => dayjs(date).format('DD/MM/YYYY HH:mm');

  const renderPeriod = (label, startTime, endTime) => (
    <>
      <p><strong>{label}</strong></p>
      <p className="font-size-12"><strong>From:</strong> {formatDate(startTime)}</p>
      <p className="font-size-12"><strong>To:</strong> {formatDate(endTime)}</p>
    </>
  );

  const renderVacationPeriod = (vacation, index) => {
    if(!vacation) return;

    if (vacation.startTime && vacation.endTime) {
      const from = formatDate(vacation.startTime);
      const to = formatDate(vacation.endTime);
      const comments = vacation.comments && vacation.comments.toLowerCase().includes('one day');
      const label = comments ? 'Absence' : 'Vacation';

      return (
        <div key={index}>
          <strong>{label}</strong>
          <div className="font-size-12">
            <strong>From: </strong>
            <span>{from}</span>
          </div>
          <div className="font-size-12">
            <strong>To: </strong>
            <span>{to}</span>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderBookingPeriod = (booking) => {
    const isMidnightBooking = dayjs(booking.startTime).hour() === 0 || dayjs(booking.startTime).hour() === 3;
    const from = isMidnightBooking ? dayjs(booking.startTime).format('DD/MM/YYYY') : formatDate(booking.startTime);
    const to = isMidnightBooking ? dayjs(booking.endTime).format('DD/MM/YYYY') : formatDate(booking.endTime);

    return (
      <>
        <p><strong>Booked for:</strong> {booking.bookedFor.name}</p>
        <p className="font-size-12"><strong>From:</strong> {from}</p>
        <p className="font-size-12"><strong>To:</strong> {to}</p>
      </>
    );
  };

  if ((row.status || {}).type === PARKING_STATUS.TEMP_DISABLED) {
    const { startTime, endTime } = row.status || {};
    if (startTime && endTime && (!row.isAvailable || row.isAvailable)) {
      return renderPeriod('Temp Disabled', startTime, endTime);
    }
  } else if (Array.isArray(row.vacation)) {
    return row.vacation.map(renderVacationPeriod);
  } else if (row.vacation && row.vacation.startTime && row.vacation.endTime) {
    return renderVacationPeriod(row.vacation, 0);
  } else if (row.booking && row.booking.startTime && row.booking.endTime) {
    return renderBookingPeriod(row.booking);
  }

  return null;
}

