import React, { Component } from 'react';
import stores from '../../stores';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { changePasswordIcon, deleteIcon, editIcon } from '../../assets';
import Modal from '../../components/modal/Modal';
import EditParking from './ParkingsComponents/editParking/EditParking';
import HoldParking from './ParkingsComponents/holdParking/HoldParking';
import Parking from '../../models/Parking';
import ParkingVacationCell from './ParkingsComponents/ParkingVacationCell';
import ParkingNameCell from './ParkingsComponents/ParkingNameCell';
import EmployeeResponseCell from './ParkingsComponents/EmployeeResponseCell';
import ParkingEmployeeName from './ParkingsComponents/ParkingEmployeeName';
import ParkingAvailableCell from './ParkingsComponents/ParkingAvailableCell';
import filterCarNumbers from '../../utils/filterCarNumbers';
import DataTable from '../../components/DataTable/DataTable';
import { PARKING_STATUS } from '../../utils/common';
import DelayUnmountHoc from '../../components/DelayUnmountHOC/DelayUnmountHOC';
import './mobileParkingFit.css'
import { toast } from 'react-toastify';
const { parkingsStore, parksStore, userStore } = stores;

const STATUS_FILTER_VALUES = {
	AVAILABLE: 'available',
	OCCUPIED: 'occupied',
	DISABLED: 'disabled',
	TEMP_DISABLED: 'temp_disabled',
};

const statusFilterOptions = [
	{ label: 'All', value: '' },
	{ label: 'Available', value: STATUS_FILTER_VALUES.AVAILABLE },
	{ label: 'Occupied', value: STATUS_FILTER_VALUES.OCCUPIED },
	{ label: 'Disabled', value: STATUS_FILTER_VALUES.DISABLED },
	{ label: 'Temp Disabled', value: STATUS_FILTER_VALUES.TEMP_DISABLED },
];

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
const DelayedEditParking = DelayUnmountHoc(EditParking);

@withRouter
@observer
class Parkings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayLanguageTable : 'en'
		}
	}

	onEditLang = (lan) => {
		this.setState({displayLanguageTable : `${lan}`})
	};

	onEditRow = row => {
		parkingsStore.editParking(row._original);
	};

	onDeleteRow = row => {
		const answer = window.confirm('Are you sure you want to delete this forever?');
		if (answer) {
			// parkingsStore.delete(row._original._id);
			parkingsStore.delete(row._original._id).then(result =>  {
				console.log(result);
				toast(`Deleted employee: ${row._original.name}`, { type: toast.TYPE.SUCCESS });
			  }).catch(error=>{
				console.error(error);
				toast(`Failed to deleted employee: ${row._original.name}`, { type: toast.TYPE.WARNING });
			  })
		}
	};

	onHoldRelease = row => {
		const parking = new Parking(row._original);
		if (
			parking.isAvailable === true ||
			(parking.unavailableStatus && parking.unavailableStatus.type !== PARKING_STATUS.OCCUPIED)
		) {
			parkingsStore.showHoldModal(parking);
		} else {
			parkingsStore.releaseParking(parking);
		}
	};

	onNewParkingClick = () => {
		window.localStorage.setItem('EditModalOpen',true)
		parkingsStore.newParking();
	};

	render() {
		const {
			list,
			isEditModalShown,
			externalParkings,
			partnerExternalParkings,
			isHoldModalShown,
			hideEditModal,
			hideHoldModal,
			selectedParking,
		} = parkingsStore;
		const { isSendingHoursNow,currentCompany } = userStore;
		const currentUser = userStore.currentUser;
		const parksList = parksStore.getDropdownList;
		const { isWithWorkstation,isWithWorkstationMultiple} = currentCompany

		const shouldTranslate = this.state.displayLanguageTable === 'he';
		const columns = [
			{
				Header: currentUser.company.isWithWorkstation ? (shouldTranslate ? 'מיקום' : 'Location') : (shouldTranslate ? 'חניון' : 'Parking Lot'),
				accessor: 'park.name',
				Footer: columnProps => (
					<span>
                <strong>{shouldTranslate ? 'סך הכל: ' : 'Total: '}</strong>
						{columnProps.data.length}
            </span>
				),
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: '100%' }}
						value={filter ? filter.value : ''}
					>
						<option value="">{shouldTranslate ? 'הכל' : 'All'}</option>
						{parksList.map(option => (
							<option value={option.label} key={option.value}>
								{option.label}
							</option>
						))}
					</select>
				),
			},
			{
				Header: currentUser.company.isWithWorkstation ? (shouldTranslate ? 'שם תחנת עבודה' : 'Workstation Name') : (shouldTranslate ? 'שם חניון' : 'Parking Name'),
				accessor: 'name',
				Cell: ParkingNameCell,
				sortMethod: (a, b, desc) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }),
			},
			{
				Header: shouldTranslate ? 'עובד קבוע' : 'Constant Employee',
				accessor: 'employee',
				Cell: (ref) => { // use to be  Cell: <ParkingEmployeeName/> 
					const employees = Array.isArray(ref.original.employee) ? ref.original.employee : ref.original.employee ? [ref.original.employee] : []

					if (!employees || !employees.length) return null

					if (employees) {
						if (isWithWorkstation && isWithWorkstationMultiple) {
							return employees.reduce((acc, emp, index) => {
								if (index === 0) {
									return [<ParkingEmployeeName key={index} value={emp} />];
								} else {
									return [...acc, <br key={`br-${index}`} />, <ParkingEmployeeName key={index} value={emp} />];
								}
							}, []);
						} else {
							return <ParkingEmployeeName value={employees[0]} />;
						}
					}
				},
				filterMethod: (filter, row) => {
					if (!row['employee'] || !row['employee']['name']) return false;
					return row['employee']['name'].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
				},
			},

			// Available
			{
				Header: shouldTranslate ? 'זמין?' : 'Available?',
				accessor: 'isAvailable',
				Cell: (ref) => { // use to be  Cell: <ParkingAvailableCell/> 
					return <ParkingAvailableCell {...ref} />
				},
				filterMethod: (filter, row) => {
					const original = row._original;
					if (filter.value === '') {
						return true;
					} else {
						const condition =
							original.unavailableStatus &&
							original.unavailableStatus.type &&
							original.unavailableStatus.type === filter.value;
						if (filter.value === STATUS_FILTER_VALUES.AVAILABLE) {
							return original.isAvailable === true;
						}
						else if (filter.value === STATUS_FILTER_VALUES.TEMP_DISABLED && !row.isAvailable) {
							return false;
						}
						else if (filter.value === STATUS_FILTER_VALUES.DISABLED) {
							return original.isAvailable === false && condition;
						} else {
							return condition;
						}
					}
				},
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: '100%' }}
						value={filter ? filter.value : ''}
					>
						{statusFilterOptions.map(option => (
							<option value={option.value} key={option.value}>
								{option.label}
							</option>
						))}
					</select>
				),
			},

			// Car Number
			{
				Header: shouldTranslate ? 'מספר רכב' : 'Car Number',
				accessor: 'employee.carNumbers',
				Cell: ({ row }) => {
					if (
						!row.isAvailable &&
						row._original.unavailableStatus &&
						row._original.unavailableStatus.carNumbers
					) {
						return row._original.unavailableStatus.carNumbers.map((number, key) => (
							<p key={key}>{number}</p>
						));
					}
					return '';
				},
				filterMethod: (filter, row) => {
					if (!row._original) return false;
					const original = row._original;
					let carNumbers = [];
					if (original.unavailableStatus) {
						carNumbers = [].concat(original.unavailableStatus['carNumbers'] || []);
					}
					return filterCarNumbers(carNumbers, filter.value);
				},
			},
			{
				Header: shouldTranslate ? 'החזק / שחרר?' : 'Hold / Release?',
				Cell: ({ row }) => (
					<img src={changePasswordIcon} onClick={this.onHoldRelease.bind(this, row)} />
				),
				sortable: false,
				filterable: false,
			},
			{
				Header: shouldTranslate ? 'מושבת' : 'Disabled',
				accessor: 'employeeTodayResponse',
				Cell: props => <EmployeeResponseCell {...props} isSendingHoursNow={isSendingHoursNow} />,
				filterable: false,
			},
			{
				Header: shouldTranslate ? 'חופשה / הזמנה / השבתה' : 'Vacation / Booking / Disabled',
				Cell: ({ row }) => <ParkingVacationCell row={row._original} />,
				sortable: false,
				filterable: false,
			},
			{
				Header: shouldTranslate ? 'העדפה' : 'Pref.',
				accessor: 'preference',
				width: 80,
			},
			{
				Header: shouldTranslate ? 'ערוך' : 'Edit',
				Cell: ({ row }) => <img src={editIcon} onClick={this.onEditRow.bind(this, row)} />,
				sortable: false,
				filterable: false,
				width: 80,
			},
			{
				Header: shouldTranslate ? 'מחק' : 'Delete',
				Cell: ({ row }) => <img src={deleteIcon} onClick={this.onDeleteRow.bind(this, row)} />,
				sortable: false,
				filterable: false,
				width: 80,
			},
		];


		const holdModalTitle = `Hold Parking ${selectedParking ? `(${selectedParking.name})` : ''}`;
		const editModalTitle =
			selectedParking && selectedParking._id
				? `Edit ${currentUser.company.isWithWorkstation ? 'Workstation' : 'Parking'} (${
						selectedParking.name
				  })`
				: `New ${currentUser.company.isWithWorkstation ? 'Workstation' : 'Parking'}`;

		
		return (
			<div className="full-height">
				<h1 className="page-title">
					{currentUser.company.isWithWorkstation ? 'Workstations' : 'Parkings'}
				</h1>
				<div className="actions">
					<button type="button" onClick={this.onNewParkingClick}>
						{currentUser.company.isWithWorkstation ? 'New Workstation' : 'New Parking'}
					</button>
				</div>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<img
						id="english_flag"
						src="https://i.imgur.com/RtXFq5i.png"
						onClick={this.onEditLang.bind(this,'en')}
						style={{
							maxWidth: 30,
							maxHeight: 30,
							marginRight:10
						}}
					/>
					<img
						id="israel_flag"
						src="https://i.imgur.com/98bCEMy.png"
						onClick={this.onEditLang.bind(this,'he')}
						style={{
							maxWidth: 30,
							maxHeight: 30,
							marginRight:20
						}}
					/>
				</div>
				<DataTable
					filterable
					data={list.slice()}
					columns={columns}
					defaultSorted={[
						{
							id: 'park.name',
							desc: false,
						},
						{
							id: 'name',
							desc: false,
						},
					]}
				/>
				<Modal title={editModalTitle} show={isEditModalShown} handleClose={hideEditModal}>
					{/* <EditParking/> */}
					<DelayedEditParking
						delayTime={500}
						isMounted={isEditModalShown}
						parking={selectedParking}
						parkingLots={parksStore.getDropdownList}
						externalParkings={externalParkings}
						partnerExternalParkings={partnerExternalParkings}
						closeModal={hideEditModal}
						displayLanguageModel={this.state.displayLanguageTable}
					/>
				</Modal>
				<Modal title={holdModalTitle} show={isHoldModalShown} handleClose={hideHoldModal}>
					<HoldParking parking={selectedParking} closeModal={hideHoldModal} displayLanguageModel={this.state.displayLanguageTable} />
				</Modal>
			</div>
		);
	}
}

Parkings.propTypes = {
	//myProp: PropTypes.object.isRequired
};

Parkings.defaultProps = {
	//myProp: <defaultValue>
};

export default Parkings;
