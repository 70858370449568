import { action, observable } from 'mobx';
import prettyMs from 'pretty-ms';
import React from 'react';
import API from '../../../utils/API/api';
import ReportsStore from '../reportsStore';
import ReportStartDateCell from '../../../components/Reports/ReportStartDateCell';
import ReportEndDateCell from '../../../components/Reports/ReportEndDateCell';

const tableColumns = [
  {
    Header: 'Employee Name',
    accessor: 'username',
    Footer: (columnProps) => {
      return (
        <span>
          <strong>Total: </strong>{columnProps.data.length}
        </span>
      )
    }
  }, {
    Header: 'Parking Name',
    accessor: 'parking'
  }, {
    Header: 'Start Date',
    accessor: 'start',
    Cell: ReportStartDateCell
  }, {
    Header: 'End Date',
    accessor: 'end',
    Cell: ReportEndDateCell
  }, {
    Header: 'Duration',
    accessor: 'duration',
    Cell: ({ value }) => Number.isFinite(value) && prettyMs(value)
  }
];

class EmployeesReportStore extends ReportsStore {
  @observable selectedEmployee;

  constructor(stores) {
    super(stores, tableColumns, API.company.reports.getEmployeesReport, API.company.reports.exportEmployeesReport)
  }

  @action
  setSelectedEmployee = (employee) => {
    this.selectedEmployee = employee;
  };
}

export default EmployeesReportStore;
