import { action, computed, observable } from 'mobx';
import API from '../../utils/API/api';
import CrudStore from './crudStore';
import Park from '../../models/Park';
import { ROLES } from '../../utils/common';
import { authorize } from '../../components/AuthrorizeHOC/AuthorizeHOC';

const defaultPermissions = [ROLES.COMPANY, ROLES.SENIOR_SECRETARY];
const extendedPermissions = [...defaultPermissions, ROLES.SECRETARY];

const permissions = {
  getById: extendedPermissions,
  getAll: extendedPermissions,
  create: defaultPermissions,
  update: defaultPermissions,
  delete: defaultPermissions
};

class ParksStore extends CrudStore {
  @observable selectedPark;
  @observable isEditModalShown;

  constructor(stores) {
    super(stores, API.company.parks, permissions);
    /*stores.userStore.onCompanyUserLoaded((user) => {
      this.getAll();
      stores.branchesStore.getAll();
    })*/
  }

	@action
	getParksByCompany(companyId) {
		return API.admin.parks.getByCompanyId(companyId).then(response => response.results);
	}

  @action
  editBranch = (branch) => {
    if (authorize(defaultPermissions)) {
      this.selectedPark = branch;
      this.showEditModal();
    }
  };

  @action
  newPark = () => {
    if (authorize(defaultPermissions)) {
      this.selectedPark = new Park();
      this.showEditModal();
    }
  };

  @action
  showEditModal = () => {
    this.isEditModalShown = true;
  };

  @action
  hideEditModal = () => {
    this.isEditModalShown = false
  };

  @computed
  get getDropdownList() {
    return this.list.map(item => ({ label: item.name, value: item._id }));
  }
}

export default ParksStore;
