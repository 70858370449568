import { action, observable } from 'mobx';
import API from '../../utils/API/api';
import CrudStore from './crudStore';
import Parking from '../../models/Parking';
import { ROLES, ROUTES } from '../../utils/common';
import { authorize } from '../../components/AuthrorizeHOC/AuthorizeHOC';

const defaultPermissions = [ROLES.COMPANY, ROLES.SENIOR_SECRETARY];
const extendedPermissions = [...defaultPermissions, ROLES.SECRETARY];

const permissions = {
	getById: extendedPermissions,
	getAll: extendedPermissions,
	create: defaultPermissions,
	update: defaultPermissions,
	delete: defaultPermissions,
};

const minuteInterval = 1000 * 15;

class ParkingsStore extends CrudStore {
	@observable selectedParking;
	@observable isEditModalShown = false;
	@observable isHoldModalShown = false;
	@observable externalParkings = observable.array();
	@observable partnerExternalParkings = observable.array();

	constructor(stores) {
		super(stores, API.company.parkings, permissions);
		let intervalId;
		this.commonStore = stores.commonStore;
		this.parksStore = stores.parksStore;
		this.commonStore.onLocationChanged(location => {
			clearInterval(intervalId);
			if (location.path === ROUTES.PARKINGS) {
				this.loadAllData();
				intervalId = setInterval(() => {
					const EditModalOpen = window.localStorage.getItem('EditModalOpen') || false
					if(EditModalOpen !== 'true')
						this.loadAllData();
				}, minuteInterval);
			}
		});
	}

	loadAllData = () => {
		this.fetchExternalParkings();
		this.fetchPartnerExternalParkings();
		this.getAll(true);
		this.parksStore.getAll(true);
	};

	@action
	refreshParking(parkingId) {
		this.commonStore.setIsLoading(true);
		return this.api
			.getParking(parkingId)
			.then(
				action(response => {
					const parkingIdx = this.list.findIndex(p => p._id === parkingId);
					if (parkingIdx > -1) {
						this.list[parkingIdx] = response.results;
					} else {
						this.list.push(response.results);
						return response.results;
					}
					this.commonStore.setIsLoading(false);
				}),
			)
			.catch(e => {
				console.log(e);
				this.commonStore.setIsLoading(false);
			});
	}

	@action
	fetchExternalParkings = () => {
		this.api
			.getExternalParkings()
			.then(
				action(response => {
					this.externalParkings = response.results;
				}),
			)
			.catch(e => console.error(e));
	};

	@action
	setIsPartner = (parkingId, bool) => {
		this.api
			.setIsPartner(parkingId, bool)
			.then(res => {})
			.catch(e => console.error(e));
	};

	@action
	setPartnerOf = (partnerParking, currentParking) => {
		this.api
			.setPartnerOf(partnerParking, currentParking)
			.then(res => {})
			.catch(e => console.error(e));
	};

	@action
	fetchPartnerExternalParkings = () => {
		this.api
			.getPartnerExternalParkings()
			.then(
				action(response => {
					this.partnerExternalParkings = response.results;
				}),
			)
			.catch(e => console.error(e));
	};

	@action
	editParking = parking => {
		if (authorize(defaultPermissions)) {
			this.selectedParking = parking;
			this.showEditModal();
		}
	};

	@action
	newParking = () => {
		if (authorize(defaultPermissions)) {
			this.selectedParking = new Parking();
			this.showEditModal();
		}
	};

	@action
	create = object => {
		if (authorize(defaultPermissions)) {
			this.commonStore.setIsLoading(true);
			return this.api
				.create(object)
				.then(
					action(response => {
						if (response.results === -1) {
							alert(
								'Sorry ... You have reach the maximum parkings number you have purchase, Please contact the DPM support team support@dpm-parking.com',
							);
							throw new Error('max parkings limit reached');
						} else if (response.results === -2) {
							alert('Error ...The same parking is existing.');
							throw new Error('parking is already exists');
						} else if (response.results && response.results._id) {
							return this.refreshParking(response.results._id);
						} else {
							alert('Error ...Please try again.');
							throw new Error('Error ...Please try again.');
						}
					}),
				)
				.finally(() => {
					this.commonStore.setIsLoading(false);
				});
		}
		return Promise.reject('Not authorized to create new parking');
	};

	@action
	showEditModal = () => {
		this.isEditModalShown = true;
		window.localStorage.setItem('EditModalOpen', this.isEditModalShown);
	};

	@action
	hideEditModal = () => {
		this.isEditModalShown = false;
		window.localStorage.setItem('EditModalOpen', this.isEditModalShown);
	};

	@action
	showHoldModal = parking => {
		this.isHoldModalShown = true;
		this.selectedParking = parking;
	};

	@action
	hideHoldModal = () => {
		this.isHoldModalShown = false;
		this.selectedParking = undefined;
	};

	@action
	holdParking(parking, guest) {
		if (authorize(defaultPermissions)) {
			this.commonStore.setIsLoading(true);
			// if (parking && parking.isAvailable === true) {
			return this.api
				.holdParking(guest.name, guest.phone, guest.carNumber, guest._id, parking._id)
				.then(response => {
					if (response && response.results === 1) {
						this.refreshParking(parking._id);
					}
					this.commonStore.setIsLoading(false);
					return response;
				})
				.catch(e => {
					this.commonStore.setIsLoading(false);
				});
			// }
			// return Promise.reject('parking is not available');
		}
	}

	@action
	releaseParking(parking) {
		if (authorize(defaultPermissions)) {
			if (parking && parking.isAvailable === true) {
				this.selectedParking = parking;
				this.showHoldModal();
			} else {
				const answer = window.confirm('Are you sure you want to release this parking?');
				if (answer) {
					this.commonStore.setIsLoading(true);
					this.api
						.releaseParking(parking._id)
						.then(() => {
							this.refreshParking(parking._id);
							this.commonStore.setIsLoading(false);
						})
						.catch(err => {
							console.error(err);
							this.commonStore.setIsLoading(false);
						});
				}
			}
		}
	}

	getAutoCompleteEmployees(query, type) {
		return this.api.employeeNameAutoComplete(query, type).then(response => response.results);
	}

	getAutoCompleteEmployeesByCarNumber(query, type) {
		return this.api.carNumberAutoComplete(query, type).then(response => response.results);
	}
}

export default ParkingsStore;
