import { action, computed, observable } from 'mobx';
import API from '../../utils/API/api';
import { ROLES, ROUTES } from '../../utils/common';
import { authorize } from '../../components/AuthrorizeHOC/AuthorizeHOC';

const defaultPermissions = [ROLES.ADMIN];

class CompaniesStore {

  @observable companiesList = observable([]);
  @observable smsCredit = 0;
  @observable selectedCompany;
  @observable isEditCompanyModalShown;

  constructor(stores) {
    this.api = API.admin.companies;
    this.commonStore = stores.commonStore;
    stores.userStore.onAdminUserLoaded((user) => {
      this.loadCompanies();
      this.loadSmsCredit();

      stores.commonStore.onLocationChanged(location => {
        if (location.path.indexOf(ROUTES.COMPANIES) > -1) {
          if (location.params && location.params.companyId) {
            this.getById(location.params.companyId);
          }
        }
      }, {
        path: `${ROUTES.COMPANIES}/:companyId?`
      })
    });
  }

  @action
  getById(companyId) {
    if (!authorize(defaultPermissions)) {
      return;
    }
    this.commonStore.setIsLoading(true);
    return this.api.getById(companyId).then(action((response) => {
      this.companiesList.replace([response.results]);
      this.commonStore.setIsLoading(false);
    })).catch(e => this.commonStore.setIsLoading(false));
  }

  @action
  loadCompanies() {
    if (!authorize(defaultPermissions)) {
      return;
    }
    this.commonStore.setIsLoading(true);
    return this.api.getAll().then(action((response) => {
      this.companiesList.replace(response.results);
      this.commonStore.setIsLoading(false);
    })).catch(e => this.commonStore.setIsLoading(false));
  }

  @action
  loadSmsCredit() {
    this.commonStore.setIsLoading(true);
    return this.api.getSmsCredit().then(action(response => {
      this.smsCredit = response;
      this.commonStore.setIsLoading(false);
    })).catch(() => this.commonStore.setIsLoading(false));
  }

  @computed
  get companies() {
    return this.companiesList;
  };

  @action
  createCompany(company) {
    return this.api.create(company).then(action(response => {
      if (response.results && response.results._id) {
        this.companiesList.push(response.results);
      }
    }));
  }

  @action
  updateCompany(company) {
    return this.api.update(company).then(action(response => {
      if (response.results && response.results._id) {
        const idx = this.companiesList.findIndex(c => c._id === company._id);
        this.companiesList[idx] = response.results;
      }
    }));
  }

  @action
  deleteCompany(companyId) {
    return this.api.delete(companyId).then(action(response => {
      const companyIdx = this.companiesList.findIndex(c => c._id === companyId);
      this.companiesList.splice(companyIdx, 1);
    }));
  }

  @action
  editCompany = (companyId) => {
    if (companyId) {
      this.selectedCompany = this.companiesList.find(c => c._id === companyId);
      this.showEditCompanyModal();
    }
  };

  @action
  newCompany = () => {
    this.selectedCompany = null;
    this.showEditCompanyModal();
  };

  @action
  showEditCompanyModal = () => {
    this.isEditCompanyModalShown = true;
  };

  @action
  hideEditCompanyModal = () => {
    this.isEditCompanyModalShown = false
  };
}

export default CompaniesStore;
