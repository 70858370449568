import dayjs from 'dayjs';

class WaitingRange {
	constructor(object = {}) {
		this.sendAt = (object.sendAt && dayjs(object.sendAt).toDate()) || null;
		this.waitUntil = (object.waitUntil && dayjs(object.waitUntil).toDate()) || null;
	}
}

export const LPR_AVAILABLE_LANGUAGES = {
	ENGLISH: 'en',
	HEBREW: 'he'
}

export const LPR_AVAILABLE_LANGUAGES_LABELS = {
	he: 'עברית',
	en: 'אנגלית',
}

const smsSendingHoursDefaultObject = {
	sunday: {
		sendAt: null,
		waitUntil: null,
	},
	monday: {
		sendAt: null,
		waitUntil: null,
	},
	tuesday: {
		sendAt: null,
		waitUntil: null,
	},
	wednesday: {
		sendAt: null,
		waitUntil: null,
	},
	thursday: {
		sendAt: null,
		waitUntil: null,
	},
	friday: {
		sendAt: null,
		waitUntil: null,
	},
	saturday: {
		sendAt: null,
		waitUntil: null,
	},
};

class SmsSendingHours {
	constructor(object = smsSendingHoursDefaultObject) {
		this.sunday = new WaitingRange(object.sunday);
		this.monday = new WaitingRange(object.monday);
		this.tuesday = new WaitingRange(object.tuesday);
		this.wednesday = new WaitingRange(object.wednesday);
		this.thursday = new WaitingRange(object.thursday);
		this.friday = new WaitingRange(object.friday);
		this.saturday = new WaitingRange(object.saturday);
	}
}

export const defaultCompanyObject = {
	name: '',
	startDate: new Date(),
	senderNameSMS: '',
	isActive: true,
	maxParkings: 0,
	maxUsers: 0,
	notes: '',
	smsSendingHours: {},
	isWithGPS: false,
	autoReleaseTime: null,
	isAutoReleaseGeofence: false,
	isWithVirtualParking: false,
	isWithAutomaticLPR: false,
	isWithSMSLPR: false,
	isWithNotificationLPR: false,
	isWithGeofenceNotification: false,
	isWithHoldGeofenceNotification: false,
	isWithSendingSMSForArrival: false,
	isWithPushNotificationsArrival :false ,
	isWithSendingSMSForArrivalByDays : false,
	isWithCheckResponseOfSMSForArrival: false,
	isWithVacationInApp: false,
	isWithBooking: false,
	isWithWorkstation: false,
	isWithWorkstationMultiple: false,
	isWithManualPush: false,
	isWithReservations: false,
	isWithPartnerParking: false,
	isWithHoldingOccupiedParking: false,
	numOfFacilities: 0,
	facilities: [],
	reportTypes: [],
	logoUrl: '',
	lprMessageLanguage: LPR_AVAILABLE_LANGUAGES.ENGLISH,
	gatePhoneNumber: '',
	isManagementCompany : false,
	managedCompanies : []
};

class Company {
	constructor(rawCompany = defaultCompanyObject) {
		if (rawCompany) {
			this._id = rawCompany._id;
			this.name = rawCompany.name;
			this.smsSendingHours = new SmsSendingHours(rawCompany.smsSendingHours);
			this.startDate = rawCompany.startDate;
			this.senderNameSMS = rawCompany.senderNameSMS;
			this.isActive = rawCompany.isActive;
			this.maxParkings = rawCompany.maxParkings;
			this.maxUsers = rawCompany.maxUsers;
			this.notes = rawCompany.notes;
			this.isWithGPS = rawCompany.isWithGPS;
			if (rawCompany.autoReleaseTime) {
				const hour = dayjs(rawCompany.autoReleaseTime).hour();
				const minute = dayjs(rawCompany.autoReleaseTime).minute();
				this.autoReleaseTime = dayjs().set('hour', hour).set('minute',minute).toDate();
			} else {
				this.autoReleaseTime = '';
			}
			this.isAutoReleaseGeofence = rawCompany.isAutoReleaseGeofence;
			this.isWithVirtualParking = rawCompany.isWithVirtualParking;
			this.isWithAutomaticLPR = rawCompany.isWithAutomaticLPR;
			this.isWithSMSLPR = rawCompany.isWithSMSLPR;
			this.isWithNotificationLPR = rawCompany.isWithNotificationLPR;
			this.isWithGeofenceNotification = rawCompany.isWithGeofenceNotification;
			this.isWithHoldGeofenceNotification = rawCompany.isWithHoldGeofenceNotification;
			this.isWithSendingSMSForArrival = rawCompany.isWithSendingSMSForArrival;
			this.isWithPushNotificationsArrival = rawCompany.isWithPushNotificationsArrival;
			this.isWithSendingSMSForArrivalByDays = rawCompany.isWithSendingSMSForArrivalByDays;
			this.isWithCheckResponseOfSMSForArrival = rawCompany.isWithCheckResponseOfSMSForArrival;
			this.isWithVacationInApp = rawCompany.isWithVacationInApp;
			this.isWithBooking = rawCompany.isWithBooking;
			this.isWithWorkstation = rawCompany.isWithWorkstation; // Is Workstation
			this.isWithWorkstationMultiple = rawCompany.isWithWorkstationMultiple; // Multiple employee for Workstation
			this.isWithManualPush = rawCompany.isWithManualPush;
			this.isWithReporting = rawCompany.isWithReporting;
			this.isWithPartnerParking = rawCompany.isWithPartnerParking;
			this.isWithReservations = rawCompany.isWithReservations;
			this.isWithHoldingOccupiedParking = rawCompany.isWithHoldingOccupiedParking;
			this.facilities = rawCompany.facilities;
			this.numOfFacilities = rawCompany.numOfFacilities;
			this.isWithOpenGate = rawCompany.isWithOpenGate;
			this.isWithAutoResetPassword = rawCompany.isWithAutoResetPassword;
			this.gatePhoneNumber = rawCompany.gatePhoneNumber;
			this.isSpecial = rawCompany.isSpecial;
			this.isWithTripleBlocking = rawCompany.isWithTripleBlocking;
			this.reportTypes = rawCompany.reportTypes || [];
			this.lprMessageLanguage = rawCompany.lprMessageLanguage;
			this.logoUrl = rawCompany.logoUrl;
			this.isUsingDpmLpr = rawCompany.isUsingDpmLpr;
			this.isWithOpenGateDpmLpr = rawCompany.isWithOpenGateDpmLpr;
			this.maxLprParking = rawCompany.maxLprParking;
			this.isUsingLightmode = rawCompany.isUsingLightmode;
			this.isManagementCompany = rawCompany.isManagementCompany;
			this.managedCompanies = rawCompany.managedCompanies || [];
		}
	}
}

export default Company;
