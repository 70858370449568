import { observer } from 'mobx-react';
import React, { Component } from 'react';
import DataTable from '../../../../components/DataTable/DataTable';
import stores from '../../../../stores/index';
// import LprLogsNotUsersReportStore from '../../../../stores/logic/reports/LprLogsNotUsersReportStore';
import LprLogsNotUsersFilters from './LprLogsNotUsersFilters';

const { lprLogsNotUsersReportStore } = stores;

@observer
class LprLogsNotUsersReport extends Component {
	componentDidMount() {
		lprLogsNotUsersReportStore.getReport();
	}

	onExportClick = submittedValues => {
		const params = this.parseParams(submittedValues);
		lprLogsNotUsersReportStore.exportReport(params);
	};

	parseParams = submittedValues => {
		let params = {};
		if (submittedValues.carNumber) {
			params.carNumber = submittedValues.carNumber;
		}
		return params;
	};

	onFiltersSearch = submittedValues => {
		const params = this.parseParams(submittedValues);
		lprLogsNotUsersReportStore.getReport(params);
	};

	render() {
		return (
			<div>
				<h1 className="page-title">Lpr Logs Not Users Report</h1>
				<div className="actions">
					<LprLogsNotUsersFilters onSearch={this.onFiltersSearch} onExportClick={this.onExportClick} />
				</div>
				<DataTable
					columns={lprLogsNotUsersReportStore.tableColumns}
					data={lprLogsNotUsersReportStore.currentReport.slice()}
				/>
			</div>
		);
	}
}

export default LprLogsNotUsersReport;
