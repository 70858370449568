import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { createHashHistory } from 'history';
import stores from './stores';

const history = createHashHistory({
  basename: '/'
});

// Handle update from history object
const handleLocationChange = (location, action) => {
  stores.commonStore._updateLocation(location);
  // console.log(action, location.pathname, location.state);
};

stores.commonStore._updateLocation(history.location);
const unsubscribeFromHistory = history.listen(handleLocationChange);

/*configure({
  enforceActions: 'always'
});*/

ReactDOM.render((
  <Provider {...stores}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
), document.getElementById('root'));