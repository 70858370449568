import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import stores from '../../../stores';
import Branch from '../../../models/Branch';
import Error from '../../../components/forms/Error';
import BootstrapInput from '../../../components/BootstrapInput/BootstrapInput';

class EditBranchModal extends Component {
  constructor(props) {
    super(props);
  }

  onSubmitForm = (submittedValues) => {
    const branch = new Branch(submittedValues);
    const isEditMode = this.props.branch && this.props.branch._id;

    if (!isEditMode) {
      stores.branchesStore.create(branch).then((response) => {
        this.props.closeModal();
      }).catch(err => {
        console.error(err);
      })
    }
    else {
      stores.branchesStore.update(branch).then((response) => {
        this.props.closeModal();
      }).catch(err => {
        console.error(err);
      })
    }
  };

  validateForm = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Branch name is required';
    }
    return errors
  };

  render() {
    const defaultValues = new Branch(this.props.branch || undefined);
    return (
      <Form onSubmit={this.onSubmitForm} initialValues={defaultValues} validate={this.validateForm}
            render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">Branch Name*:</div>
                    <div className="col">
                      <Field name="name" component={BootstrapInput} type="text" />
                      <Error name="name" />
                    </div>
                  </div>
                  <button type="submit" disabled={submitting}>
                    Save
                  </button>
                </form>
              )
            }} />
    );
  }
}

EditBranchModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  branch: PropTypes.object,
};

EditBranchModal.defaultProps = {
  branch: undefined,
};

export default EditBranchModal;