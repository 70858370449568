import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import Error from '../../../components/forms/Error';

import stores from '../../../stores';
import Company from '../../../models/Company';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import BootstrapInput from '../../../components/BootstrapInput/BootstrapInput';

const { userStore } = stores;

@observer
class CompanyDetails extends Component {

  onSubmitForm = (submittedValues) => {
    const currentCompany = new Company(submittedValues);
    userStore.updateCompanyDetails(currentCompany).then(response => {
      toast("Company Details saved!", { type: toast.TYPE.SUCCESS });
    }).catch(e => {
      toast("Error while saving company details", { type: toast.TYPE.ERROR });
      console.error(e);
    });
  };

  render() {
    const defaultValues = new Company(userStore.currentCompany);
    return (
      <div>
        <h1 className="page-title">Company Details</h1>
        <Form onSubmit={this.onSubmitForm} initialValues={defaultValues}
              validate={this.validateForm}
              render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
                return (
                  <div className="row text-left">
                    <div className="col-sm-4 col-sm-offset-4">
                      <div className="form-container">
                        <form className="mt-2" onSubmit={handleSubmit}>
                          <div className="user-details-container">
                            <div className="form-group">
                              <label>Company Name:</label>
                              <Field name="name" component={BootstrapInput} type="text" />
                              <Error name="name" />
                            </div>
                            <div className="form-group">
                              <label>Sender SMS Name:</label>
                              <Field name="senderNameSMS" component={BootstrapInput} type="text" />
                            </div>
                            <div className="form-group">
                              <label>Logo URL:</label>
                              <Field name="logoUrl" component={BootstrapInput} type="text" />
                            </div>
                            {values.isWithOpenGate && (<div className="form-group">
                              <label>Gate phone number:</label>
                              <Field name="gatePhoneNumber" component={BootstrapInput} type="text" />
                            </div>)}
                            <button type="submit" disabled={submitting} >
                              Save
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )
              }
              } />
      </div>
    )
  }
}

export default CompanyDetails;
