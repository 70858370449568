import React from 'react';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import './fileInput.scss';

function parseFileSize(fileSizeInBytes) {
  if (typeof fileSizeInBytes === 'number') {
    const sizeInKb = Number(fileSizeInBytes / 1024);
    if (sizeInKb >= 1000) {
      return Number(sizeInKb / 1024).toFixed(2) + 'MB'
    }
    return Number(sizeInKb).toFixed(2) + 'KB';
  }
  return 0;
}

const DropFilesMessage = (props) => {
  return (
    <div>
      <h1>Drop or click to select your Excel file</h1>
    </div>
  )
};

const ShowFileInfo = (props) => {
  return (
    <div>
      <h3>{props.file.name}</h3>
      <p>{parseFileSize(props.file.size)}</p>
    </div>
  );
};

const ShowUnsupportedMessage = (props) => {
  return 'This file is unsupported';
};

const FileInput = (props) => {
  return (
    <div>
      <Dropzone accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                multiple={false}
                onDrop={(files, e) => {
                  props.input.onChange(files, e);
                }}
                onBlur={e => {
                  props.input.onBlur(e);
                }}
                name={props.input.name}
      >
        {({ getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
          const className = classnames({
            dropzone: true,
            focused: isFocused,
            active: isDragActive,
            accepted: isDragAccept || acceptedFiles.length > 0,
            rejected: isDragReject || rejectedFiles.length > 0
          });
          return (
            <div
              className={className}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div>
                {acceptedFiles.length > 0 ? <ShowFileInfo file={acceptedFiles[0]} /> : <DropFilesMessage />}
              </div>
              {isDragReject && <ShowUnsupportedMessage />}
            </div>
          )
        }}
      </Dropzone>
    </div>
  );
};

export default FileInput;