import React, { Component } from "react";
import stores from "../../stores";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Field, Form } from "react-final-form";
import BootstrapInput from '../../components/BootstrapInput/BootstrapInput';
import Error from '../../components/forms/Error';
import DPMLoader from '../../components/Loader/DPMLoader';

const { pushNotificationsStore } = stores;

@withRouter
@observer
class PushNotifications extends Component {
  constructor(props) {
    super(props);
  }

  onSubmitForm = ({title, message, screen}) => {
    return pushNotificationsStore.sendPush(title, message, screen.value);
  };

  render() {
    const {
      title,
      message,
      screen,
      screensList,
      isLoading
    } = pushNotificationsStore;
    const initialValues = {
      title,
      message,
      screen
    };

    return (
      <div className="full-height">
        <DPMLoader />
        <h1 className="page-title">Push Notifications</h1>
        <Form
          onSubmit={this.onSubmitForm}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, form }) => {
            return (
              <form onSubmit={event => {
                handleSubmit(event).then(() => {
                  form.reset()
                })
              }}>
                <div className="container">
                  <h4>Sending Push Notification to all users</h4>
                  <div className="row col-sm-10 col-sm-offset-4">
                    <div className="col col-sm-3 text-left">
                      Title: <span className="font-size-12">(Will not display in iOS)</span>
                    </div>
                    <div className="col col-sm-4">
                      <Field name="title" component={BootstrapInput} type="text" />
                      <Error name="title" />
                    </div>
                  </div>
                  <div className="row col-sm-10 col-sm-offset-4 mt-1">
                    <div className="col col-sm-3 text-left">Message:</div>
                    <div className="col col-sm-4">
                      <Field name="message" render={({input, meta}) => {
                        return (
                          <textarea className="form-control noresize" rows="5" onChange={input.onChange} {...input} />
                        )
                      }} />
                      <Error name="message" />
                    </div>
                  </div>
                  {/*<div className="row col-sm-10 col-sm-offset-4 mt-1">
                    <div className="col col-sm-2 text-left">Screen:</div>
                    <div className="col col-sm-4">
                      <Field name="screen">
                        {({ input, meta }) => {
                          return (
                            <Select
                              className="basic-single"
                              classNamePrefix="react-select"
                              value={input.value}
                              onChange={input.onChange}
                              onBlur={input.onBlur}
                              onFocus={input.onFocus}
                              options={screensList}
                              isClearable={true}
                            />
                          )
                        }
                        }
                      </Field>
                      <Error name="screen" />
                    </div>
                  </div>*/}
                  <div className="row col-sm-10 col-sm-offset-4 mt-1">
                    <div className="col col-sm-3" />
                    <div className="col col-sm-4">
                      <button type="submit" className="btn-block" disabled={submitting}>
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )
          }}
        />
      </div>
    );
  }
}

PushNotifications.propTypes = {
  //myProp: PropTypes.object.isRequired
};

PushNotifications.defaultProps = {
  //myProp: <defaultValue>
};

export default PushNotifications;
