import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import stores from '../../../stores';
import Error from '../../../components/forms/Error';
import Guest from '../../../models/Guest';
import BootstrapInput from '../../../components/BootstrapInput/BootstrapInput';
import DatePicker from 'react-datepicker';
import randomPassword from '../../../utils/randomPassword';
import dayjs from 'dayjs';
import Select from 'react-select';

class EditGuestModal extends Component {
	constructor(props) {
		super(props);
	}

	onCompanySelected = (option) => {
		stores.userStore.currentUser.company.managedCompanies = option;
	}
	onSubmitForm = submittedValues => {
		const guest = new Guest(submittedValues);
		// eslint-disable-next-line no-unused-expressions
		stores.userStore.currentUser.company.isManagementCompany ? Object.assign(guest,{...{managedCompanies:stores.userStore.currentUser.company.managedCompanies}}): null;
		const isEditMode = this.props.guest && this.props.guest._id;
		if (!isEditMode) {
			stores.guestsStore
				.create(guest)
				.then(response => {
					this.props.closeModal();
				})
				.catch(err => {
					console.error(err);
				});
		} else {
			stores.guestsStore
				.update(guest)
				.then(response => {
					this.props.closeModal();
				})
				.catch(err => {
					console.error(err);
				});
		}
	};

	validateForm = values => {
		const errors = {};
		if (!values.name) {
			errors.name = 'Guest name is required';
		}
		if (!values.phone) {
			errors.phone = 'Username is required';
		}
		if (!values.password && values._id === undefined) {
			errors.password = 'Password is required';
		}
		if (!values.startDate) {
			errors.startDate = 'Start date is required';
		}
		if (!values.endDate) {
			errors.endDate = 'End date is required';
		}
		return errors;
	};

	render() {
		const defaultValues = new Guest(this.props.guest || undefined);
		const isEditMode = defaultValues._id !== undefined;
		const currentUser = stores.userStore.currentUser;
		const { parkingLots } = this.props;
		if (!isEditMode) {
			defaultValues.password = randomPassword();
		}
		return (
			<Form
				onSubmit={this.onSubmitForm}
				mutators={{ ...arrayMutators }}
				initialValues={defaultValues}
				validate={this.validateForm}
				render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
					return (
						<form onSubmit={handleSubmit}>
							<div className="row">
								<div className="col">Full Name*:</div>
								<div className="col">
									<Field name="name" component={BootstrapInput} type="text" />
									<Error name="name" />
								</div>
							</div>
							<div className="row">
								<div className="col">Start Date*:</div>
								<div className="col">
									<Field name="startDate">
										{({ input, meta }) => {
											const value =
												typeof input.value === 'string' && input.value === ''
													? undefined
													: dayjs(input.value).toDate();
											return (
												<DatePicker
													minDate={new Date()}
													selected={value}
													onChange={input.onChange}
													onBlur={input.onBlur}
													onFocus={input.onFocus}
													dateFormat={'dd/MM/yyyy'}
													className="form-control"
												/>
											);
										}}
									</Field>
									<Error name="startDate" />
								</div>
							</div>
							<div className="row">
								<div className="col">End Date*:</div>
								<div className="col">
									<Field name="endDate">
										{({ input, meta }) => {
											const value =
												typeof input.value === 'string' && input.value === ''
													? undefined
													: dayjs(input.value).toDate();
											const minDate = values.startDate === null ? new Date() : values.startDate;
											return (
												<DatePicker
													minDate={minDate}
													selected={value}
													onChange={input.onChange}
													onBlur={input.onBlur}
													onFocus={input.onFocus}
													dateFormat={'dd/MM/yyyy'}
													className="form-control"
												/>
											);
										}}
									</Field>
									<Error name="endDate" />
								</div>
							</div>
							<div className="row">
								<div className="col">Username (phone number)*:</div>
								<div className="col">
									<Field name="phone" component={BootstrapInput} type="text" />
									<Error name="phone" />
								</div>
							</div>
							{!isEditMode && (
								<div className="row">
									<div className="col">Password*:</div>
									<div className="col">
										<Field name="password" component={BootstrapInput} type="text" />
										<Error name="password" />
									</div>
								</div>
							)}
							<div className="row">
								<div className="col">Email:</div>
								<div className="col">
									<Field name="email" component={BootstrapInput} type="text" />
									<Error name="email" />
								</div>
							</div>
							<div className="row">
								<div className="col">ID:</div>
								<div className="col">
									<Field name="customId" component={BootstrapInput} type="text" />
									<Error name="customId" />
								</div>
							</div>
							<div className='row'>
								{currentUser.company.isManagementCompany ?
									<>
										<div className='col'>
											<label htmlFor='managedCompanies'>Managed Companies: </label>
										</div>
										<div className='col'>
											<Field name="managedCompanies" id="managedCompanies" >
												{({ input, meta }) => {
													return (
														<Select
															className="basic-single"
															classNamePrefix="select"
															id="managedCompanies"
															isMulti
															defaultValue={null}
															onChange={this.onCompanySelected}
															options={stores.userStore.currentUser.company.managedCompanies}
														/>
													);
												}}
											</Field>
										</div>
									</>
									: null}
							</div>
							<div className="row">
								<div className="col">Car numbers:</div>
								<div className="col">
									<button type="button" onClick={() => form.mutators.push('carNumbers', undefined)}>
										Add Car number
									</button>
									<FieldArray name="carNumbers">
										{({ fields }) => {
											return fields.map((name, index) => (
												<div key={name} className="row">
													<div className="col-sm-3">
														<label>Car #{index + 1}</label>
													</div>
													<div className="col-sm-8">
														<Field
															name={`${name}`}
															component={BootstrapInput}
															placeholder="12345678"
														/>
													</div>
													<div className="col-sm-1">
														<span
															onClick={() => fields.remove(index)}
															style={{ cursor: 'pointer' }}
														>
															&times;
														</span>
													</div>
													<Error name={`carNumbers[${index}]`} />
												</div>
											));
										}}
									</FieldArray>
								</div>
							</div>
							<div className="row">
								<div className="col">
									{currentUser.company.isWithWorkstation ? 'Locations' : 'Parking Lots'}:
								</div>
								<div className="col">
									<Field name="allowedParks">
										{({ input, meta }) => {
											const onChange = parkId => {
												const idx = input.value.indexOf(parkId);
												if (idx > -1) {
													input.onChange([
														...input.value.slice(0, idx),
														...input.value.slice(idx + 1, input.value.length),
													]);
												} else {
													input.onChange([...input.value, parkId]);
												}
											};
											return (
												<>
													{parkingLots.map(park => (
														<div className="form-group row" key={park.value}>
															<div className="col-sm-12">
																<div className="form-check">
																	<input
																		className="form-check-input"
																		type="checkbox"
																		onChange={() => onChange(park.value)}
																		id={park.value}
																		value={park.value}
																		checked={input.value.includes(park.value)}
																	/>
																	<label className="form-check-label" htmlFor={park.value}>
																		{park.label}
																	</label>
																</div>
															</div>
														</div>
													))}
												</>
											);
										}}
									</Field>
									<Error name="allowedParks" />
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label htmlFor="isActive">Active</label>
								</div>
								<div className="col">
									<Field name="isActive" component={BootstrapInput} type="checkbox" id="isActive" />
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label htmlFor="shouldSendSms">Send SMS to constant guest?:</label>
								</div>
								<div className="col">
									<Field
										name="shouldSentSMS"
										component={BootstrapInput}
										type="checkbox"
										id="shouldSendSms"
									/>
									<Error name="shouldSentSMS" />
								</div>
							</div>
							<button type="submit" disabled={submitting} className="btn btn-primary">
								Save
							</button>
						</form>
					);
				}}
			/>
		);
	}
}

EditGuestModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	guest: PropTypes.object,
	parkingLots: PropTypes.array.isRequired,
};

EditGuestModal.defaultProps = {
	guest: undefined,
};

export default EditGuestModal;
