import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import stores from '../../../../stores';
import DatePicker from 'react-datepicker';
import EmployeesGuestSelector from '../../../../components/EmployeesGuestSelector/EmployeesGuestSelector';
import moment from 'moment';

const { constantEmployeeReportStore } = stores;

class ConstantEmployeeFilters extends Component {
  onSubmitForm = submittedValues => {
    constantEmployeeReportStore.setFromDate(submittedValues.startDate);
    constantEmployeeReportStore.setToDate(submittedValues.endDate);
    constantEmployeeReportStore.setSelectedEmployee(submittedValues.employeeId);
    const employeeId = submittedValues.employeeId ? submittedValues.employeeId._id : undefined;
    this.props.onSearch(Object.assign({}, submittedValues, { employeeId: employeeId }));
  };

  countWeekendDays() {
    const { fromDate, toDate } = constantEmployeeReportStore;
    const momentFromDate = moment(fromDate);
    const momentToDate = moment(toDate);
    let weekendDays = 0;
    for (let day = momentFromDate; day.diff(momentToDate, 'days') < 0; day.add(1, 'day')) {
      if (day.day() === 6 || day.day() === 5) {
        weekendDays++;
      }
    }
    return weekendDays;
  }

  render() {
    const initialValues = {
      startDate: constantEmployeeReportStore.fromDate,
      endDate: constantEmployeeReportStore.toDate,
      employeeId: constantEmployeeReportStore.selectedEmployee,
    };
    const weekendDays = this.countWeekendDays();
    return (
      <div className="reports-filters">
        <Form
          onSubmit={this.onSubmitForm}
          initialValues={initialValues}
          render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <h3 className="text-left">Filters</h3>
                <div className="row col-sm-10 col-sm-offset-1">
                  <div className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-4">
                        <label>Employee</label>
                      </div>
                      <div className="col-sm-8">
                        <Field name="employeeId" component={EmployeesGuestSelector} />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-2">
                        <label>From</label>
                      </div>
                      <div className="col-sm-10">
                        <Field name="startDate">
                          {({ input, meta }) => {
                            return (
                              <DatePicker
                                selected={input.value}
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                onFocus={input.onFocus}
                                maxDate={values.endDate || new Date()}
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-2">
                        <label>To</label>
                      </div>
                      <div className="col-sm-10">
                        <Field name="endDate">
                          {({ input, meta }) => {
                            return (
                              <DatePicker
                                selected={input.value}
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                onFocus={input.onFocus}
                                maxDate={new Date()}
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <button type="submit" disabled={submitting}>
                      Search
                    </button>
                    <button
                      type="button"
                      className="ml-1"
                      onClick={() => this.props.onExportClick(values)}
                    >
                      Export to Excel
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        />
        {weekendDays > 0 && (
          <p>
            Please notice: there are <strong>{weekendDays} weekend days</strong> in this date range
          </p>
        )}
      </div>
    );
  }
}

ConstantEmployeeFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
};

export default ConstantEmployeeFilters;
