export default class CrudRoute {
  constructor(baseRoute, apiInstance) {
    this.baseRoute = baseRoute;
    this.apiInstance = apiInstance;
  }

  getById(id) {
    const url = `${this.baseRoute}/${id}`;
    return this.apiInstance.get(url);
  }

  getAll() {
    const url = `${this.baseRoute}/`;
    return this.apiInstance.get(url);
  }

  create(object) {
    const url = `${this.baseRoute}/`;
    return this.apiInstance.post(url, object);
  }

  update(object) {
    const url = `${this.baseRoute}/${object._id}`;
    return this.apiInstance.put(url, object);
  }

  delete(id) {
    const url = `${this.baseRoute}/${id}`;
    return this.apiInstance.delete(url);
  }
}