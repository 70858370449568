import '@babel/polyfill';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Login from './pages/Login/Login';
import Header from './components/header/Header';
import stores from './stores';
import PrivateRoute from './routes/PrivateRoute';
import Companies from './pages/Companies/Companies';
import Authorization from './routes/Auhtorization';
import Cameras from './pages/Cameras/Cameras';
import AutoReleaseLogs from './pages/AutoReleaseLogs/AutoReleaseLogs';
import SmsLogs from './pages/SmsLogs/SmsLogs';
import Logout from './pages/Logout/Logout';
import Users from './pages/Users/Users';
import Branches from './pages/Branches/Branches';
import Parks from './pages/Parks/Parks';
import Parkings from './pages/Parkings/Parkings';
import Employees from './pages/Employees/Employees';
import Vacations from './pages/Vacations/Vacations';
import Reports from './pages/Reports/Reports';
import LprLogsPerPark from './pages/LprLogsPerPark/LprLogsReport';
import Settings from './pages/Settings/Settings';
import Guests from './pages/Guests/Guests';
import MyDetails from './pages/MyDetails/MyDetails';
import { ADMIN_USER, COMPANY_USER, ROUTES } from './utils/common';
import DPMLoader from './components/Loader/DPMLoader';
import DefaultRoute from './routes/DefaultRoute';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import LPRLogs from './pages/LprLogs/LPRLogs';
import PushNotifications from './pages/PushNotifications/PushNotifications';
import Reservations from './pages/Reservations/Reservations';
import Bookings from './pages/Bookings/Bookings';
import SpecialParkings from './pages/SpecialParkings/SpecialParkings';
import LprDashboard from './pages/LprDashboard/LprDashboard';

const firstChild = props => {
  const childrenArray = React.Children.toArray(props.children);
  return childrenArray[0] || null;
};

@withRouter
@observer
class App extends Component {
  componentDidMount() {
    stores.authStore.checkLogin().finally(() => {
      stores.commonStore.setAppLoaded();
    });
  }

  render() {
    const adminOnly = Authorization(ADMIN_USER);
    const companyOnly = Authorization(COMPANY_USER);

    if (stores.commonStore.appLoaded) {
      return (
        <div className="App">
          <DPMLoader />
          <Header />
          <Switch>
            <Route path={ROUTES.LOGIN} component={Login} />
            <Route path="/logout" component={Logout} />
            {/*<PrivateRoute path="/settings{/*}" component={Settings} />*/}
            {/*<PrivateRoute path="/users" children={({ match, ...rest }) => (
              <TransitionGroup component={firstChild}>
                {match && <AdminUsers {...rest} />}
              </TransitionGroup>
            )} />*/}
            <PrivateRoute path={ROUTES.USERS} component={adminOnly(Users)} />
            <PrivateRoute path={ROUTES.COMPANIES} component={adminOnly(Companies)} />
            <PrivateRoute path="/cameras/:companyId" component={adminOnly(Cameras)} />
            <PrivateRoute path="/autoRelease" component={adminOnly(AutoReleaseLogs)} />
            <PrivateRoute path="/smsLogs" component={adminOnly(SmsLogs)} />
            <PrivateRoute path="/lprLogs" component={adminOnly(LPRLogs)} />
            <PrivateRoute path={ROUTES.BRANCHES} component={companyOnly(Branches)} />
            <PrivateRoute path={ROUTES.PARKING_LOTS} component={companyOnly(Parks)} />
            <PrivateRoute path={ROUTES.PARKINGS} component={companyOnly(Parkings)} />
            <PrivateRoute path={ROUTES.EMPLOYEES} component={companyOnly(Employees)} />
            <PrivateRoute path={ROUTES.VACATIONS} component={companyOnly(Vacations)} />
						<PrivateRoute path={ROUTES.ADMIN_VACATIONS} component={adminOnly(Vacations)}/>
            <PrivateRoute path="/reports" component={companyOnly(Reports)} />
            <PrivateRoute path="/logs-per-park/:parkId" component={companyOnly(LprLogsPerPark)} />
            <PrivateRoute path={ROUTES.RESERVATIONS} component={companyOnly(Reservations)} />
            <PrivateRoute path={ROUTES.SPECIAL_PARKINGS} component={companyOnly(SpecialParkings)} />
            <PrivateRoute path={ROUTES.BOOKINGS} component={companyOnly(Bookings)} />
            <PrivateRoute path={ROUTES.SETTINGS} component={companyOnly(Settings)} />
            <PrivateRoute path={ROUTES.GUESTS} component={companyOnly(Guests)} />
            <PrivateRoute path={ROUTES.MY_DETAILS} component={companyOnly(MyDetails)} />
            <PrivateRoute path={ROUTES.LPR_DASHBOARD} component={companyOnly(LprDashboard)} />
            <PrivateRoute
              path={ROUTES.PUSH_NOTIFICATIONS}
              component={companyOnly(PushNotifications)}
            />
            <Route path="/reset" component={ResetPassword} />
            <Route path="/" component={DefaultRoute} />
          </Switch>
          <ToastContainer />
        </div>
      );
    }
    return <Header />;
  }
}

export default App;
