import React, { Component } from 'react';
import './users.style.scss';
import stores from '../../stores';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { changePasswordIcon, deleteIcon, editIcon } from '../../assets';
import Modal from '../../components/modal/Modal';
import EditUserModal from './UsersComponents/EditUserModal/EditUserModal';
import { toast } from 'react-toastify';
import DataTable from '../../components/DataTable/DataTable';
import { generateOption, ROLES } from '../../utils/common';

const { usersStore, usersViewStore, companiesStore } = stores;

const rolesList = [
	generateOption('Admin', ROLES.ADMIN),
	generateOption('Company', ROLES.COMPANY),
	generateOption('Senior Secretary', ROLES.SENIOR_SECRETARY),
	generateOption('Secretary', ROLES.SECRETARY),
];

const defaultFilterFunction = (filter, row) => {
	if (filter.value.length > 0 && !row[filter.id]) return false;
	return String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
};

@withRouter
@observer
class Users extends Component {

	constructor(props) {
		super(props);
	}

	onEditRow = (row) => {
		usersViewStore.editUser(row._original);
	};

	onDeleteRow = (row) => {
		const answer = window.confirm('Are you sure you want to delete this forever?');
		if (answer) {
			usersStore.delete(row._original._id);
		}
	};

	onChangePassword = (row) => {
		const newPassword = window.prompt('New Password', 'Type new password ...');
		if (newPassword !== null && newPassword !== 'Type new password ...') {
			// call api to change company's user password
			usersStore.changePassword(row._original._id, newPassword).then(response => {
				toast('Password has changed!', { type: toast.TYPE.SUCCESS });
			}).catch(err => {
				if (err && err.data && err.data.message) {
					toast(err.data.message, { type: toast.TYPE.ERROR });
				}
			});
		}
	};

	render() {
		const columns = [
			{
				Header: 'User ID',
				accessor: 'idNumber',
			}, {
				Header: 'Username',
				accessor: 'username',
				filterMethod: defaultFilterFunction
			}, {
				Header: 'Company',
				accessor: 'company.name',
				Cell: ({ original, value }) => {
					if (original && original.company) {
						return <Link to={`companies/${original.company._id}`}>{value}</Link>;
					}
					return null;
				},
				filterMethod: defaultFilterFunction
			}, {
				Header: 'Type',
				accessor: 'type',
				Filter: ({ filter, onChange }) => {
					return (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: '100%' }}
							value={filter ? filter.value : ''}
						>
							<option value="">All</option>
							{rolesList.map(option => {
								return <option value={option.value} key={option.value}>{option.label}</option>;
							})}
						</select>
					);
				},
			},
			{
				Header: 'Full name',
				accessor: 'name',
				filterMethod: defaultFilterFunction

			}, {
				Header: 'Phone',
				accessor: 'phone',
				filterMethod: defaultFilterFunction
			}, {
				Header: 'Change password',
				Cell: ({ row }) => <img src={changePasswordIcon}
																onClick={this.onChangePassword.bind(this, row)} />,
				sortable: false,
				filterable: false,
			}, {
				Header: 'Edit',
				Cell: ({ row }) => <img src={editIcon} onClick={this.onEditRow.bind(this, row)} />,
				sortable: false,
				filterable: false,
			}, {
				Header: 'Delete',
				Cell: ({ row }) => <img src={deleteIcon} onClick={this.onDeleteRow.bind(this, row)} />,
				sortable: false,
				filterable: false,
			},
		];
		const { isEditModalShown, newUser, hideEditModal, selectedUser } = usersViewStore;
		return (
			<div className="users-page full-height">
				<h1 className="page-title">Users</h1>
				<div className="actions">
					<button type="button" onClick={newUser}>New User</button>
				</div>
				<DataTable
					filterable
					data={usersStore.list.slice()}
					columns={columns}
				/>
				<Modal title="New User" show={isEditModalShown} handleClose={hideEditModal}>
					<EditUserModal user={selectedUser} closeModal={hideEditModal} />
				</Modal>
			</div>
		);
	}
}

export default Users;
