import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import stores from '../../../../stores';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import dayjs from 'dayjs';

const { parksStore, efficiencyReportStore, userStore } = stores;

class EfficiencyFilters extends Component {
  onSubmitForm = submittedValues => {
    efficiencyReportStore.setFromDate(submittedValues.startDate);
    efficiencyReportStore.setToDate(submittedValues.endDate);
    this.props.onSearch(submittedValues);
  };

  render() {
    const currentUser = userStore.currentUser;
    const { list } = parksStore;
    const parkingLotsList = list.slice().map(item => ({ label: item.name, value: item._id }));
    const initialValues = {
      startDate: dayjs()
        .startOf('month')
        .toDate(),
      endDate: new Date(),
    };
    return (
      <div className="reports-filters">
        <Form
          onSubmit={this.onSubmitForm}
          initialValues={initialValues}
          keepDirtyOnReinitialize={true}
          render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <h3 className="text-left">Filters</h3>
                <div className="row col-sm-10 col-sm-offset-1">
                  <div className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-4">
                        <label>{currentUser.company.isWithWorkstation ? 'Location' : 'Parking Lot'}</label>
                      </div>
                      <div className="col-sm-8">
                        <Field name="parkingLotId">
                          {({ input, meta }) => {
                            return (
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                {...input}
                                value={parkingLotsList.find(
                                  ({ value }) => value === input.value._id,
                                )}
                                onChange={({ value }) => input.onChange(value)}
                                options={parkingLotsList.slice()}
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-2">
                        <label>From</label>
                      </div>
                      <div className="col-sm-10">
                        <Field name="startDate">
                          {({ input, meta }) => {
                            return (
                              <DatePicker
                                selected={input.value}
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                onFocus={input.onFocus}
                                maxDate={values.endDate || new Date()}
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="row">
                      <div className="col-sm-2">
                        <label>To</label>
                      </div>
                      <div className="col-sm-10">
                        <Field name="endDate">
                          {({ input, meta }) => {
                            return (
                              <DatePicker
                                selected={input.value}
                                onChange={input.onChange}
                                onBlur={input.onBlur}
                                onFocus={input.onFocus}
                                maxDate={new Date()}
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <button type="submit" disabled={submitting}>
                      Search
                    </button>
                    <button
                      type="button"
                      className="ml-1"
                      onClick={() => this.props.onExportClick(values)}
                    >
                      Export to Excel
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

EfficiencyFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
};

export default EfficiencyFilters;
