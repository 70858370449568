import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import Error from '../../../../components/forms/Error';
import stores from '../../../../stores';
import EmployeesGuestSelector from '../../../../components/EmployeesGuestSelector/EmployeesGuestSelector';
import BootstrapInput from '../../../../components/BootstrapInput/BootstrapInput';
import { OnChange } from 'react-final-form-listeners';
import CarNumberSelector from '../../../../components/CarNumberSelector/EmployeesGuestSelector';

const { parkingsStore } = stores;

class HoldParking extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedEmployee: null
    }
  };

  reset = () => {
    this.setState({
      selectedEmployee: null
    })
  };

  validateForm = (values) => {
    const errors = {};
    if (!values.employee) {
      errors.employee = 'Name is required';
    }
    else if (values.employee.length && values.employee.length === 0) {
      errors.employee = 'Name is required';
    }
    if (!values.phone) {
      errors.phone = 'Phone is required';
    }
    else if (values.phone.length > 10) {
      errors.phone = 'Phone number cannot be more then 10 digits';
    }
    return errors;
  };

  onSubmitForm = (submittedValues) => {
    const parker = {
      name: submittedValues.employee.name || submittedValues.employee,
      phone: submittedValues.phone,
      carNumber: submittedValues.employee.carNumber || submittedValues.employeeByCarNumber,
      _id: submittedValues.employee._id
    };
    parkingsStore.holdParking(this.props.parking, parker)
      .then(response => {
        if (response.results > 0) {
          this.reset();
          this.props.closeModal(true);
        }
        else {
          if (response.results === -1) {
            alert("This parking is no longer available, please choose another parking.");
          }
          else if (response.results === -4) {
            alert("This Employee is already parking");
          }
          else {
            alert("Error ...Please try again.");
          }
        }
      })
      .catch(err => {
        console.error(err);
      })
  };

  render() {
		const { selectedEmployee } = this.state;
		const defaultValues = {};
		if (selectedEmployee) {
			defaultValues.employee = selectedEmployee;
			defaultValues.phone = selectedEmployee.phone;
			defaultValues.employeeByCarNumber = selectedEmployee.carNumber;
		} else {
			defaultValues.phone = '';
		}
		const shouldTranslate = this.props.displayLanguageModel === 'he';

		const labels = {
			name: shouldTranslate ? 'שם:*' : 'Name*:',
			phone: shouldTranslate ? 'טלפון:*' : 'Phone*:',
			carNumber: shouldTranslate ? 'מספר רכב:' : 'Car Number:',
			saveChanges: shouldTranslate ? 'שמור שינויים' : 'Save Changes',
		};

		return (
			<Form
				onSubmit={this.onSubmitForm}
				initialValues={defaultValues}
				validate={this.validateForm}
				render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
					return (
						<form onSubmit={handleSubmit}>
							<div className="editParking">
								<div className="row">
									<div className="col">{labels.name}</div>
									<div className="col">
										<Field name="employee" component={EmployeesGuestSelector} />
										<Error name="employee" />
									</div>
								</div>
								<div className="row">
									<div className="col">{labels.phone}</div>
									<div className="col">
										<Field name="phone" component={BootstrapInput} type="text" maxLength={10} />
										<Error name="phone" />
									</div>
								</div>
								<div className="row">
									<div className="col">{labels.carNumber}</div>
									<div className="col">
										<Field name="employeeByCarNumber" component={CarNumberSelector} />
										<Error name="employeeByCarNumber" />
									</div>
								</div>
								<div className="row center">
									<button type="submit" disabled={submitting} className="btn btn-primary">
										{labels.saveChanges}
									</button>
								</div>
								<OnChange name="employee">
									{(value, previous) => {
										this.setState({ selectedEmployee: value });
									}}
								</OnChange>
								<OnChange name="employeeByCarNumber">
									{(value, previous) => {
										if (value && value.carNumber) {
											this.setState({ selectedEmployee: value });
										}
									}}
								</OnChange>
							</div>
						</form>
					);
				}}
			/>
		);

	}
}

HoldParking.propTypes = {
  parking: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
	displayLanguageModel : PropTypes.string.isRequired
};

export default HoldParking;
