import { action } from 'mobx';
import API from '../../utils/API/api';
import CrudStore from './crudStore';
import { ROLES } from '../../utils/common';

const defaultPermissions = [ROLES.ADMIN];

const permissions = {
  getById: defaultPermissions,
  getAll: defaultPermissions,
  create: defaultPermissions,
  update: defaultPermissions,
  delete: defaultPermissions
};

class UsersStore extends CrudStore {

  constructor(stores) {
    super(stores, API.admin.users, permissions);
    stores.userStore.onAdminUserLoaded((user) => {
      this.loadUsers();
      stores.companiesStore.loadCompanies();
    })
  }

  @action
  loadUsers() {
    this.commonStore.setIsLoading(true);
    this.api.getAll().then(action(response => {
      this.list.replace(response.results);
      this.commonStore.setIsLoading(false);
    })).catch(() => {
      this.commonStore.setIsLoading(false);
    })
  }

  @action
  changePassword(userId, newPassword) {
    return this.api.changePassword(userId, newPassword);
  }
}

export default UsersStore;