import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import stores from '../../../stores';
import Error from '../../../components/forms/Error';
import Vacation from '../../../models/Vacation';
import DatePicker from 'react-datepicker';
import BootstrapInput from '../../../components/BootstrapInput/BootstrapInput';
import dayjs from 'dayjs';


class EditVacationModal extends Component {
  constructor(props) {
    super(props);
  }

  onSubmitForm = (submittedValues) => {
    let vacation = new Vacation(submittedValues);
    const isEditMode = this.props.vacation && this.props.vacation._id;
    if (!isEditMode) {
			// eslint-disable-next-line no-unused-expressions
			stores.userStore.isAdmin ? vacation= {...vacation, comments:vacation.comments+'-admin'} : {};
      stores.vacationsStore.create(vacation).then((response) => {
        this.props.closeModal();
      }).catch(err => {
        console.error(err);
      })
    }
    else {
      stores.vacationsStore.update(vacation).then((response) => {
        this.props.closeModal();
      }).catch(err => {
        console.error(err);
      })
    }
  };

  validateForm = (values) => {
    const errors = {};
    if (!values.startTime) {
      errors.startTime = 'Start Date is required';
    }
    if (!values.endTime) {
      errors.endTime = 'End Date is required';
    }
    if (values.startTime && values.endTime) {
      if (dayjs(values.startTime).isAfter(dayjs(values.endTime))) {
        errors.startTime = 'Start time cannot be after end time';
      }
    }
    return errors
  };

  render() {
    const defaultValues = new Vacation(this.props.vacation || undefined);
    return (
      <Form onSubmit={this.onSubmitForm} initialValues={defaultValues} validate={this.validateForm}
            render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">Start Date*:</div>
                    <div className="col">
                      <Field name="startTime">
                        {({ input, meta }) => {
                          return (
                            <DatePicker
                              selected={input.value}
                              {...input}
                              minDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              autoComplete="off"
                            />
                          )
                        }
                        }
                      </Field>
                      <Error name="startTime" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">End Date*:</div>
                    <div className="col">
                      <Field name="endTime">
                        {({ input, meta }) => {
                          return (
                            <DatePicker
                              selected={input.value}
                              {...input}
                              minDate={values.startTime}
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              autoComplete="off"
                            />
                          )
                        }
                        }
                      </Field>
                      <Error name="endTime" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">Notes:</div>
                    <div className="col">
                      <Field name="comments" component={BootstrapInput} type="text" />
                      <Error name="comments" />
                    </div>
                  </div>
                  <button type="submit" disabled={submitting}>
                    Save
                  </button>
                </form>
              )
            }} />
    );
  }
}

EditVacationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  employee: PropTypes.object,
};

EditVacationModal.defaultProps = {
  employee: undefined,
};

export default EditVacationModal;
