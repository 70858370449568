import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import stores from '../../../stores';
import Error from '../../../components/forms/Error';
import Park from '../../../models/Park';
import Select from 'react-select';
import { observer } from 'mobx-react';
import BootstrapInput from '../../../components/BootstrapInput/BootstrapInput';

const LAT_IDX = 0;
const LNG_IDX = 1;

@observer
class SpecialParkingsModal extends Component {
  constructor(props) {
    super(props);
  }

  onSubmitForm = submittedValues => {
    const park = new Park(submittedValues);
    const isEditMode = this.props.park && this.props.park._id;
    if (!isEditMode) {
      stores.parksStore
        .create(park)
        .then(response => {
          this.props.closeModal();
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      stores.parksStore
        .update(park)
        .then(response => {
          this.props.closeModal();
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  validateForm = values => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Park name is required';
    }
    if (!values.location) {
      errors.location = 'Location is required';
    } else {
      errors.location = {
        coordinates: [],
      };
      if (!values.location.coordinates[LAT_IDX] && values.location.coordinates[LAT_IDX] !== 0) {
        errors.location.coordinates[LAT_IDX] = 'Latitude is required';
      }
      if (!values.location.coordinates[LNG_IDX] && values.location.coordinates[LNG_IDX] !== 0) {
        errors.location.coordinates[LNG_IDX] = 'Longitude is required';
      }
    }
    /*if (!values.distanceThreshold) {
      errors.distanceThreshold = 'Hold Range is required';
    }
    if (!values.geoFenceRange) {
      errors.geoFenceRange = 'Release Range is required';
    }*/
    if (!values.branch) {
      errors.branch = 'Branch is required';
    }
    return errors;
  };

  render() {
    const defaultValues = new Park(this.props.park);
    const { branchesDropdownOptions } = stores.branchesStore;
    return (
      <Form
        onSubmit={this.onSubmitForm}
        initialValues={defaultValues}
        validate={this.validateForm}
        render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">Name*:</div>
                <div className="col">
                  <Field name="name" component={BootstrapInput} type="text" />
                  <Error name="name" />
                </div>
              </div>
              <div className="row">
                <div className="col">Latitude:</div>
                <div className="col">
                  <Field name="location.coordinates[0]" component={BootstrapInput} type="text" />
                  <Error name="location.coordinates[0]" />
                </div>
              </div>
              <div className="row">
                <div className="col">Longitude:</div>
                <div className="col">
                  <Field name="location.coordinates[1]" component={BootstrapInput} type="text" />
                  <Error name="location.coordinates[1]" />
                </div>
              </div>
              <div className="row">
                <div className="col">Hold Range (in meters):</div>
                <div className="col">
                  <Field name="distanceThreshold" component={BootstrapInput} type="text" />
                  <Error name="distanceThreshold" />
                </div>
              </div>
              <div className="row">
                <div className="col">Release Range (in meters):</div>
                <div className="col">
                  <Field name="geoFenceRange" component={BootstrapInput} type="text" />
                  <Error name="geoFenceRange" />
                </div>
              </div>
              <div className="row">
                <div className="col">Branch*:</div>
                <div className="col">
                  <Field name="branch">
                    {({ input, meta }) => {
                      return (
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          {...input}
                          value={branchesDropdownOptions.find(({ value }) => value === input.value)}
                          onChange={({ value }) => input.onChange(value)}
                          options={branchesDropdownOptions}
                        />
                      );
                    }}
                  </Field>
                  <Error name="branch" />
                </div>
              </div>
              <div className="row">
                <div className="col">Preference:</div>
                <div className="col">
                  <Field name="preference">
                    {({ input, meta }) => {
                      return (
                        <input
                          type="text"
                          placeholder="Preferrence (used in LPR companies)..."
                          {...input}
                          className="form-control d-inline"
                        />
                      );
                    }}
                  </Field>
                  <Error name="preference" />
                </div>
              </div>
              <div className="row">
                <div className="col" />
                <div className="col">
                  <a
                    href={`https://www.google.com/maps/place/${values.location.coordinates[0]},${
                      values.location.coordinates[1]
                    }`}
                    target="_blank"
                  >
                    Show in map
                  </a>
                </div>
              </div>
              <button type="submit" disabled={submitting}>
                Save
              </button>
            </form>
          );
        }}
      />
    );
  }
}

SpecialParkingsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  park: PropTypes.object,
};

SpecialParkingsModal.defaultProps = {
  park: undefined,
};

export default SpecialParkingsModal;
