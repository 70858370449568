import { PERFORMED_BY } from '../../../utils/common';

export default function PerformedBy(props) {
  switch (props.by) {
    case PERFORMED_BY.CRON:
      return 'Automatic';
    case PERFORMED_BY.APP:
      return 'App';
    case PERFORMED_BY.WEB:
      return 'Web Admin';
    case PERFORMED_BY.SMS:
      return 'SMS';
    case PERFORMED_BY.LPR:
      return 'LPR';
		case PERFORMED_BY.OCCUPIED:
      return 'Occupied';
    default:
      return props.by || null;
  }

}
