import dayjs from 'dayjs';
import {action, observable} from 'mobx';
import React from 'react';
// import {Link} from 'react-router-dom';
import API from '../../../utils/API/api';
import ReportsStore from '../reportsStore';
// import {ROUTES} from '../../../utils/common';

const tableColumns = [
	{
		Header: 'Date',
		accessor: 'createdAt',
		Cell: ({ value }) => {
			return dayjs(value).format('YYYY-MM-DD HH:mm (dddd)');
		},
	},
	// {
	// 	id:'parker',
	// 	Header: 'Employee Name',
	// 	accessor: row => row.employee || row.guest,
	// 	Cell: (row) => {
	// 		if (row.value) {
	// 			const isEmployee = !!row.original.employee;
	// 			const linkUrl = isEmployee ? `${ROUTES.EMPLOYEES}/${row.value._id}` : `${ROUTES.GUESTS}/${row.value._id}`;
	// 			return row.value._id && <Link to={linkUrl}>{row.value.name}</Link> || '';
	// 		}
	// 		return '';
	// 	},
	// },
	{
		Header: 'Car Number',
		accessor: 'carNumber',
	},
	{
		Header: 'Camera',
		accessor: 'camera',
	},
	{
		Header: 'Plate',
		accessor: 'plateImage',
		Cell: ({ value }) => {
			return value && <img src={value} style={{ height: '40px' }} />;
		}
	},
	{
		Header: 'Status',
		accessor: 'statusMessage',
	},
];

class LprLogsNotUsersReportStore extends ReportsStore {
  @observable selectedCarNumber;

  constructor(stores) {
		super(stores, tableColumns, API.company.reports.getLprLogsNotUsersReport, API.company.reports.exportLprLogsNotUsersReport)
		this.fromDate = dayjs().subtract(1, 'week').toDate();
    this.toDate = new Date();
  }

  @action
  setselectedCarNumber = (carNumber) => {
    this.selectedCarNumber = carNumber;
  };
}

export default LprLogsNotUsersReportStore;
