import React, { Component } from 'react';
import stores from '../../../../stores';
import { observer } from 'mobx-react';
import DataTable from '../../../../components/DataTable/DataTable';
import EmployeesFilters from './EmployeesFilters';

const { employeesReportStore } = stores;

@observer
class EmployeesReport extends Component {
  componentDidMount() {
    employeesReportStore.getReport();
  }

  onExportClick = (submittedValues) => {
    const params = this.parseParams(submittedValues);
    employeesReportStore.exportReport(params);
  };

  parseParams = (submittedValues) => {
    let params = {};
    if (submittedValues.startDate) {
      params.startDate = submittedValues.startDate.toISOString();
    }
    if (submittedValues.endDate) {
      params.endDate = submittedValues.endDate.toISOString();
    }
    if (submittedValues.parking) {
      params.parking = submittedValues.parking;
    }
    if (submittedValues.parkingLotId) {
      params.parkingLotId = submittedValues.parkingLotId;
    }
    if (submittedValues.employee) {
      params.employeeId = submittedValues.employee._id;
    }
    if (submittedValues.guest) {
      params.guestId = submittedValues.guest._id;
    }
    return params;
  };

  onFiltersSearch = (submittedValues) => {
    const params = this.parseParams(submittedValues);
    employeesReportStore.getReport(params);
  };

  render() {
    return (
      <div>
        <h1 className="page-title">Employees Report</h1>
        <div className="actions">
          <EmployeesFilters onSearch={this.onFiltersSearch} onExportClick={this.onExportClick} />
        </div>
        <DataTable columns={employeesReportStore.tableColumns} data={employeesReportStore.currentReport.slice()} />
      </div>
    );
  }
}

export default EmployeesReport;
