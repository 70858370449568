import { action, computed, observable, reaction } from 'mobx';
import { matchPath } from 'react-router-dom';

function getDefaultPathParams(pathname) {
  return {
    path: pathname
  };
}

class CommonStore {

  @observable appName = 'DPM';
  @observable appLoaded = false;
  @observable isLoading = false;
  @observable location;

  @action
  setAppLoaded() {
    this.appLoaded = true;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  _updateLocation(location) {
    this.location = location;
  }

  @computed
  get locationPathname() {
    return this.location && this.location.pathname;
  }

  onLocationChanged = (callback, params) => {
    reaction(
      () => this.location,
      (location) => {
        const path = matchPath(location.pathname, params || getDefaultPathParams(location.pathname));
        // console.log(path);
        if (path) {
          callback(path)
        }
        /*else {
          console.error('this url has no path');
        }*/
      }
    )
  }
}

export default CommonStore;