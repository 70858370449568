import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from '../../stores';
import Select from 'react-select';
import DataTable from '../../components/DataTable/DataTable';
import dayjs from 'dayjs';

const { lprLogsViewStore, companiesStore } = stores;

@withRouter
@observer
class LPRLogs extends Component {
  componentDidMount() {
    lprLogsViewStore.loadLprLogs();
  }

  onMonthSelected = (selectedMonth) => {
    lprLogsViewStore.setSelectedMonth(selectedMonth);
  };

  onCompanySelected = (selectedCompany) => {
    lprLogsViewStore.setSelectedCompany(selectedCompany);
  };

  onSearch = () => {
    lprLogsViewStore.loadLprLogs();
  };

  render() {
    const { lprLogsList, monthsList, selectedMonth, selectedCompany } = lprLogsViewStore;
    const companiesList = companiesStore.companiesList.map(c => ({ value: c._id, label: c.name }));
    const columns = [
      {
        Header: 'Date',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          return dayjs(value).format('YYYY-MM-DD HH:mm (dddd)')
        },
      }, {
        Header: 'Company Name',
        accessor: 'company',
        Cell: ({value}) => value && value.name || ''
      }, {
        Header: 'Camera',
        accessor: 'camera',
        Cell: ({value}) => {
          return value && <span>{value.name} [{value.type.toUpperCase()}]</span> || ''
        }
      },{
        Header: 'Car Number',
        accessor: 'carNumber',
      }
    ];
    return (
      <div className="sms-logs-page full-height">
        <h1 className="page-title">LPR Logs</h1>
        <div className="actions">
          <div className="row">
            <div className="col-sm-2">
              Month
            </div>
            <div className="col-sm-2">
              <Select
                className="basic-single"
                classNamePrefix="select"
                name="company"
                value={selectedMonth}
                onChange={this.onMonthSelected}
                options={monthsList.slice()}
              />
            </div>
            <div className="col-sm-2">
              Company
            </div>
            <div className="col-sm-2">
              <Select
                className="basic-single"
                classNamePrefix="select"
                name="company"
                value={selectedCompany}
                isClearable={true}
                onChange={this.onCompanySelected}
                options={companiesList.slice()}
              />
            </div>
            <div className="col-sm-2">
              <button onClick={this.onSearch}>Search</button>
            </div>
          </div>
        </div>
        <DataTable
          data={lprLogsList.slice()}
          columns={columns}
          SubComponent={row => {
            return (
              <div style={{ padding: "20px" }}>
                <p>Headers</p>
                <pre>
                  {JSON.stringify(row.original.content.headers)}
                </pre>
                <p>Query</p>
                <pre>
                  {JSON.stringify(row.original.content.query)}
                </pre>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

export default LPRLogs;
