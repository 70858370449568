export function getApiUrlEnvironment() {
  // analyze the first sub domain
  let domain = window.location.host;
  let urls = {
    'admin.dpm-parking.com': '//admin.dpm-parking.com/api/',
    'dev.admin.dpm-parking.com': '//dev.admin.dpm-parking.com/api/'
  };

  if (process.env.NODE_ENV !== 'production') {
    urls['dev'] = '//192.168.68.119:3000/api';
    urls['localhost:3001'] = '//localhost:3000/api';
    urls['185.118.252.29'] = '//185.118.252.29/api/';
    urls['62.90.154.227'] = '//62.90.154.227/api/';
  }

  if (urls[domain.toLowerCase()] === undefined) {
    domain = 'dev';
  }

  let returnedValue = urls[domain.toLowerCase()];
  return returnedValue;
}
