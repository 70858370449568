import { action } from 'mobx';
import dayjs from 'dayjs';
import React from 'react';
import API from '../../../utils/API/api';
import ReportsStore from '../reportsStore';

const MAP_RESPONSE = {
  not_responded: 'Not Responded',
  arrive: 'Arrived',
  does_not_arrive: 'Doesn\'t Arrived'
};

const tableColumns = [
  {
    Header: 'Date',
    accessor: 'updatedAt',
    Cell: ({ row, value }) => {
      const date = dayjs(value);
      return (
        <span>{date.format('DD/MM/YYYY HH:mm')} [{date.format('ddd')}]</span>
      )
    }
  },
  {
    Header: 'Employee',
    accessor: 'employee'
  }, {
    Header: 'Response',
    accessor: 'response',
    Cell: ({ value }) => MAP_RESPONSE[value],
    Footer: (columnProps) => {
      return (
        <span>
          <p><strong>Arrived: </strong>{columnProps.data.filter(r => r.response === "arrive").length}</p>
          <p><strong>Didn't Arrived: </strong>{columnProps.data.filter(r => r.response === "does_not_arrive").length}</p>
          <p><strong>Not Responded: </strong>{columnProps.data.filter(r => r.response === "not_responded").length}</p>
        </span>
      )
    },
  }
];

class ArrivalLogsReportStore extends ReportsStore {
  constructor(stores) {
    super(stores, tableColumns, API.company.reports.getArrivalLogsReport, API.company.reports.exportArrivalLogsReport)
  }

  @action
  setSelectedEmployee(selectedEmployee) {
    this.selectedEmployee = selectedEmployee;
  }
}

export default ArrivalLogsReportStore;
