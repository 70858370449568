import React from 'react';
import stores from '../stores/index';

const Authorization = (allowedRoles) => {
  return (WrappedComponent) => {
    return class WithAuthorization extends React.Component {
      constructor(props) {
        super(props);
      }

      render() {
        const { currentUser } = stores.userStore;

        if (currentUser && allowedRoles.includes(currentUser.type)) {
          return <WrappedComponent {...this.props} />
        } else {
          return <h1>No page for you!</h1>
        }
      }
    }
  }
};

export default Authorization;