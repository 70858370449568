import React, { Component } from 'react';
import stores from '../../stores';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { deleteIcon, editIcon, mapIcon } from '../../assets';
import Modal from '../../components/modal/Modal';
import EditParkModal from './ParksComponents/EditParkModal';
import DataTable from '../../components/DataTable/DataTable';

const { parksStore, branchesStore, userStore } = stores;

@withRouter
@observer
class Parks extends Component {
	constructor(props) {
		super(props);
	}

	onEditRow = row => {
		parksStore.editBranch(row._original);
	};

	onDeleteRow = row => {
		const answer = window.confirm('Are you sure you want to delete this forever?');
		if (answer) {
			parksStore.delete(row._original._id);
		}
	};

	onMapClick = row => {
		// redirect to map view
		const park = row._original;
		window.open(
			`https://www.google.com/maps/place/${park.location.coordinates[1]},${
				park.location.coordinates[0]
			}`,
			'_blank',
		);
	};

	onNewParkClick = () => {
		parksStore.newPark();
	};

	render() {
		const { list, isEditModalShown, hideEditModal, selectedPark } = parksStore;
		const currentUser = userStore.currentUser;
		const columns = [
			{
				Header: 'Pref.',
				accessor: 'preference',
				Footer: columnProps => {
					return (
						<span>
							<strong>Total: </strong>
							{columnProps.data.length}
						</span>
					);
				},
			},
			{
				Header: currentUser.company.isWithWorkstation ? 'Location Name' : 'Parking Lot Name',
				accessor: 'name',
				filterMethod: (filter, row) => {
					if (!row['name']) return false;
					return row['name'].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
				},
			},
			{
				Header: currentUser.company.isWithWorkstation ? 'Location ID' : 'Parking ID',
				accessor: '_id',
			},
			{
				Header: 'Hold Range',
				accessor: 'distanceThreshold',
				Cell: ({ value }) => `${new Intl.NumberFormat('he-IL').format(value)}m`,
			},
			{
				Header: 'Release Range',
				accessor: 'geoFenceRange',
				Cell: ({ value }) => `${new Intl.NumberFormat('he-IL').format(value)}m`,
			},
			{
				Header: 'Map',
				Cell: ({ row }) => <img src={mapIcon} onClick={this.onMapClick.bind(this, row)} />,
				sortable: false,
				filterable: false,
				width: 80,
			},
			{
				Header: 'Edit',
				Cell: ({ row }) => <img src={editIcon} onClick={this.onEditRow.bind(this, row)} />,
				sortable: false,
				filterable: false,
				width: 80,
			},
			{
				Header: 'Delete',
				Cell: ({ row }) => <img src={deleteIcon} onClick={this.onDeleteRow.bind(this, row)} />,
				sortable: false,
				filterable: false,
				width: 80,
			},
		];

		return (
			<div className="full-height">
				<h1 className="page-title">
					{currentUser.company.isWithWorkstation ? 'Locations' : 'Parking Lots'}
				</h1>
				<div className="actions">
					<button type="button" onClick={this.onNewParkClick}>
						{currentUser.company.isWithWorkstation ? 'New Location' : 'New Parking Lot'}
					</button>
				</div>
				<DataTable
					filterable
					data={list.slice()}
					columns={columns}
					defaultSort={[
						{
							id: 'preference',
							desc: false,
						},
					]}
				/>
				<Modal
					title={currentUser.company.isWithWorkstation ? 'New Location' : 'New Parking Lot'}
					show={isEditModalShown}
					handleClose={hideEditModal}
				>
					<EditParkModal park={selectedPark} closeModal={hideEditModal} />
				</Modal>
			</div>
		);
	}
}

Parks.propTypes = {
	//myProp: PropTypes.object.isRequired
};

Parks.defaultProps = {
	//myProp: <defaultValue>
};

export default Parks;
