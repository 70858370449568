import CrudRoute from '../crudRoute';

class AdminCompaniesApi extends CrudRoute {
  constructor(apiInstance) {
    super('admin/companies', apiInstance);
  }

  getSmsCredit() {
    const url = `admin/smsCredit`;
    return this.apiInstance.get(url).then(response => response.smsQuantity);
  }
}

class AdminBranchesApi extends CrudRoute {
  constructor(apiInstance) {
    super('admin/branches', apiInstance);
  }

  getByCompanyId(companyId) {
    if (!companyId) return null;
    const url = `${this.baseRoute}/byCompany/${companyId}`;
    return this.apiInstance.get(url);
  }
}

class AdminUsersApi extends CrudRoute {
  constructor(apiInstance) {
    super('admin/users', apiInstance);
  }

  changePassword(userId, newPassword) {
    const url = `${this.baseRoute}/${userId}/changePassword`;
    return this.apiInstance.put(url, { newPassword });
  }
}

class CamerasApi extends CrudRoute {
  constructor(apiInstance) {
    super('admin/cameras', apiInstance);
  }

  getByCompanyId(companyId) {
    if (!companyId) return null;
    const url = `${this.baseRoute}/company/${companyId}`;
    return this.apiInstance.get(url);
  }
}

class LogsApi {
  constructor(apiInstance) {
    this.apiInstance = apiInstance;
  }

  getAutoReleaseLogs(fromDate, toDate, companyId) {
    const url = `admin/logs/autoRelease`;
    return this.apiInstance.get(url);
  }

  getSmsLogs(fromDate, toDate, companyId) {
    let url = `admin/logs/sms?from=${fromDate}&to=${toDate}`;
    if (companyId) {
      url += `&companyId=${companyId}`;
    }
    return this.apiInstance.get(url);
  }

  getLprLogs(fromDate, toDate, companyId) {
    let url = `admin/logs/lpr?from=${fromDate}&to=${toDate}`;
    if (companyId) {
      url += `&companyId=${companyId}`;
    }
    return this.apiInstance.get(url);
  }
}

class AdminParksApi extends CrudRoute {
	constructor(apiInstance) {
		super('admin/parks', apiInstance);
	}

	getByCompanyId(companyId) {
		if (!companyId) return null;
		const url = `${this.baseRoute}/byCompany/${companyId}`;
		return this.apiInstance.get(url);
	}
}

class AdminApi {
  constructor(apiInstance) {
    this.apiInstance = apiInstance;
    this.branches = new AdminBranchesApi(apiInstance);
    this.parks = new AdminParksApi(apiInstance);
    this.cameras = new CamerasApi(apiInstance);
    this.users = new AdminUsersApi(apiInstance);
    this.companies = new AdminCompaniesApi(apiInstance);
    this.logs = new LogsApi(apiInstance);
  }
}

export default AdminApi;
