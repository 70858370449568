import { action, observable } from 'mobx';
import API from '../../utils/API/api';
import CrudStore from './crudStore';
import Employee from '../../models/Employee';
import { CAR_NUMBER_EXISTS_ERROR, ROLES, ROUTES, SAME_USER_AND_PASSWORD_EXISTS } from '../../utils/common';
import { authorize } from '../../components/AuthrorizeHOC/AuthorizeHOC';

const defaultPermissions = [ROLES.COMPANY, ROLES.SENIOR_SECRETARY];
const extendedPermissions = [...defaultPermissions, ROLES.SECRETARY];

const permissions = {
  getById: extendedPermissions,
  getAll: extendedPermissions,
  create: defaultPermissions,
  update: defaultPermissions,
  delete: defaultPermissions
};

class EmployeesStore extends CrudStore {
  @observable selectedEmployee;
  @observable isEditModalShown;
  @observable isUploadFileModalShown;

  constructor(stores) {
    super(stores, API.company.employees, permissions);
    stores.commonStore.onLocationChanged(location => {
      if (location.path.indexOf(ROUTES.EMPLOYEES) > -1) {
        stores.parksStore.getAll();
        if (location.params && location.params.employeeId) {
          this.getById(location.params.employeeId);
        }
        else {
          console.log("this",this.getAll);
          this.getAll();
        }
      }
    }, {
      path: `${ROUTES.EMPLOYEES}/:employeeId?`
    })
  }

  @action
  editEmployee(parking) {
    if (authorize(defaultPermissions)) {
      this.selectedEmployee = parking;
      this.showEditModal();
    }
  };

  @action
  newEmployee = () => {
    if (authorize(defaultPermissions)) {
      this.selectedEmployee = new Employee();
      this.showEditModal();
    }
  };

  @action
  create = (object) => {
    if (authorize(defaultPermissions)) {
      this.commonStore.setIsLoading(true);
      return this.api.create(object).then(action(response => {
        if (response.results === -1) {
          alert("Sorry ... You have reach the maximum users number you have purchase, Please contact the DPM support team support@dpm-parking.com");
          throw new Error('max users limit reached');
        }
        else if (response.results === -2) {
          alert(CAR_NUMBER_EXISTS_ERROR);
          throw new Error(CAR_NUMBER_EXISTS_ERROR);
        }
        else if (response.results === -3) {
          alert(SAME_USER_AND_PASSWORD_EXISTS);
          throw new Error(SAME_USER_AND_PASSWORD_EXISTS);
        }
        else if (response.results && response.results._id) {
          this.refreshEmployee(response.results._id);
          return response;
        }
        else {
          alert("Error ...Please try again.");
          throw new Error('Error ...Please try again.');
        }
      })).finally(() => {
        this.commonStore.setIsLoading(false);
      });
    }
    return Promise.reject('Not authorized to create new employee');
  };

  @action
  update(object) {
    return super.update(object).then(response => {
      if (response.results === -2) {
        alert(CAR_NUMBER_EXISTS_ERROR);
        throw new Error(CAR_NUMBER_EXISTS_ERROR);
      } else if (response.status === 400 && response.data && response.data.message) {
        throw new Error(`Error: ${ response.data.message}`);
      }
    })
  }

  @action
  refreshEmployee(employeeId) {
    this.commonStore.setIsLoading(true);
    this.api.getById(employeeId).then(action(response => {
      const employeeIdx = this.list.findIndex(p => p._id === employeeId);
      if (employeeIdx > -1) {
        this.list[employeeIdx] = response.results;
      }
      else {
        this.list.push(response.results);
      }
      this.commonStore.setIsLoading(false);
    })).catch(e => {
      this.commonStore.setIsLoading(false);
    });
  }

  @action
  showEditModal = () => {
    this.isEditModalShown = true;
  };

  @action
  hideEditModal = () => {
    this.selectedEmployee = null
    this.isEditModalShown = false
  };

  @action
  showUploadModal = () => {
    this.isUploadFileModalShown = true;
  };

  @action
  hideUploadModal = () => {
    this.isUploadFileModalShown = false
  };

  @action
  onUploadExcelButtonClick = () => {
    if (authorize(defaultPermissions)) {
      this.showUploadModal();
    }
  };

  @action
  changePassword(userId, newPassword) {
    if (authorize(defaultPermissions)) {
      this.commonStore.setIsLoading(true);
      return this.api.changePassword(userId, newPassword).then(response => {
        if (response.results && response.results === -3) {
          throw new Error('Please choose another password');
        }
      }).finally(() => this.commonStore.setIsLoading(false));
    }
    return Promise.reject('Unauthorized');
  }

  @action
  uploadEmployeesFile(file) {
    if (authorize(defaultPermissions)) {
      this.commonStore.setIsLoading(true);
      return this.api.importExcelFile(file).then((response) => {
        this.getAll();
        return response;
      }).finally(() => this.commonStore.setIsLoading(false));
    }
    return Promise.reject('Unauthorized');
  }

  @action
	exportExcel = () => {
  	return this.api.exportExcel();
	}
}

export default EmployeesStore;
