import { observable, action } from 'mobx';
import API from '../../utils/API/api';
import { toast } from 'react-toastify';

class PushNotificationsStore {
  @observable title = '';
  @observable message = '';
  @observable screensList = [
    { value: 'home', label: 'Home' },
    { value: 'parkingLot', label: 'Parking Lot' },
    { value: 'vacation', label: 'Vacation' },
  ];
  @observable screen = this.screensList[0];

  constructor(stores) {
    this.commonStore = stores.commonStore;
  }

  @action
  sendPush = (title, message, screen) => {
    if (title.length > 0 && message.length > 0 && screen.length > 0) {
      this.commonStore.setIsLoading(true);
      return API.company.notifications.sendPush(title, message, screen).then(response => {
        this.commonStore.setIsLoading(false);
        let toastMessage = '';
        let toastErrorMessage = '';
        if (response.results) {
          toastMessage = 'Notification Sent!';
          /*const {android, ios} = response.results;
          if (android) {
            if (android.success && android.success > 0) {
              toastMessage += `Notification sent to ${android.success} android employees`;
            }
            if (android.failure && android.failure > 0) {
              toastErrorMessage += `Notification didn't sent to ${android.failure} android employees`;
            }
          }
          if (ios) {
            if (ios.success && ios.success > 0) {
              toastMessage += `Notification sent to ${ios.success} ios employees`;
            }
            if (ios.failure && ios.failure > 0) {
              toastErrorMessage += `Notification didn't sent to ${ios.failure} ios employees`;;
            }
          }
          if (toastErrorMessage.length > 0) {
            toast(toastErrorMessage, {type: toast.TYPE.ERROR});
          }
          if (toastMessage.length > 0) {
            toast(toastMessage, { type: toast.TYPE.SUCCESS });
          }*/
          toast(toastMessage, { type: toast.TYPE.SUCCESS });
        }
        return response;
      }).catch(err => {
        this.commonStore.setIsLoading(false);
        return err;
      });
    }
    else {
      alert('please fill in title, message and screen');
      return Promise.reject();
    }
  }
}

export default PushNotificationsStore;
