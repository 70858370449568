import React, { Component } from 'react';
import { Field } from 'react-final-form';
import BootstrapInput from "../../../components/BootstrapInput/BootstrapInput";
export class SmsByDaysComponent extends Component {
	constructor(props) {
		super(props);
	}
	 render() {
		return (
			<div>
				<div className="row">
					<div className="col-5">
						<label htmlFor="Sunday" style={{ marginRight: "10px" }} > Sunday </label>
					</div>
					<div className="col-5">
						<Field name="Sunday" component={BootstrapInput} type="checkbox" id="Sunday" />
					</div>
				</div>
				<div className="row">
					<div className="col-5">
						<label htmlFor="Monday" style={{ marginRight: "10px" }}> Monday </label>
					</div>
					<div className="col-5">
						<Field name="Monday" component={BootstrapInput} type="checkbox" id="Monday" />
					</div>
				</div>
				<div className="row">
					<div className="col-5">
						<label htmlFor="Tuesday" style={{ marginRight: "10px" }}> Tuesday </label>
					</div>
					<div className="col-5">
						<Field name="Tuesday" component={BootstrapInput} type="checkbox" id="Tuesday" />
					</div>
				</div>
				<div className="row">
					<div className="col-5">
						<label htmlFor="Wednesday" style={{ marginRight: "10px" }}> Wednesday </label>
					</div>
					<div className="col-5">
						<Field name="Wednesday" component={BootstrapInput} type="checkbox" id="Wednesday" />
					</div>
				</div>
				<div className="row">
					<div className="col-5">
						<label htmlFor="Thursday" style={{ marginRight: "10px" }}> Thursday </label>
					</div>
					<div className="col-5">
						<Field name="Thursday" component={BootstrapInput} type="checkbox" id="Thursday" />
					</div>
				</div>
				<div className="row">
					<div className="col-5">
						<label htmlFor="Friday" style={{ marginRight: "10px" }}> Friday </label>
					</div>
					<div className="col-5">
						<Field name="Friday" component={BootstrapInput} type="checkbox" id="Friday" />
					</div>
				</div>
				<div className="row">
					<div className="col-5">
						<label htmlFor="Saturday" style={{ marginRight: "10px" }}> Saturday </label>
					</div>
					<div className="col-5">
						<Field name="Saturday" component={BootstrapInput} type="checkbox" id="Saturday" checked={true} />
					</div>
				</div>
			</div>
		);
	}
}
export  default SmsByDaysComponent
