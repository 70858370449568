import dayjs from 'dayjs';
import {action, observable} from 'mobx';
import React from 'react';
import API from '../../../utils/API/api';
import ReportsStore from '../reportsStore';

const tableColumns = [
	{
		Footer: (columnProps) => {
			return (
				<span>
				<strong>Total: </strong>{columnProps.data.length}
			</span>
			)
		}
	},
	{
		Header: 'Enter Date',
		accessor: 'enterAt',
		Cell: ({ value }) => {
			return dayjs(value).format('YYYY-MM-DD HH:mm (dddd)');
		},
	},
	{
		Header: 'Car Number',
		accessor: 'carNumber',
	},
	{
		Header: 'Driver Name',
		accessor: 'driverName',
	},
	{
		Header: 'Camera',
		accessor: 'camera',
	},
	{
		Header: 'Park',
		accessor: 'park.name',
	},
];

class LprLogsPerPark extends ReportsStore {
  @observable selectedCarNumber;

  constructor(stores) {
		super(stores, tableColumns, API.company.reports.getLprLogsReportPerPark, API.company.reports.exportLprLogsReport)
		this.fromDate = dayjs().subtract(1, 'week').toDate();
    this.toDate = new Date();
  }


  @action
  setselectedCarNumber = (carNumber) => {
    this.selectedCarNumber = carNumber;
  };
}

export default LprLogsPerPark;
