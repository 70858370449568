import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from '../../stores';
import '../../styles/react-table.styles.scss';
import Modal from '../../components/modal/Modal';
import { changePasswordIcon, deleteIcon, editIcon } from '../../assets';
import { toast } from 'react-toastify';
import EditEmployeeModal from './EmployeesComponents/EditEmployeeModal';
import filterCarNumbers from '../../utils/filterCarNumbers';
import DataTable from '../../components/DataTable/DataTable';
import UploadFileModal from '../../components/UploadFileModal/UploadFileModal';

const { employeesStore, parksStore, userStore } = stores;

@withRouter
@observer
class Employees extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayLanguageTable : 'en'
		}
	}

  onEditRow = (row) => {
    employeesStore.editEmployee(row._original);
  };

	onEditLang = (lan) => {
		this.setState({displayLanguageTable : `${lan}`})
  };

  onDeleteRow = (row) => {
    const answer = window.confirm("Are you sure you want to delete this forever?");
    if (answer) {
      employeesStore.delete(row._original._id).then(result =>  {
        toast(`Deleted employee: ${row._original.name}`, { type: toast.TYPE.SUCCESS });
      }).catch(error=>{
        console.error(error);
        toast(`Failed to deleted employee: ${row._original.name}`, { type: toast.TYPE.WARNING });
      })
    }
  };

  onChangePassword = (row) => {
    const newPassword = window.prompt("New Password", "Type new password ...");
    if (newPassword !== null && newPassword !== "Type new password ...") {
      // call api to change company's user password
      employeesStore.changePassword(row._original._id, newPassword).then(response => {
        toast("Password has changed!", { type: toast.TYPE.SUCCESS });
      }).catch(err => toast(err.message, { type: toast.TYPE.ERROR }))
    }
  };

  onFileUploaded = (files) => {
    if (files.length > 0) {
      employeesStore.uploadEmployeesFile(files[0]).then(response => {
        if (response.results) {
          if (response.results.error) {
            toast(response.results.error, { type: toast.TYPE.ERROR });
          }
          else if (response.results.success) {
            toast(`Imported ${response.results.success} users!`, { type: toast.TYPE.SUCCESS });
          }
        }
        else {
          console.error(response);
        }
      }).catch(e => {
        toast("Error while uploading Excel file", { type: toast.TYPE.ERROR });
        console.error(e);
      });
    }
  };
  

  render() {
    const { list, isEditModalShown, isUploadFileModalShown, newEmployee, hideEditModal, hideUploadModal, exportExcel, selectedEmployee, onUploadExcelButtonClick } = employeesStore;
		let should_translate = this.state.displayLanguageTable == 'he' ? true : false;
		console.log(this.state.displayLanguageTable , 'this.state.displayLanguageTable');
    const columns = [
      {
        Header:  should_translate ? 'שם מלא' : 'Full Name',
        accessor: 'name',
        filterMethod: (filter, row) => {
          if (!row['name']) return false;
          return row['name'].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
        },
        Footer: (columnProps) => {
          return (
            <span>
              <strong>Total: </strong>{columnProps.data.length}
            </span>
          )
        },
      }, {
        Header: should_translate ? 'מזהה': 'ID',
        accessor: 'customId'
      }, {
        Header: should_translate ?  'שם משתמש (מס טלפון)' : 'Username (Phone)',
        accessor: 'phone'
      }, {
        Header: 'Email',
        accessor: 'email',
        filterMethod: (filter, row) => {
          if (!row['email']) return false;
          return row['email'].toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
        },
        Cell: ({ value }) => <a href={`mailto:${value}`}>{value}</a>,
      }, {
        Header: should_translate ? '?אפלקצייה הורדה': 'Downloaded App?',
        accessor: 'deviceToken',
        Cell: ({ value }) => {
					if(should_translate){
						return value ? 'כן':'לא'
					}
					else return value ? 'Yes' : 'No'
				} ,
        filterable: false
      }, {
        Header: should_translate ? 'מס רכב': 'Car No.',
        accessor: 'carNumbers',
        Cell: ({ value }) => {
          return (
            (value || []).map((number, key) => {
              return <p key={key}>{number}</p>
            })
          );
        },
        filterMethod: (filter, row) => {
          return filterCarNumbers(row['carNumbers'] || [], filter.value);
        }
      }, {
        Header: should_translate ? 'שם חברה': 'Company Name',
        accessor: 'company.name',
        filterable: false
      }, {
        Header: should_translate ? 'שנה סיסמה': 'Change password',
        Cell: ({ row }) => <img src={changePasswordIcon} onClick={this.onChangePassword.bind(this, row)} />,
        sortable: false,
        filterable: false,
        width: 100
      }, {
        Header: should_translate ? 'ערוך':'Edit',
        Cell: ({ row }) => <img src={editIcon} onClick={this.onEditRow.bind(this, row)} />,
        sortable: false,
        filterable: false,
        width: 80
      }, {
        Header: should_translate ? 'מחק':'Delete',
        Cell: ({ row }) => <img src={deleteIcon} onClick={this.onDeleteRow.bind(this, row)} />,
        sortable: false,
        filterable: false,
        width: 80
      }
    ];

    const editModalTitle = (selectedEmployee && selectedEmployee._id) ? `Edit User (${selectedEmployee.name})` : 'New User';
    const {currentCompany, currentUser} = userStore;
    
    return (
      <div className="companies-page full-height">
        <h1 className="page-title">Users</h1>
        <div className="actions">
          {
            <button type="button" onClick={newEmployee}>New User</button>
          }
          <button type="button" onClick={onUploadExcelButtonClick}>Excel import</button>
          <button type="button" onClick={exportExcel}>Export to Excel</button>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<img
							id="english_flag"
							src="https://i.imgur.com/RtXFq5i.png"
							onClick={this.onEditLang.bind(this,'en')}
							style={{
								maxWidth: 30,
								maxHeight: 30,
								marginRight:10
							}}
						/>
						<img
							id="israel_flag"
							src="https://i.imgur.com/98bCEMy.png"
							onClick={this.onEditLang.bind(this,'he')}
							style={{
								maxWidth: 30,
								maxHeight: 30,
								marginRight:20
							}}
						/>
					</div>
				</div>
        <DataTable
          filterable
          data={list.slice()}
          columns={columns}
          defaultSorted={[
            {
              id: "name",
              desc: false
            }
          ]}
        />

        <Modal title={editModalTitle} show={isEditModalShown} handleClose={hideEditModal}>
          {selectedEmployee && 
            <EditEmployeeModal parkingLots={parksStore.getDropdownList} employee={selectedEmployee}
              closeModal={hideEditModal} displayLanguageModel={this.state.displayLanguageTable} />
          }
        </Modal>
        
        <Modal title="Upload users excel file" show={isUploadFileModalShown} handleClose={hideUploadModal}>
          <UploadFileModal closeModal={hideUploadModal} onFileUploaded={this.onFileUploaded} />
        </Modal>
      </div>
    );
  }
}

export default Employees;
