import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import stores from '../../../stores';
import Error from '../../../components/forms/Error';
import BootstrapInput from '../../../components/BootstrapInput/BootstrapInput';
import { toast } from 'react-toastify';

class ForgotPasswordModal extends Component {
  constructor(props) {
    super(props);
  }

  onSubmitForm = (submittedValues) => {
    stores.commonStore.setIsLoading(true);
    stores.authStore.forgotPassword(submittedValues.email, submittedValues.phone).then((response) => {
      stores.commonStore.setIsLoading(false);
      if (response.result === 1) {
        toast("Follow the email you've received from us to proceed", {
          type: toast.TYPE.SUCCESS,
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        this.props.closeModal();
      }
      else {
        toast("User not found", {type: toast.TYPE.ERROR});
      }
    }).catch(err => {
      console.error(err);
      stores.commonStore.setIsLoading(false);
    })
  };

  validateForm = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    }
    if (!values.phone) {
      errors.phone = 'Phone is required';
    }
    return errors
  };

  render() {
    return (
      <Form onSubmit={this.onSubmitForm}
            validate={this.validateForm}
            render={({ handleSubmit, form, submitFailed, submitting, pristine, errors, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col">Email*:</div>
                    <div className="col">
                      <Field name="email" component={BootstrapInput} type="text" />
                      <Error name="email" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">Phone number*:</div>
                    <div className="col">
                      <Field name="phone" component={BootstrapInput} type="text" />
                      <Error name="phone" />
                    </div>
                  </div>
                  <button type="submit" disabled={submitting} className="btn btn-primary">
                    Send
                  </button>
                </form>
              )
            }} />
    );
  }
}

ForgotPasswordModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ForgotPasswordModal;
