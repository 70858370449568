import dayjs from 'dayjs';
import React from 'react';
import API from '../../../utils/API/api';
import ReportsStore from '../reportsStore';

const tableColumns = [
	{
		Header: 'Date',
		accessor: 'date',
		Cell: ({ row, value }) => {
			const date = dayjs(value);
			return (
				<span>
					{date.format('DD/MM/YYYY')} [{date.format('ddd')}]
				</span>
			);
		},
	},
	{
		Header: 'Compnay',
		accessor: 'company.name',
	},
	{
		Header: 'Enteries',
		accessor: 'usedCount',
	},
	{
		Header: 'Total Parkings',
		accessor: 'totalParkings',
	},
];

class LprEfficiencyReport extends ReportsStore {
	constructor(stores) {
		super(stores, tableColumns, API.company.reports.getLprEfficiencyReport, API.company.reports.exportParkingsEfficiency)
	}
}

export default LprEfficiencyReport;
